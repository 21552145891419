import React, { ReactNode, useRef, useState } from 'react';

import {
    Modal,
    ModalOverlay, ModalContent,
    ModalHeader, ModalBody,
    ModalCloseButton,
    Image, Flex, Link, Spacer, Center
} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';

// import qrcode1 from "../../assets/qrcode1.jpeg";
// import qrcode3 from "../../assets/qrcode3.jpeg";
const qrcode1 = 'https://lingoleapstorage1.blob.core.windows.net/publicdata/study_group_toefl.jpeg'
const qrcode3 = 'https://lingoleapstorage1.blob.core.windows.net/publicdata/study_group_ielts.jpeg'


const StudyGroupModal = (props) => {
    const { t, i18n } = useTranslation();

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t('join_study_group')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Center >
                        {i18n.language == "cn"
                            ? (
                                props.project == "toefl"
                                ? <Image width={250} src={qrcode1} marginBottom={"20px"} />
                                : <Image width={250} src={qrcode3} marginBottom={"20px"} />
                            )
                            :
                            <Flex direction={'column'} gap={5}>
                                <Flex direction={'column'} alignItems={'center'}>
                                    <Flex>
                                        Discord:
                                    </Flex>
                                    <Flex>
                                        <Link href="https://discord.gg/8MuGxPEz5U">https://discord.gg/8MuGxPEz5U</Link>
                                    </Flex>
                                </Flex>
                                <Spacer></Spacer>
                                <Flex direction={'column'} alignItems={'center'}>
                                    <Flex>Facebook:</Flex>
                                    <Flex><Link href="https://www.facebook.com/groups/lingoleapai">https://www.facebook.com/groups/lingoleapai</Link></Flex>
                                </Flex>
                            </Flex>
                        }
                    </Center>
                </ModalBody>
            </ModalContent>
        </Modal>
    );


}

export default StudyGroupModal;