import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    FormControl,
    Button,
    Divider,
    Box,
    Image,
    AbsoluteCenter,
    useToast,
    Text
} from '@chakra-ui/react'

import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import { getRedirectData } from '../Login/Redirect';
const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;
import AuthService from "services/auth.service";

const GoogleIconSVG = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.0017 10.5913L19.5427 10.602C20.201 13.7203 18.9977 20 12.0017 20C7.58267 20 4 16.4183 4 12C4 7.58167 7.58267 4 12.0017 4C14.0773 4 15.968 4.79033 17.3897 6.08567L15.138 8.33667C14.294 7.61367 13.2003 7.17433 12.0017 7.17433C9.33633 7.17433 7.175 9.33467 7.175 11.9997C7.175 14.6647 9.33567 16.825 12.0017 16.825C14.2403 16.825 15.788 15.5 16.3573 13.6477H12.0017V10.5913Z" fill="currentColor"/>
    </svg>
)

export const SignupZone = (props) => {
    const { t, i18n } = useTranslation();
    const toast = useToast();
    const validateEmail = (email) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(email)) {
            return false
        }
        return true
    }

    const checkPhoneRegisterValid = (login) => {
        let errMsg = ""
        let err = ""
        let isErr = false
        if (login.phoneNumber === "" || login.prefix === "" || login.password === "") {
            console.log("handle error phone number")
            isErr = true
            if (login.phoneNumber === "") {
                errMsg = "Phone Number"
                err = "phoneNumber"
            }
            if (login.prefix === "") {
                errMsg = "Country Code"
                err = "countryCode"
            }
            if (login.password === "") {
                errMsg = "Password"
                err = "password"
            }
        }

        return {
            err,
            errMsg,
            isErr,
        }
    }

    const checkEmailRegistryValid = (login) => {
        let errMsg = ""
        let err = ""
        let isErr = false
        if (login.mail === "") {
            isErr = true
            err = "mail"
            errMsg = t('signupZone.emailEmpty')
        }

        if (login.password === "") {
            isErr = true
            err = "password"
            errMsg = "Password"
        }

        if (!validateEmail(login.mail)) {
            isErr = true
            err = "mail"
            errMsg = t('signupZone.emailEmpty')
        }

        return {
            err,
            errMsg,
            isErr,
        }

    }

    const handleLogin = async (e) => {
        const loginProps = props.props.login
        const handleResult = loginProps.handleLoginResult
        AuthService.login(loginProps.mail, loginProps.prefix + loginProps.phoneNumber, loginProps.password).then(
            () => {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user) {
                    mixpanel.identify(user.uid);
                    mixpanel.people.set({
                        $email: loginProps.mail,
                        $phone: loginProps.prefix + loginProps.phoneNumber,
                        $name: user.user_name,
                        source: "regular_login",
                    });
                    
                    // Track login event
                    mixpanel.track('Login V2 - Login Button Click', {
                        loginType: loginProps.loginType,
                        source: 'regular_login'
                    });
                }

                AuthService.login(loginProps.mail, loginProps.prefix + loginProps.phoneNumber, loginProps.password).then(
                    () => {
                        console.log("login ok, call", handleResult)
                        handleResult({
                            result: "ok",
                        })
                        
                        // Check for redirect data
                        const redirectUrl = getRedirectData();
                        if (redirectUrl) {
                            localStorage.removeItem('redirectData');
                            window.location.href = redirectUrl;
                        } else {
                            window.location.href = '/toefl/reading';
                        }
                    },
                    (error) => {
                        let errMsg = error.message
                        if (error.response && error.response.data) {
                            errMsg = error.response.data.error
                        }
                        toast({
                            description: errMsg,
                            status: "error",
                            duration: 3000,
                            isClosable: true,
                        });
                    }
                );
            },
            (error) => {
                console.log(error)

                let err = "phoneNumber"
                if (loginProps.loginType !== "phone") {
                    err = "mail"
                }
                let errMsg = error.message
                if (error.response && error.response.data) {
                    errMsg = error.response.data.error
                }
                handleResult({
                    result: "err",
                    err,
                    errMsg,
                })


                // Track failed login attempt
                mixpanel.track('Login V2 - Login Failed', {
                    loginType: loginProps.loginType,
                    error: errMsg
                });
                console.log("login error")
                console.log(error);
            }
        );
    }

    const handleSignUp = async (e) => {
        const loginProps = props.props
        const login = loginProps.login
        const handleResult = login.handleLoginResult
        let signupInfo = null

        if (login.loginType === "phone") {
            //for testing phone number exist
            const result = checkPhoneRegisterValid(login)
            if (result.isErr) {
                handleResult({
                    result: "err",
                    err: result.err,
                    errMsg: result.errMsg,
                })
                return
            }

            signupInfo = {
                'phone': login.prefix + login.phoneNumber,
                "routeName": 'signup',
            }
        } else {
            const result = checkEmailRegistryValid(login)
            if (result.isErr) {
                handleResult({
                    result: "err",
                    err: result.err,
                    errMsg: result.errMsg,
                })
                return
            }
            signupInfo = {
                'email': login.mail,
                'routeName': 'signup',
            }
        }

        try {
            const response = await axios.post(API_URL + '/api/send_totp', signupInfo)
            if (response.status === 200) {
                handleResult({
                    result: "ok",
                })
                
                // Track successful signup initiation
                mixpanel.track('Signup V2 - Signup Button Click', {
                    signupType: login.loginType,
                    source: 'regular_signup'
                });
            }
        } catch (error) {
            let err = "phoneNumber"
            if (loginProps.loginType !== "phone") {
                err = "mail"
            }
            console.log("totp error: ", error)
            let errMsg = error.message
            if (error.response && error.response.data) {
                if (error.response.data.error === "User already exists") {
                    if (err === "mail") {
                        errMsg = t('signupZone.userExists')
                    } else {
                        errMsg = t('signupZone.wrongCredentials')
                    }
                }
            }
            handleResult({
                result: "err",
                err,
                errMsg,
            })
            
            // Track failed signup attempt
            mixpanel.track('Signup V2 - Signup Failed', {
                signupType: login.loginType,
                error: errMsg
            });
        }
    }



    return (
        <FormControl marginTop="14px">
            <Button 
                w={'100%'} 
                bg="#FF8A00" 
                borderRadius={'8'}
                fontFamily="THICCCBOI, sans-serif"
                fontSize={18}
                fontWeight={600}
                height="53px"
                color="#232323"
                _hover={{
                    background: 'radial-gradient(75.26% 282.9% at 24.74% 44.9%, #FFAC35 0%, #FF9600 100%) '
                }}
                _focus={{
                    bg: "#FF8A00",
                    color: "#232323"
                }}
                onClick={(e) => {
                    if (!props.props.login.isLogin) {
                        handleSignUp()
                    } else {
                        handleLogin()
                    }
                }}
            >
                <span>
                    {!props.props.login.isLogin ? t('signupZone.signup') : t('signupZone.login')}
                </span>
            </Button>
            <Box position='relative' padding='28px 0'>
                <Divider />
                <AbsoluteCenter bg='white' px='4'>
                    <Text fontSize="18px" color="#545454">{t('signupZone.or')}</Text>
                </AbsoluteCenter>
            </Box>
            <Button
                w={'100%'}
                borderRadius={'8'}
                variant='outline'
                bg={'white'}
                fontFamily="THICCCBOI, sans-serif"
                fontSize={16}
                fontWeight={600}
                height="53px"
                color="#232323"
                onClick={() => {
                    let baseUrl;
                    if (process.env.REACT_APP_CM_BACKEND_URL) {
                        baseUrl = process.env.REACT_APP_CM_BACKEND_URL
                    } else {
                        const currentLocation = window.location;
                        baseUrl = currentLocation.protocol + '//' + currentLocation.host; // This includes both hostname and port if present
                    }
                    
                    let googleLoginUrl = `${API_URL}/api/google_login?base_url=${encodeURIComponent(baseUrl)}`;
                    
                    if (props.props.login.invitationCode) {
                        googleLoginUrl += `&r=${encodeURIComponent(props.props.login.invitationCode)}`;
                    }
                    
                    console.log('API_URL:', API_URL);
                    console.log('Google Login URL:', googleLoginUrl);
                    window.location.href = googleLoginUrl;
                }}
                _hover={{
                    background: '#F7F7F7',
                }}
                _focus={{
                    bg: 'white',
                    color: "#232323",
                    borderColor: "#232323"
                }}
                position="relative"
                pl="48px"
                borderColor="#232323"
                borderWidth="1px"
            >
                <Box position="absolute" left="16px" top="50%" transform="translateY(-50%)">
                    <GoogleIconSVG />
                </Box>
                <span>
                    {t('signupZone.continueWithGoogle')}
                </span>
            </Button>

        </FormControl >
    )
}
