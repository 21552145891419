import React from 'react';
import {Box, Flex, Image, Progress, Text} from '@chakra-ui/react';
import ToeflibtWaiting from "assets/ToeflibtWaiting.svg";
import {useTranslation} from "react-i18next";

const ToeflWaitingPage = ({isAnswered = true}) => {
    const {t, i18n} = useTranslation();
    return (
        <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            bg="gray.100"
        >
            <Box mb={2}>
                <Image src={ToeflibtWaiting} alt="Loading SVG" boxSize="150px"/>
            </Box>
            <Box mb={4} display={isAnswered ? 'block' : 'none'}>
                <Progress size='xs' isIndeterminate colorScheme='teal' height='8px' width={'260px'}
                          borderRadius={"sm"}/>
            </Box>
            {isAnswered ? <Text fontSize="lg" color="gray.700">{t("under_review")}</Text>
                : <Text fontSize="lg" color="red">{t("unanswered")}</Text>}
        </Flex>
    );
};

export default ToeflWaitingPage;
