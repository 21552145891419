import React, {useEffect, useState} from "react";

import {Box, Grid, Text, VStack} from '@chakra-ui/react'
import {RxDragHandleDots2} from "react-icons/rx";
import {ToeflIbtContext} from "./ToeflIbtContext";

export const ToeflReadingQuestionDragAndDrop = (props) => {
    var {questionText, subQuestionOptions, subIdx = 0, ...other} = props;
    const [userAnswerTexts, setUserAnswerTexts] = useState({})
    const {
        answerContent,
        setAnswerContent
    } = React.useContext(ToeflIbtContext);

    useEffect(() => {
        if (questionText) {
            const answerTexts = {};
            const dot_keys = [];
            questionText.forEach((item, idx) => {
                if (item === "●") {
                    const key = '●_' + idx;
                    answerTexts[key] = '';
                    dot_keys.push(key)
                } else if (idx + 1 <= questionText.length && questionText[idx + 1] === "●" && Object.keys(answerTexts).length > 0) {
                    const key = '●_' + idx;
                    answerTexts[key] = '#';
                    dot_keys.push(key)
                }
            });
            Object.keys(subQuestionOptions).map((item, idx) => {
                answerTexts[item] = subQuestionOptions[item];
            });
            const answer = answerContent?.reading?.[subIdx]
            if (answer) {
                for (let idx = 0; idx < answer.length; idx++) {
                    const item = answer[idx];
                    if (["A", "B", "C", "D", "E", "F", "G"].includes(item)) {
                        const key = dot_keys[idx];
                        if (key && answerTexts[item]) {
                            answerTexts[key] = answerTexts[item];
                            answerTexts[item] = '';
                        }
                    }
                }
            }
            setUserAnswerTexts(answerTexts);
        }
    }, [])


    const handleDragStart = (e, id) => {
        e.dataTransfer.setData('text/plain', id);
    };


    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e, targetId) => {
        e.preventDefault();
        const sourceID = e.dataTransfer.getData('text');
        const sourceText = userAnswerTexts[sourceID];
        // 移动到答案框则复原
        if (!targetId.startsWith("●")) {
            targetId = sourceText[0]
        }
        const targetText = userAnswerTexts[targetId];
        if (targetText !== '' || sourceText === '') {
            return;
        }
        const newUserAnswerTexts = {...userAnswerTexts};
        newUserAnswerTexts[targetId] = sourceText;
        newUserAnswerTexts[sourceID] = '';
        setUserAnswerTexts(newUserAnswerTexts);
        handleOptionChange(newUserAnswerTexts);
    };

    const handleDoubleClick = (e, id) => {
        const sourceID = id;
        const sourceText = userAnswerTexts[sourceID];
        const targetId = sourceText[0]
        const targetText = userAnswerTexts[targetId];
        if (targetText !== '' || sourceText === '') {
            return;
        }
        const newUserAnswerTexts = {...userAnswerTexts};
        newUserAnswerTexts[targetId] = sourceText;
        newUserAnswerTexts[sourceID] = '';
        setUserAnswerTexts(newUserAnswerTexts);
        handleOptionChange(newUserAnswerTexts);
    };

    const handleOptionChange = (newUserAnswerTexts) => {
        let values = [];
        newUserAnswerTexts && Object.entries(newUserAnswerTexts).map(([key, option]) => {
            if (key.startsWith("●")) {
                if (option !== '') {
                    values.push(option[0])
                } else {
                    values.push('')
                }
            }
        })
        setAnswerContent(prevAnswerContent => ({
            ...prevAnswerContent,
            reading: {
                ...prevAnswerContent.reading,
                [subIdx]: values
            }
        }));
    };


    return (
        <Box
            w={"100%"}
        >
            <VStack alignItems={"center"}
                    w={"100%"}
                    h={"100%"}
                    p={4}
                    spacing={4}
            >
                {questionText.length > 0 && questionText.map((item, idx) => {
                    if (idx === 0) {
                        return <Box key={idx}>
                            <Box key={"1_0"} maxWidth={"1100px"} display='flex' alignItems='center'
                                 justifyContent='center'>
                                {item}
                            </Box>
                            <Box
                                key={"1_1"}
                                backgroundColor="#E2F7F9"
                                borderRadius="16"
                                // h={"vh"}
                                minWidth={'35%'}
                                alignContent={"center"}
                                alignItems={"center"}
                                color="black"
                                pt={3}
                                pb={3}
                                pl={8}
                                pr={8}
                            >
                                Drag your choices to the spaces where they belong. To review the passage select <span
                                style={{fontWeight: 'bold'}}>VIEW Passages</span>.
                            </Box>
                        </Box>
                    } else if (item.startsWith("Drag your") || (item === "Answer Choices")) {
                        return null
                    } else if (idx === 1) {
                        return (
                            <Box key={idx} minWidth={'50%'} display='flex' alignItems='center' justifyContent='center'>
                                <Text fontWeight="bold">
                                    {item}
                                </Text>
                            </Box>
                        );
                    } else if (item === "●") {
                        const key = item + '_' + idx;
                        return <Box
                            key={key}
                            // maxWidth={'600px'}
                            minWidth={'320px'}
                            w={window.innerWidth <= 600 ? "100%" : "600px"}
                            minH={"8vh"}
                            // h={"10vh"}
                            overflowY="auto"
                            border="1px"
                            // borderColor={isDropped ? 'green.500' : 'black.700'}
                            p={2}
                            borderRadius="16"
                            onDragOver={handleDragOver}
                            onDrop={(e) => handleDrop(e, key)}
                            draggable={subIdx !== 0}
                            onDragStart={(e) => handleDragStart(e, key)}
                            cursor="move"
                            bg={(userAnswerTexts[key] === '') ? "" : "teal"}
                            color={(userAnswerTexts[key] === '') ? "black" : "white"}
                            display="flex"
                            alignItems="center"
                            onDoubleClick={(e) => handleDoubleClick(e, key)}
                        >
                            {userAnswerTexts[key] !== '' && (
                                <RxDragHandleDots2 size={40}/>
                            )}
                            <Text flex={1}>
                                {userAnswerTexts[key]}
                            </Text>
                        </Box>
                    } else if (idx + 1 <= questionText.length && questionText[idx + 1] === "●") {
                        return <Box key={idx} minWidth={'50%'} display='flex' alignItems='center'
                                    justifyContent='center'>
                            {item}
                        </Box>
                    } else {
                        return null;
                    }
                })}
                <Box minWidth={'50%'} display='flex' alignItems='center' justifyContent='center'>
                    Answer Choices
                </Box>
                {
                    subQuestionOptions &&
                    <Box>
                        <Grid
                            templateColumns="repeat(2, 1fr)"
                            gap={4}
                        >
                            {Object.keys(subQuestionOptions).map((item, idx) => {
                                return <Box
                                    key={item}
                                    overflowY="auto"
                                    maxW={"530px"}
                                    minH={"8vh"}
                                    // h={"8vh"}
                                    border="1px"
                                    borderColor="black.700"
                                    borderRadius="16"
                                    p={2}
                                    onDragOver={handleDragOver}
                                    onDrop={(e) => handleDrop(e, item)}
                                    draggable={subIdx !== 0}
                                    onDragStart={(e) => handleDragStart(e, item)}
                                    cursor="move"
                                    display="flex"
                                    alignItems="center"
                                >
                                    {userAnswerTexts[item] !== '' && (
                                        <RxDragHandleDots2 size={40}/>
                                    )}
                                    <Text flex={1}>
                                        {userAnswerTexts[item]}
                                    </Text>
                                </Box>;
                            })}
                        </Grid>
                    </Box>
                }
            </VStack>
        </Box>
    );
}