import React, {useState} from 'react';

import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Text,
    useBreakpointValue,
    useDisclosure
} from '@chakra-ui/react';
import {useGetUserPreferencesQuery, useUpdateUserPreferencesMutation} from "../services/cmApi";
import {useHistory} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import brand_Loge from "assets/img/brand_Logo.png";
import app_img from "assets/img/app.png";
// import qrcode_toefl from "../assets/qrcode1.jpeg";
// import qrcode_ielts from "../assets/qrcode3.jpeg";
import ai_chatbot_cn_img from "../assets/img/AI_chatbot_cn.png";
import ai_chatbot_en_img from "../assets/img/AI_chatbot_en.png";

const qrcode_toefl = 'https://lingoleapstorage1.blob.core.windows.net/publicdata/study_group_toefl.jpeg'
const qrcode_ielts = 'https://lingoleapstorage1.blob.core.windows.net/publicdata/study_group_ielts.jpeg'

const ListHeader = ({children}) => {
    return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    );
};

function ChineseContent() {
    return (
        <>
            <Center>
                <Text as='samp'>扫码进</Text>
                <Text color="#f8b500ff" as='b'>LingoLeap</Text>
                <Text as='samp'>托福雅思备考群</Text>
            </Center>
            <Center>
                <Text as='b'>领福利｜赚积分｜新功能</Text>
            </Center>
            <Center>
                <Image src={qrcode_toefl} w='40%'/>
                <Image src={qrcode_ielts} w='40%'/>
            </Center>
            {/*<Center>*/}
            {/*    <Image src={checkin_activities_img} borderRadius='10px'/>*/}
            {/*</Center>*/}
        </>
    );
}


function EnglishContent() {
    const {t, i18n} = useTranslation();
    return (
        <Flex w='100%' direction="row" justifyContent="flex_start">
            <Flex justifyContent="flex_start" width={'60%'}>
                <Image src={app_img} w='70%' borderRadius='10px'/>
            </Flex>
            <Flex direction="column" width={'40%'} mt={'20px'} mr={'50px'} ml={'-40px'}>
                <Flex justifyContent="flex-end">
                    <Image src={brand_Loge} w='80%'/>
                </Flex>
                <Box h={'80px'}></Box>
                <Text fontSize='5xl' fontWeight='bold'>{t("download our app")}</Text>
                <Divider borderColor='#FF9F16' borderWidth='6px' width='30%' mt={'20px'} mb={'20px'}></Divider>
                {i18n.language === "cn" ? (
                    <ul style={{marginLeft: '30px'}}>
                        <li><Text fontSize='xl' fontWeight='bold' width={'280px'}>APP
                            网页实时同步</Text></li>
                        <li><Text fontSize='xl' fontWeight='bold'>随时随地练习&复习</Text></li>
                        <li><Text fontSize='xl' fontWeight='bold'>成就、挑战和排行榜设计</Text>
                        </li>
                        <li><Text fontSize='xl' fontWeight='bold'>全面实现Ai备考自由</Text></li>
                    </ul>
                ) : (
                    <ul style={{marginLeft: '30px'}}>
                        <li><Text fontSize='xl' fontWeight='bold'
                                  width={'280px'}>{t("more accurate scoring")}</Text></li>
                        <li><Text fontSize='xl'
                                  fontWeight='bold'>{t("advanced grammar correction")}</Text>
                        </li>
                        <li><Text fontSize='xl'
                                  fontWeight='bold'>{t("faster evaluation")}</Text></li>
                    </ul>
                )}
                <Flex direction="column" alignItems="flex-start">
                    <Flex direction="column" justifyContent="center" alignItems="center">
                        <Button
                            mt={'40px'}
                            mb={'10px'}
                            pt={'32px'}
                            pb={'32px'}
                            pl={'25px'}
                            pr={'25px'}
                            style={{backgroundColor: 'black', color: 'white'}}
                            width="100%"
                            borderRadius='10px'
                            fontSize={'2xl'}
                            onClick={() => window.open("https://apps.apple.com/us/app/lingoleap-ai-toefl-prep/id6480381514?platform=iphone", "_blank")}
                        >
                            {t("download now")}
                        </Button>
                        <Text color={'yellow.700'} fontSize={'13px'} letterSpacing={'1px'}>{t("available on the")} Apple
                            Store</Text>
                    </Flex>
                </Flex>
                <Flex justifyContent="flex-end" mt={'100px'} mb={'40px'}>
                    <Text fontWeight='bold'>@LingoLeap</Text>
                </Flex>
            </Flex>
        </Flex>

    );
}


export default function UpdatesModal() {
    const {t, i18n} = useTranslation();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [hasForcedOpen, setHasForcedOpen] = useState(false);

    // MODIFY THIS WHEN NEW UPDATES AER ADDED
    const version = 26;
    const {data: preferences, isLoading, isError, isSuccess} = useGetUserPreferencesQuery({});
    const [updateUserPreferencesRequest, isUpdateUserPreferencesError] = useUpdateUserPreferencesMutation();
    const isMobile = useBreakpointValue({base: true, md: false});
    let history = useHistory();

    if (!hasForcedOpen && !isOpen && isSuccess) {
        if (!('updatesVersion' in preferences) || preferences['updatesVersion'] < version) {
            setHasForcedOpen(true);
            onOpen();
            let prefs = {}
            prefs['updatesVersion'] = version;
            updateUserPreferencesRequest(prefs);
        }
    }
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}
                // isCentered={i18n.language === "cn"}
                   isCentered={true}
            >
                <ModalOverlay/>
                <ModalContent maxW='1000px' width="100%" borderRadius='10px'>
                    <ModalCloseButton/>
                    <ModalBody p={0} borderRadius='10px'>
                        {/*{i18n.language === "cn" ? (*/}
                        {/*    <ChineseContent/>*/}
                        {/*) : (*/}
                        {/*    <EnglishContent/>*/}
                        {/*)}*/}
                        {/*<EnglishContent/>*/}
                        <Flex w='100%' direction="row" justifyContent="flex_start">
                            <Image src={i18n.language === "cn" ? ai_chatbot_cn_img : ai_chatbot_en_img}
                                   borderRadius='10px' w="100%" h="auto"
                                   onClick={() => {
                                       history.push('/aiChat')
                                   }}
                            />
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}
