import React, {useEffect, useState} from 'react';
import {Box, Divider, Tab, TabList, Tabs, useToast, VStack} from '@chakra-ui/react';
import {useTranslation} from "react-i18next";
import ToeflReadingAnswer from './ToeflReadingAnswer'
import ToeflListeningAnswer from './ToeflListeningAnswer'
import ToeflWritingAnswer from './ToeflWritingAnswer'
import ToeflSpeakingAnswer from './ToeflSpeakingAnswer'
import {FaHeadphonesAlt, FaReadme} from "react-icons/fa";
import {FiFileText, FiMic} from "react-icons/fi";
import ToeflScoreReport from "./ToeflScoreReport";
import ToeflWaitingPage from "./ToeflWaitingPage";
import authHeader from "../../services/auth-header";
import axios from "axios";
import {PracticeTypeEnum, ToeflIbtContext} from "./ToeflIbtContext";
import mixpanel from "mixpanel-browser";

const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;
let WS_URL = process.env.REACT_APP_WS_BACKEND_URL ? process.env.REACT_APP_WS_BACKEND_URL : window.location.origin;

function ToeflAnswer() {
    const {
        questionContent,
        answerContent,
        setAnswerContent,
        setMockJson,
        mockId
    } = React.useContext(ToeflIbtContext);

    const {t, i18n} = useTranslation();
    const [isWaiting, setIsWaiting] = useState(true);
    const toast = useToast();
    const {
        questionSet,
        tpoNum,
        section,
        practiceType,
        answerJson,
        sections,
        mid,
        viewAnswerSection
    } = parseUrl()
    const [curSection, setCurSection] = React.useState(viewAnswerSection ? viewAnswerSection :
        practiceType === PracticeTypeEnum.FULL_MOCK_TEST ? 'scoreReport' : section);
    const [midToken, setMidToken] = useState(null)
    const [bestScoreDic, setBestScoreDic] = useState({});


    useEffect(() => {
        // 判断是否等待
        if (answerJson) { // 阅读、听力单任务、单科模考(单科模考也是单任务展示) 练习历史跳转
            setAnswerContent(prevAnswerContent => ({
                ...prevAnswerContent,
                [section]: answerJson
            }));
            setIsWaiting(false)
            // } else if (mid) { // 全科模考分享跳转
            //     setIsWaiting(false)
        } else if (viewAnswerSection) { // 详情跳转页不批改等待
            setIsWaiting(false);
        } else if (practiceType !== PracticeTypeEnum.FULL_MOCK_TEST) { //正常模考场景
            if (['reading', 'listening'].includes(section)) { // 单科模考练习时，阅读听力不批改等待
                setIsWaiting(false)
            }
        }
    }, []);


    useEffect(() => {
        if (answerJson) { // 阅读、听力单任务、单科模考(单科模考也是单任务展示) 练习历史跳转
            return;
            // } else if (mid) { // 全科模考分享跳转
            //     return;
        } else if (viewAnswerSection) { // 详情跳转页不批改等待
            return;
        } else if (practiceType !== PracticeTypeEnum.FULL_MOCK_TEST) { //正常模考场景
            if (['reading', 'listening'].includes(section)) { // 单科模考练习时，阅读听力不批改等待
                return;
            }
        } else if (!answerContent || Object.keys(answerContent).length === 0) { // 无答案则先跳过
            return;
        }
        // 判断是否都已批改过
        let evaluated = true;
        let all_finished = true;
        for (const [sectionStr, questionTasks] of Object.entries(questionContent)) {
            if (['speaking', 'writing'].includes(sectionStr)) {
                for (const [idx, question] of Object.entries(questionTasks.tasks)) {
                    const partIdxV = Number(idx) + 1;
                    const answerData = answerContent?.[sectionStr]?.[partIdxV]?.['answer'];
                    const gid = answerContent?.[sectionStr]?.[partIdxV]?.['gid'];
                    const status = answerContent?.[sectionStr]?.[partIdxV]?.['status'];
                    console.log('status', status)
                    if (!['evaluated', 'finished'].includes(status) && (answerData || gid)) { // 有答案内容，但没有批改完成
                        evaluated = false
                        break; // 退出内层循环
                    }else if (status !== 'finished'){
                        all_finished = false
                    }
                }
                if (!evaluated) {
                    break; // 退出外层循环
                }
            }
        }
        console.log('evaluated', evaluated)
        if (all_finished){ // 所有的题目都已批改完成，则打印信息
            mixpanel.track('Mock Test', {
                practiceType: practiceType,
                section: 'all',
                status: "finished"
            });
        }
        if (evaluated) { // 全部已提交批改完成
            setIsWaiting(false)
            return;
        }else if (midToken) { // 跳过
            return;
        }
        // 付费，避免多次付费和批改
        if (practiceType !== PracticeTypeEnum.FULL_MOCK_TEST) {
            setMidToken('paid')
        } else { // 全科模考扣费
            setMidToken('paying')
        }
    }, [answerContent, midToken, mockId]);


    useEffect(() => {
        // 答案以保存完整或已付费 则批改
        if (!midToken) {
        } else if (midToken === 'paying') {
            mockPaid();
        } else {
            // console.log('midToken', midToken)
            evaluate(midToken === 'paid' ? '' : midToken)
        }
    }, [midToken]);


    useEffect(() => {
        if (isWaiting) {
            return;
        }
        mixpanel.track('Mock Test', {
            practiceType: practiceType,
            section: curSection,
            status: "resultTab"
        });
    }, [curSection, isWaiting]);


    function parseUrl() {
        const pathParts = location.pathname.split('/'); // 分割路径
        const [tpo_idx, section_str, qid] = pathParts.slice(2, 5)
        const [questionSet, tpoNum] = tpo_idx.split('_'); // tpo_idx -- "questionBack_*"
        const section = section_str.split('_').pop();
        const practiceType = section_str.includes('_') ? PracticeTypeEnum.FULL_MOCK_TEST : qid === "all" ? "mock_test" : "practice";
        const searchParams = new URLSearchParams(location.search);
        // 阅读、听力单任务练习、单科练习历史记录查看
        const answerJsonString = searchParams.get('answer_json'); // 获取 answer_json 参数
        const viewAnswerSection = searchParams.get('view_section'); // 获取 answer_json 参数
        const answerJson = answerJsonString ? JSON.parse(decodeURIComponent(answerJsonString)) : null;
        // 全科模考历史记录查看
        const mid = searchParams.get('mid');
        let sections;
        if (practiceType === PracticeTypeEnum.FULL_MOCK_TEST) {
            sections = ['reading', 'listening', 'speaking', 'writing'];
        } else {
            sections = ['reading', 'listening', 'writing', 'speaking'].filter(
                (section) => section_str.includes(section)
            );
        }
        return {questionSet, tpoNum, section, practiceType, answerJson, sections, mid, viewAnswerSection};

    }


    function evaluate(mid_toke = '') {
        // console.log('questionContent', questionContent)
        Object.entries(questionContent).map(([sectionStr, questionTasks]) => {
            if (['speaking', 'writing'].includes(sectionStr)) {
                Object.entries(questionTasks.tasks).map(([idx, question]) => {
                    const partIdxV = Number(idx) + 1
                    const answerData = answerContent?.[sectionStr]?.[partIdxV]?.['answer'] || '';
                    const gid = answerContent?.[sectionStr]?.[partIdxV]?.['gid'];
                    const status = answerContent?.[sectionStr]?.[partIdxV]?.['status'];
                    // console.log('status', status)
                    // console.log('answerData', answerData)
                    if (!['evaluated', 'finished'].includes(status) && (answerData || gid)) { //答案未批改成功则进行重新批改
                        handleSubmit(sectionStr, question, answerData, partIdxV, mid_toke);
                    }
                })
            }
        })
    }


    const handleSubmit = (sectionStr, question, data, partIdxV, mid_token) => {
        // console.log('handleSubmit', sectionStr, question, data, partIdxV, mid_token)
        const gidV = answerContent?.[sectionStr]?.[partIdxV]?.['gid'] || '';
        let headers = authHeader();
        const params = {
            'type': question.gen_type,
            'topic': question.question,
            'reading': typeof question.reading_texts === 'string' ? question.reading_texts : (question.reading_texts || []).join(''),
            'listening': typeof question.listening_texts === 'string' ? question.listening_texts : (question.listening_texts || []).join(''),
            'tpo_number': question.tpo_number,
            "qid": question.qid,
            'gid': gidV,
            'structured': '1',
            'authorization': headers['Authorization'],
            'lang': i18n.language,
            'example_level': "",
            // 'mid_token': ''
            'mid_token': mid_token
        };
        if (question.section === 'speaking') {
            params['audio'] = data;
            params['audioSource'] = "RECORDER";
        } else if (question.gen_type === 'writing_task1') {
            params['content'] = data;
        } else { // 'writing_task3'
            params['content'] = data;
            params['conversations'] = JSON.stringify({
                "0": [question.discussion1_name, question.discussion1_content],
                "1": [question.discussion2_name, question.discussion2_content],
                "2": [question.discussion3_name, question.discussion3_content]
            }, null, 2);
        }
        let url = WS_URL.replace("https://", "wss://").replace("http://", "ws://");
        url = url + "/api/generate_ws";
        const ws = new WebSocket(url);
        ws.onopen = (event) => {
            ws.send(JSON.stringify(params));
        };

        let gid;
        let score = 0;
        let evaluationAt = Math.floor(Date.now() / 1000);
        ws.onmessage = function (event) {
            let data = JSON.parse(event.data);
            if (data.t === "metadata") {
                gid = data.c.gid;
            } else {
                let parsedData
                try {
                    parsedData = JSON.parse(data.c);
                } catch (error) {
                    console.error("Failed to parse WebSocket message:", error);
                    return;
                }
                if (parsedData?.score) {
                    score = parsedData.score;
                    setAnswerContent(prevAnswerContent => {
                        const updatedWriting = {
                            ...prevAnswerContent?.[question.section],
                            [partIdxV]: {
                                ...(prevAnswerContent[question.section]?.[partIdxV] || {}),
                                status: 'evaluated',
                                gid: gid,
                                score: parsedData.score,
                                evaluationAt: evaluationAt
                            }
                        };
                        return {
                            ...prevAnswerContent,
                            [question.section]: updatedWriting
                        };
                    });
                }
            }
        };

        ws.onclose = function (event) {
            console.log("WebSocket close");
            console.log("WebSocket gid: ", gid);
            if (gid) {
                // saveUserAnswer(question.section, {
                //     [question.qid]: {
                //         'gid': gid,
                //         'score': score,
                //         'evaluationAt': evaluationAt
                //     }
                // });
                setAnswerContent(prevAnswerContent => {
                    const updatedWriting = {
                        ...prevAnswerContent?.[question.section],
                        [partIdxV]: {
                            ...(prevAnswerContent[question.section]?.[partIdxV] || {}),
                            status: 'finished',
                        }
                    };
                    return {
                        ...prevAnswerContent,
                        [question.section]: updatedWriting
                    };
                });
            } else { //异常结束
                setAnswerContent(prevAnswerContent => {
                    const updatedWriting = {
                        ...prevAnswerContent?.[question.section],
                        [partIdxV]: {
                            ...(prevAnswerContent[question.section]?.[partIdxV] || {}),
                            status: 'evaluated',
                        }
                    };
                    return {
                        ...prevAnswerContent,
                        [question.section]: updatedWriting
                    };
                });
            }
        };

        ws.onerror = function (error) {
            console.log("WebSocket error: ", error);
        };
    }


    const mockPaid = () => {
        try {
            let headers = authHeader();
            axios.post(API_URL + '/api/mock_test_paid', {
                mid: mockId,
            }, {headers: headers}).then(response => {
                if (response.status === 200) {
                    console.log(`${mockId}: Paid  successfully`);
                    setMidToken(response.data.mid_token)
                }
            }).catch(error => {
                console.log(`${mockId}:  Error Evaluate`, error);
                toast({
                    title: "Failed to evaluate",
                    description: error.message,
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });
            });
        } catch (error) {
            console.error("Failed to to evaluate:", error);
            toast({
                title: "Failed to to evaluate",
                description: error.message,
                status: "error",
                duration: 2000,
                isClosable: true,
            });
        }
    };

    const saveUserAnswer = async (sectionStr, answer_json, mock_json = null) => {
        try {
            let headers = authHeader();
            await axios.post(API_URL + '/api/save_answer', {
                answer_json: answer_json,
                practice_type: practiceType,
                question_id: tpoNum,
                question_set: questionSet,
                section: sectionStr,
                test: 'toefl',
                mid: mockId,
                mock_json: mock_json || {}
            }, {headers: headers}).then(response => {
                if (response.status === 201) {
                    console.log(`${tpoNum}-${sectionStr}: Answer saved successfully`);
                    return true
                }
            }).catch(error => {
                console.log(`${tpoNum}-${sectionStr}: Error saving answer`, error);
                toast({
                    title: "Failed to save answer",
                    description: error.message,
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });
            });
        } catch (error) {
            console.error("Error saving answer:", error);
            toast({
                title: "Failed to save answer",
                description: error.message,
                status: "error",
                duration: 2000,
                isClosable: true,
            });
        }
        return false
    };

    return (
        <Box margin="auto" bg={'gray.100'} width={'99%'}>
            {isWaiting ?
                <ToeflWaitingPage/>
                : <VStack>
                    {!viewAnswerSection && <VStack>
                        <Tabs variant='enclosed' bg={'white'} borderRadius={'md'}
                              display={practiceType === PracticeTypeEnum.FULL_MOCK_TEST ? 'flex' : 'none'}>
                            <TabList alignItems="center" justifyContent="center">
                                <Tab key={-1} onClick={() => setCurSection('scoreReport')}>
                                    <FaHeadphonesAlt color='inherit'/>
                                    <Box width={'4px'}></Box>
                                    {t('score_report')}
                                </Tab>
                                {sections.map((sectionObj, index) => {
                                    let icon;
                                    if (sectionObj === 'listening') {
                                        icon = <FaHeadphonesAlt color='inherit'/>;
                                    } else if (sectionObj === 'reading') {
                                        icon = <FaReadme color='inherit'/>;
                                    } else if (sectionObj === 'speaking') {
                                        icon = <FiMic color='inherit'/>;
                                    } else if (sectionObj === 'writing') {
                                        icon = <FiFileText color='inherit'/>;
                                    }
                                    return (
                                        <Tab key={index} onClick={() => setCurSection(sectionObj)}>
                                            {icon} <Box width={'4px'}></Box> {t(sectionObj)}
                                        </Tab>
                                    );
                                })}
                            </TabList>
                        </Tabs>
                        <Divider borderWidth={1}
                                 display={practiceType === PracticeTypeEnum.FULL_MOCK_TEST ? 'flex' : 'none'}/>
                    </VStack>}
                    {curSection.includes('scoreReport') &&
                        <ToeflScoreReport
                            sections={sections}
                            questionSet={questionSet}
                            tpoNum={tpoNum}
                        />}
                    {curSection.includes('reading') && <ToeflReadingAnswer/>}
                    {curSection.includes('listening') && <ToeflListeningAnswer/>}
                    {curSection.includes('speaking') && <ToeflSpeakingAnswer/>}
                    {curSection.includes('writing') && <ToeflWritingAnswer/>}
                </VStack>}
        </Box>
    );
}

export default ToeflAnswer;
