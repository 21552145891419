import React from 'react';
import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text,} from '@chakra-ui/react';

const FinishWarning = ({isOpen, onClose, onReturn, onContinue, section}) => {
    const capitalizeFirstLetter = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1);
    };


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Finish Warning</ModalHeader>
                <ModalBody>
                    <Text>You have seen all of the questions in this part of
                        the {capitalizeFirstLetter(section)} Section.</Text>
                    <Text>You may go back and Review. As long as there is time remaining, you can check your
                        work.</Text>
                    <Text>Click on Return to continue working.</Text>
                    <Text>Click on Continue to go on.</Text>
                    <Text>Once you leave this part of the {capitalizeFirstLetter(section)} section, you WILL NOT be able
                        to return on
                        it.</Text>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" onClick={onReturn}>RETURN</Button>
                    <Button colorScheme="green" onClick={onContinue} ml={3}>CONTINUE</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default FinishWarning;
