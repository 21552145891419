import {Box, Divider, Heading, Text, VStack} from '@chakra-ui/react';
import React, {useEffect, useRef} from "react";
import {ToeflIbtContext} from "./ToeflIbtContext";
import {useHistory} from "react-router-dom";

function ToeflReadingDirections() {
    const directionsAudio = "https://lingoleapstorage1.blob.core.windows.net/sm-audio/reading_section_directions.mp3";
    const audioRef = useRef(null); // 创建 ref
    const {volume} = React.useContext(ToeflIbtContext);
    const history = useHistory();
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume; // 设置音量
        }
    }, [volume]);

    const handleAudioEnded = () => {
        const parentPath = location.pathname.split("/directions")[0]
        const nextUrl = `${parentPath}/text/1`;
        history.replace(nextUrl);
    };


    return (
        <Box maxWidth="860px" margin="auto" padding="4">
            <VStack spacing={8} align="stretch">
                <audio
                    src={directionsAudio}
                    autoPlay={true} // 自动播放音频
                    ref={audioRef} // 使用 ref 来控制音频
                    onEnded={handleAudioEnded}
                    style={{display: 'none'}} // 隐藏音频控件
                />
                <Heading as="h1" size="lg" textAlign="left" style={{fontWeight: 'normal'}}>
                    Reading Section Directions
                </Heading>
                <Divider borderWidth={1}/>
                <Text>
                    In this section, you will be able to demonstrate your ability to understand academic passages in
                    English. You will read and answer questions about <span style={{fontWeight: 'bold'}}>two
                    passages</span>. A clock will indicate how much time remains.
                </Text>
                <Text>
                    Some passages may include an underlined word or phrase. Select the word or phrase to see a
                    definition, an explanation, or an illustration.
                </Text>
                <Text>
                    Move to the next question by selecting <span style={{fontWeight: 'bold'}}>Next</span>. You can skip
                    questions and go back to them later if there is time remaining. Return to previous screens by
                    selecting <span style={{fontWeight: 'bold'}}>Back</span>
                </Text>
                <Text>
                    Select <span style={{fontWeight: 'bold'}}>Review Questions</span> at any time to see which
                    questions you have answered and which questions you have not answered. From the Review screen, you
                    may go only to questions you have already encountered.
                </Text>
                <Text>
                    Select <span style={{fontWeight: 'bold'}}>Continue</span> to go on.
                </Text>
            </VStack>
        </Box>
    );
}

export default ToeflReadingDirections;
