import React, {useCallback, useEffect, useMemo, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useDispatch, useSelector} from "react-redux";
import {debounce} from 'lodash';

import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Badge,
    Box,
    Button,
    Center,
    Fade,
    Flex,
    SimpleGrid,
    Skeleton,
    Stack,
    Text,
    Textarea,
} from '@chakra-ui/react'
import {
    clearAnswer,
    clearQuestionAndAnswer,
    loadQidQuestionData,
    selectMarkdownResponse,
    selectToeflData,
    updateAnswer,
    updateAnswerOutline,
    updateAudioFile,
    updateAudioSource
} from 'redux/toeflSlice';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import ConversationTable from './ConversationTable';
import ToeflSubmitButton from './ToeflSubmitButton';
import {ShareResultButton} from "./ShareResultButton";
import {ResponsePanel} from "./ResponsePanel";
import {AuthorizedBackendGetRequest, getGenTypeFromSectionAndTaskName} from "./utils/common";
import AudioRecorder from "./AudioRecorder";
import LLAudioPlayer from "./LLAudioPlayer";
import NewUserPopover from "./NewUserPopover";
import UploadAudioFileButton from "./UploadAudioFileButton";

//import SaveButton from "./SaveButton";

function TextSection(props) {
    var {title, content, isVisible, audio, ...other} = props;
    const {t} = useTranslation();
    return (
        <Flex display={isVisible ? "block" : "none"}>
            <Flex flexDirection={"row"} margin={0}>
                <Text fontWeight={600} fontSize="xl" mt="10px" color={'gray.700'} paddingLeft={3}>{title}
                </Text>
                <Box flex={"1"}></Box>
            </Flex>
            {
                audio ? (
                    <>
                        <Center>
                            <LLAudioPlayer src={audio} autoPlay controls></LLAudioPlayer>
                        </Center>
                        <Accordion allowMultiple>
                            <AccordionItem borderRadius={10} m={10}>

                                <AccordionButton
                                    _expanded={{bg: 'blue.500', color: 'white'}}
                                    _hover={{bg: 'blue.100'}}
                                    borderRadius={10}
                                >
                                    <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
                                        {t('listening_text')}
                                    </Box>
                                    <AccordionIcon/>
                                </AccordionButton>

                                <AccordionPanel pb={4} bg='gray.50' borderLeft='4px' borderColor='blue.500' pl={2}
                                >
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: typeof content === 'string'
                                                ? content.replace(/\n/g, '<br/><br/>')
                                                : ''
                                        }}
                                        style={{whiteSpace: 'pre-line'}}
                                    />
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </>
                ) : (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: typeof content === 'string'
                                ? content.replace(/\n/g, '<br/><br/>')
                                : ''
                        }}
                        style={{whiteSpace: 'pre-line'}}
                    />
                )
            }


        </Flex>
    )
}

export default function TPOPracticePanel(props) {
    var {isCorpusGeneration, ...other} = props;
    const url = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;
    const query = new URLSearchParams(window.location.search);
    const section = location.pathname.includes('writing') ? "writing" : "speaking";
    const task_name = query.get("task_name")
    const tpo_number = query.get("tpo_number")
    const qid = query.get("qid")
    const gid = query.get("gid")
    const structured = query.get("structured")

    const [questionLoaded, setQuestionLoaded] = useState(false);

    const dispatch = useDispatch();
    const toeflData = useSelector(selectToeflData);
    const [lastSavedAt, setLastSavedAt] = useState(null);
    const [saveNotification, setSaveNotification] = useState('');
    const [isNotificationVisible, setIsNotificationVisible] = useState(false);

    const isSpeakingTask = toeflData.section === 'speaking';

    // Function to show save/load notification
    const showSaveNotification = useCallback((action) => {
        setSaveNotification(`${isSpeakingTask ? 'Audio' : 'Answer'} ${action}`);
        setIsNotificationVisible(true);
        setTimeout(() => {
            setIsNotificationVisible(false);
        }, 2000);
    }, [isSpeakingTask]);

    // Load saved content immediately
    useEffect(() => {
        const key = isSpeakingTask ? `toefl_audio_${qid}` : `toefl_answer_${qid}`;
        const savedContent = localStorage.getItem(key);
        console.log(`Attempting to load saved ${isSpeakingTask ? 'audio' : 'answer'}. Key:`, key);
        if (savedContent) {
            console.log(`Found saved ${isSpeakingTask ? 'audio' : 'answer'}`);
            if (isSpeakingTask) {
                dispatch(updateAudioFile(savedContent));
                dispatch(updateAudioSource("FILE"));
            } else {
                dispatch(updateAnswer(savedContent));
            }
            setLastSavedAt(new Date());
            showSaveNotification('loaded');
        } else {
            console.log(`No saved ${isSpeakingTask ? 'audio' : 'answer'} found`);
        }
    }, [qid, dispatch, isSpeakingTask, showSaveNotification]);

    // Function to save the audio to localStorage
    const saveAudio = (audioData) => {
        if (isSpeakingTask) {
            const key = `toefl_audio_${qid}`;
            localStorage.setItem(key, audioData);
            console.log("Saved audio");
            setLastSavedAt(new Date());
            showSaveNotification('saved');
        }
    };

    // Debounced version of saveAudio
    const debouncedSaveAudio = useCallback(
        debounce((audioData) => saveAudio(audioData), 1000),
        [qid, isSpeakingTask, showSaveNotification]
    );

    // Load saved answer immediately
    const initialAnswer = useMemo(() => {
        const key = `toefl_answer_${qid}`;
        const savedAnswer = localStorage.getItem(key);
        console.log("Attempting to load saved answer. Key:", key);
        if (savedAnswer) {
            console.log("Found saved answer:", savedAnswer);
            dispatch(updateAnswer(savedAnswer));
            setLastSavedAt(new Date());
            return savedAnswer;
        } else {
            console.log("No saved answer found");
            return '';
        }
    }, [qid, dispatch]);

    // Function to save the answer to localStorage
    const saveAnswer = (answer) => {
        const key = `toefl_answer_${qid}`;
        localStorage.setItem(key, answer);
        console.log("Saved answer:", answer);
        setLastSavedAt(new Date());
        showSaveNotification('saved');
    };

    // Debounced version of saveAnswer
    const debouncedSaveAnswer = useCallback(
        debounce((answer) => saveAnswer(answer), 2000),
        [qid, showSaveNotification]
    );

    useEffect(() => {
        if (!query.get("noreset")) {
            dispatch(clearQuestionAndAnswer())
        }
        AuthorizedBackendGetRequest(`/api/get_question_by_qid?qid=${qid}&gid=${gid}`)
            .then(function (response) {
                console.log(response.data, "response data");
                dispatch(loadQidQuestionData({
                    ...response.data,
                    answer: toeflData.answer
                }));
                setQuestionLoaded(true);
            }).catch(function (error) {
        });
    }, [])

    let history = useHistory();
    const {t, i18n} = useTranslation();
    const [isRecording, setIsRecording] = useState(false);
    const markdownResponse = useSelector(selectMarkdownResponse);

    let answer = toeflData.answer ? toeflData.answer : "";

    let wordCount = answer.trim() === "" ? 0 : answer.trim().split(/\s+/).length;
    let showReading, showListening;
    const isDiscussion = (toeflData.section == "writing" && toeflData.taskName == "task3")

    let isSpeaking = section === 'speaking';
    let isWriting = section === 'writing';

    let showAnswer = isWriting && !isCorpusGeneration;
    let showRecordingFunctions = isSpeaking && !isCorpusGeneration;

    if (isSpeaking) {
        showReading = (toeflData.taskName !== 'task1' && toeflData.taskName !== 'task4');
        showListening = toeflData.taskName !== 'task1';
    } else if (isWriting) {
        showReading = (toeflData.taskName == 'task1');
        showListening = toeflData.taskName == 'task1';
    } else {
        showReading = false;
        showListening = false;
    }

    const banner = () => {
        let backRoute = isSpeaking ? "/toefl/speaking" : "/toefl/writing"
        return (
            <Flex
                flexDirection="row"
                mx={5}
                px={2}
            >
                <Flex
                    flexDirection="column"
                    alignItems="start"
                    spacing={3}
                >
                    <Center>
                        <Text fontSize="xl" fontWeight="bold" mb={1}>
                            {tpo_number || toeflData.tpoNumber ? `TPO${tpo_number || toeflData.tpoNumber} ${t(toeflData.genType)}` : `${toeflData.bundleName} ${t(toeflData.genType)}`}
                        </Text>
                    </Center>
                </Flex>

                <Box flex="1"></Box>

                <Center>
                    <Button colorScheme={'yellow'} rounded="full" onClick={() => {
                        dispatch(clearAnswer());
                        history.push(backRoute);
                    }}>
                        {t("exit")}
                    </Button>
                </Center>
            </Flex>
        );
    };

    const getTaskPanel = () => {
        return (
            <>
                <TextSection
                    isVisible={showReading}
                    title={t('reading')}
                    content={toeflData.readingText}></TextSection>
                <TextSection
                    isVisible={showListening}
                    title={t('listening')}
                    audio={
                        toeflData.listeningAudio && toeflData.listeningAudio.startsWith("http") ?
                            toeflData.listeningAudio : toeflData.listeningAudioPath}
                    content={toeflData.listeningText}></TextSection>
                <TextSection
                    isVisible={true}
                    title={t('question_text')}
                    content={toeflData.question}></TextSection>
                <Flex mt={"10px"} display={isDiscussion ? "block" : "none"}>
                    <Flex flexDirection={"row"} margin={0}>
                        <Text fontWeight={600} fontSize="xl" mt="10px" color={'gray.700'}
                              paddingLeft={3}>{t('writing_discussion')}
                        </Text>
                        <Box flex={"1"}></Box>
                    </Flex>
                    <ConversationTable dataType={'tpo'}/>
                </Flex>

                <Flex flexDirection={"row"} margin={0} display={isCorpusGeneration ? "block" : "none"}>
                    <Text mt={"10px"} fontWeight={600} fontSize="xl" mt="10px" color={'gray.700'}
                          paddingLeft={3}>{t('direction')}
                    </Text>
                    <Box flex={"1"}></Box>
                </Flex>
                <Textarea
                    onChange={(e) => {
                        dispatch(updateAnswerOutline(e.target.value))
                    }} value={toeflData.answerOutline}
                    display={isCorpusGeneration ? "block" : "none"}
                    height="200px"
                    placeholder={toeflData.tabIndex == 2 ?
                        t("toefl_placeholder_message2")
                        : ""}
                    spellcheck="false"
                ></Textarea>
                <Flex>
                    <Flex flexDirection={"row"} margin={0} display={showAnswer ? "block" : "none"}>
                        <Text mt={"10px"} fontWeight={600} fontSize="xl" mt="10px" color={'gray.700'}
                              paddingLeft={3}>{t('answer')}
                        </Text>
                        <Box flex={"1"}></Box>
                    </Flex>
                </Flex>
                <Box position="relative" display={showAnswer ? "block" : "none"}>
                    <Textarea
                        rows={6}
                        onChange={(e) => {
                            const newAnswer = e.target.value;
                            dispatch(updateAnswer(newAnswer));
                            debouncedSaveAnswer(newAnswer);
                        }}
                        value={toeflData.answer}
                        height="400px"
                        spellCheck="false"
                        placeholder="Start typing your answer..."
                    />
                    <Fade in={isNotificationVisible} unmountOnExit>
                        <Text
                            position="absolute"
                            bottom="10px"
                            right="10px"
                            fontSize="sm"
                            color="gray.500"
                            bg="white"
                            px={2}
                            py={1}
                        >
                            {saveNotification}
                        </Text>
                    </Fade>
                </Box>
                <Flex mt="10px">
                    <Box flex="1"></Box>
                    <Center>
                        <Badge
                            variant='outline' colorScheme='blue' mr="10px" borderRadius="full"
                            display={toeflData.selectedExampleLevel && toeflData.section == "writing" ? "block" : "none"}
                        >
                            {t("example_essay")}
                        </Badge>
                    </Center>

                    <Text align={"right"} color={'grey'} display={(isWriting && !isCorpusGeneration) ? "block" : "none"}
                          p="0" m="0">words: {wordCount}</Text>
                </Flex>

            </>
        );
    }

    const getMainUI = (props) => {
        return (
            <Flex direction={'column'} gap={5} p={5}>
                {getTaskPanel()}
                <Flex ml="10px"
                      display={toeflData.taskName == "task1" && toeflData.tpoNumber == 1 ? "block" : "none"}>
                    <Center>
                        <NewUserPopover></NewUserPopover>
                    </Center>
                </Flex>
                <Box display={showRecordingFunctions ? "block" : "none"}>
                    <Center>
                        <Box display={toeflData.audioFile && !isRecording ? "inline-block" : "none"}>
                            <SimpleGrid columns={3} columnGap='25px'>
                                <Center>
                                    <Text fontWeight="bold" fontSize="md" color="blue.500">
                                        {t("your_answer")}
                                    </Text>
                                </Center>
                                <Center>
                                    <LLAudioPlayer src={toeflData.audioFile} autoPlay controls></LLAudioPlayer>
                                </Center>
                                {
                                    toeflData.selectedExampleLevel ?
                                        <Center>
                                            <Badge
                                                variant='outline' colorScheme='green' ml="10px" borderRadius="full"
                                                display={toeflData.selectedExampleLevel && toeflData.section == "speaking" ? "block" : "none"}
                                            >
                                                {toeflData.audioFile ? t("example_recording") : t("example_recording_loading")}
                                            </Badge>
                                        </Center> :
                                        <Center>
                                            {/* <SaveButton gid={gid} qid={qid}></SaveButton> */}
                                        </Center>
                                }
                            </SimpleGrid>
                        </Box>
                    </Center>
                </Box>
                <Box display={showRecordingFunctions ? "block" : "none"}>
                    <Center>
                        <SimpleGrid columns={3} columnGap='25px'>
                            <Center>
                                <UploadAudioFileButton
                                    onLoadEnd={(data) => {
                                        dispatch(updateAudioFile(data));
                                        dispatch(updateAudioSource("FILE"));
                                        debouncedSaveAudio(data);
                                    }}
                                ></UploadAudioFileButton>
                            </Center>
                            <Center>
                                <AudioRecorder onAudioSave={(data) => {
                                    dispatch(updateAudioFile(data));
                                    dispatch(updateAudioSource("RECORDER"));
                                    debouncedSaveAudio(data);
                                }} isRecording={isRecording} setIsRecording={setIsRecording}
                                               showRecordingFunctions={true}/>
                            </Center>
                            <Center>
                                <ToeflSubmitButton
                                    genType={getGenTypeFromSectionAndTaskName(toeflData.section, toeflData.taskName, isCorpusGeneration)}
                                    qid={qid}
                                    gid={gid}
                                    structured={structured}
                                ></ToeflSubmitButton>
                            </Center>
                        </SimpleGrid>
                    </Center>
                </Box>
                <Box display={!showRecordingFunctions ? "block" : "none"}>
                    <Center>
                        <SimpleGrid columns={toeflData.selectedExampleLevel || !toeflData.answer ? 1 : 2}
                                    columnGap='5px'>
                            <Center>
                                <ToeflSubmitButton
                                    genType={getGenTypeFromSectionAndTaskName(toeflData.section, toeflData.taskName, isCorpusGeneration)}
                                    qid={qid}
                                    gid={gid}
                                    structured={structured}
                                ></ToeflSubmitButton>
                            </Center>
                            {toeflData.selectedExampleLevel || !toeflData.answer ? "" : <Center>
                                {/* <SaveButton gid={gid} qid={qid}></SaveButton> */}
                            </Center>
                            }

                        </SimpleGrid>
                    </Center>
                </Box>
            </Flex>
        );
    }

    return (
        <>
            <Flex direction={'column'}>
                {/* {banner()} */}
                <Stack m="20px" display={questionLoaded ? 'none' : 'block'}>
                    <Skeleton height='20px'/>
                    <Skeleton height='20px'/>
                    <Skeleton height='20px'/>
                </Stack>
                <Box display={questionLoaded ? 'block' : 'none'}>
                    {getMainUI()}
                    <ResponsePanel></ResponsePanel>
                    <Center mt="10px">
                        <ShareResultButton
                            visible={markdownResponse.result && !toeflData.isStreaming}></ShareResultButton>
                    </Center>
                </Box>
            </Flex>
        </>
    );
}



