import React, {useEffect, useState} from 'react';
import {ToeflIbtContext} from "./ToeflIbtContext";
import {Box, Button, Divider, Flex, Icon, Tab, TabList, Tabs, Text} from '@chakra-ui/react';
import {ToeflReadingQuestionRenderReadingText} from "./ToeflReadingQuestionRenderReadingText";
import {ToeflReadingQuestionAnswerSetting} from "./ToeflReadingQuestionAnswerSetting";
import {ToeflReadingQuestionDragAndDrop} from "./ToeflReadingQuestionDragAndDrop";
import {FaCheck, FaTimes} from "react-icons/fa";
import {useTranslation} from "react-i18next";

function ToeflReadingAnswer() {
    const [partIdx, setPartIdx] = useState(1);
    const [subIdx, setSubIdx] = useState('1');
    const [question, setQuestion] = useState(null);
    const {questionContent, answerContent} = React.useContext(ToeflIbtContext);
    const [taskAnswer, setTaskAnswer] = useState(null);
    const {t, i18n} = useTranslation();

    useEffect(() => {
        const taskObj = questionContent?.["reading"]?.tasks?.[partIdx - 1];
        if (taskObj) {
            const questions = taskObj.questions;
            const answers = {};
            questions.forEach((q) => {
                answers[q.sub_question_idx] = q.sub_question_answer; // 假设每个问题对象有一个 answer 属性
            });
            setTaskAnswer(answers);
            setSubIdx(questions[0].sub_question_idx.toString());
        }
    }, [partIdx, questionContent]);

    useEffect(() => {
        getQuestionInfo();
    }, [subIdx, questionContent]);

    function getQuestionInfo() {
        const taskObj = questionContent?.['reading']?.tasks?.[partIdx - 1];
        if (taskObj && taskObj.questions) {
            for (const questionObj of taskObj.questions) {
                if (questionObj.sub_question_idx.toString() === subIdx.toString()) {
                    setQuestion(questionObj);
                    // console.log(questionObj);
                    return;
                }
            }
        }
    }


    function isEqual(answer1, answer2) {
        const preprocess = (value, needSort = false) => {
            if (Array.isArray(value)) {
                value = value.join("");
            }
            // console.log(value);
            if (value.includes('#')) {
                let vStrs = '';
                value.split("#").map((vStr) => {
                    vStrs += vStr.split("").sort().join("");
                    // vStrs += '#'
                })
                // console.log("#", value);
                return vStrs;
            } else if (needSort) {
                // console.log("needSort", value);
                // console.log("needSort", value.split("").sort().join(""));
                return value.split("").sort().join("");
            } else {
                return value;
            }
        };
        const processedAnswer1 = preprocess(answer1 || '', true);
        const processedAnswer2 = preprocess(answer2 || '');
        // console.log(processedAnswer1, processedAnswer2);
        return processedAnswer1 === processedAnswer2;
    }

    return (
        <Box margin="auto" padding="1" bg={'gray.100'} height={"89vh"} width={'100%'}>
            <Tabs variant='enclosed' bg={'white'} borderRadius={'md'} fitted={"true"}>
                <TabList>
                    {questionContent?.['reading']?.tasks.map((task, index) => (
                        index <= 1 && (
                            <Tab key={index} onClick={() => setPartIdx(index + 1)}>
                                Part {index + 1}
                            </Tab>
                        )
                    ))}
                </TabList>
            </Tabs>
            <Divider borderWidth={1}/>
            <Flex direction='row' height={'100%'}>
                <Box width={'50%'} flex='1' p={2} borderRadius={'md'} bg={'white'}>
                    <ToeflReadingQuestionRenderReadingText
                        textArray={question?.reading_texts}
                        questionText={question?.sub_question_texts}
                    />
                </Box>
                <Box width={'50%'} flex='1' p={2} borderRadius={'md'} bg={'white'} height={'100%'}>
                    <Box>
                        {questionContent?.['reading']?.tasks && partIdx > 0 && partIdx <= questionContent['reading'].tasks.length ? (
                            (questionContent['reading'].tasks[partIdx - 1]?.questions || []).map(q => {
                                    return (
                                        <Button key={q.sub_question_idx} onClick={() => {
                                            setSubIdx(q.sub_question_idx.toString());
                                        }}
                                                mr={3}
                                                bg={subIdx === q.sub_question_idx.toString() ? 'teal' : 'gray.300'} // 背景色变更
                                                color={subIdx === q.sub_question_idx.toString() ? 'white' : 'black'} // 文本颜色变更
                                                size='sm'
                                        >
                                            Q {q.sub_question_idx}
                                            {taskAnswer?.[q.sub_question_idx] !== undefined &&
                                            isEqual(answerContent?.["reading"]?.[q.sub_question_idx], taskAnswer[q.sub_question_idx]) ? (
                                                    <Box
                                                        position="absolute"
                                                        top={0}
                                                        right={0}
                                                        mt={-2}
                                                        mr={-2}
                                                        color={'green.300'}>
                                                        <Icon as={FaCheck} fontSize={'xl'}/>
                                                    </Box>
                                                ) :
                                                (
                                                    <Box
                                                        position="absolute"
                                                        top={0}
                                                        right={0}
                                                        mt={-2}
                                                        mr={-2}
                                                        textColor={'red.300'}
                                                    >
                                                        <Icon as={FaTimes} fontSize={'xl'}/>
                                                    </Box>
                                                )}
                                        </Button>
                                    )
                                }
                            )
                        ) : null}

                    </Box>
                    {question && (
                        <Box mt={4} overflowY="auto" height={'95%'}>
                            {(!question?.sub_question_texts || !question?.sub_question_texts.includes("●")) ?
                                <ToeflReadingQuestionAnswerSetting
                                    subQuestionOptions={question?.sub_question_options}
                                    answerExplain={question?.sub_question_answer}
                                    questionText={question?.sub_question_texts}
                                    readTexts={question?.reading_texts}
                                /> :
                                <ToeflReadingQuestionDragAndDrop
                                    questionText={question?.sub_question_texts}
                                    subQuestionOptions={question?.sub_question_options}
                                />

                            }
                            <Flex direction={"row"} mt={2} fontSize={'xl'}>
                                <Text mr={2}>{t("your_answer")}:</Text>
                                <Text
                                    color={isEqual(answerContent?.["reading"]?.[subIdx], question.sub_question_answer) ? 'green' : 'red'}>
                                    {Array.isArray(answerContent?.["reading"]?.[subIdx]) ? answerContent?.["reading"]?.[subIdx] : answerContent?.["reading"]?.[subIdx] || t("unanswered")}
                                </Text>
                            </Flex>
                            <Text fontSize={'xl'} color={'teal'}
                                  mt={2} mb={2}>{t("correct_answer")}: {question.sub_question_answer}</Text>
                            {i18n.language === 'cn' && <Text>解析: {question.sub_question_analysis}</Text>}
                        </Box>
                    )}
                </Box>
            </Flex>
        </Box>
    );
}

export default ToeflReadingAnswer;
