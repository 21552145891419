import React, { useState } from "react";
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useToast,
    VStack,
    HStack,
    Badge
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useRedeemRedemptionCodeMutation } from "../../services/cmApi";

// 使用cmApi中的兑换码API

export function RedeemCodeModal({ isOpen, onClose, onSuccess }) {
    const { t } = useTranslation();
    const toast = useToast();
    const [code, setCode] = useState("");
    const [redeemResult, setRedeemResult] = useState(null);
    
    // 使用RTK Query的兑换码API
    const [redeemCode, { isLoading: loading }] = useRedeemRedemptionCodeMutation();

    // 处理兑换码兑换
    const handleRedeem = async () => {
        if (!code.trim()) {
            toast({
                title: "请输入兑换码",
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            // 调用后端API兑换积分
            const response = await redeemCode({ code: code.trim() }).unwrap();
            
            // 处理成功响应
            if (response.success) {
                const result = {
                    success: true,
                    message: response.message,
                    credit_type: response.data?.code_type || '',
                    credit_amount: response.data?.credit_amount || 0
                };
                
                setRedeemResult(result);
                
                if (onSuccess) {
                    onSuccess(result);
                }
                
                toast({
                    title: "兑换成功",
                    description: response.message,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                // 处理业务逻辑失败
                setRedeemResult({
                    success: false,
                    message: response.message
                });
                
                toast({
                    title: "兑换失败",
                    description: response.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            // 处理API调用异常
            const errorMessage = error.data?.message || '兑换失败，请稍后再试';
            
            setRedeemResult({
                success: false,
                message: errorMessage
            });
            
            toast({
                title: "兑换失败",
                description: errorMessage,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    // 获取积分类型显示名称
    const getCreditTypeName = (type) => {
        switch(type) {
            case 'speaking':
                return '口语';
            case 'writing':
                return '写作';
            case 'mock_test':
                return '模考';
            default:
                return type;
        }
    };

    // 重置状态
    const handleClose = () => {
        setCode("");
        setRedeemResult(null);
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>积分兑换</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing={4}>
                        <FormControl>
                            <FormLabel>请输入兑换码</FormLabel>
                            <Input 
                                placeholder="输入兑换码" 
                                value={code} 
                                onChange={(e) => setCode(e.target.value)}
                                isDisabled={loading || (redeemResult && redeemResult.success)}
                            />
                        </FormControl>

                        {redeemResult && (
                            <VStack align="start" w="100%" p={3} bg={redeemResult.success ? "green.50" : "red.50"} borderRadius="md">
                                <Text fontWeight="bold" color={redeemResult.success ? "green.500" : "red.500"}>
                                    {redeemResult.message}
                                </Text>
                                {/*{redeemResult.success && (*/}
                                {/*    <HStack>*/}
                                {/*        <Text>获得：</Text>*/}
                                {/*        <Badge colorScheme="green">*/}
                                {/*            {getCreditTypeName(redeemResult.credit_type)}积分 {redeemResult.credit_amount}点*/}
                                {/*        </Badge>*/}
                                {/*    </HStack>*/}
                                {/*)}*/}
                            </VStack>
                        )}
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <Button 
                        colorScheme="blue" 
                        mr={3} 
                        onClick={handleRedeem} 
                        isLoading={loading}
                        isDisabled={redeemResult && redeemResult.success}
                    >
                        兑换
                    </Button>
                    <Button variant="ghost" onClick={handleClose}>关闭</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}