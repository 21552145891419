import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Flex,
    Input,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useToast
} from "@chakra-ui/react";
import CardHeader from "./Card/CardHeader";
import CardBody from "./Card/CardBody";
import Card from "./Card/Card";
import authHeader from "../services/auth-header";
import axios from "axios";
import {useTranslation} from "react-i18next";

function DenialRow(props) {
    const toast = useToast()
    const [input, setInput] = useState("");

    return (
        <Flex>
            <Input placeholder='Denial reason' backgroundColor="white" flex="1" maxWidth="250px"
                   value={input}
                   onChange={(e) => {
                       setInput(e.target.value)
                   }}
            />
            <Box m={"5px"}></Box>
            <Button bg={'blue.400'} color={'white'}
                    onClick={() => {
                        let headers = authHeader();
                        axios.post(props.url, {
                                user_id: props.userId,
                                id: props.pendingId,
                                reason: input
                            }, {headers: headers}
                        ).then(function (response) {
                            toast({
                                title: "Successfully denied pending credits",
                                status: "success",
                                duration: 2000,
                                isClosable: true,
                            });
                        }).catch(function (error) {
                            toast({
                                title: "Failed to deny pending credits",
                                status: "error",
                                duration: 2000,
                                isClosable: true,
                            });
                        });
                    }}>
                Deny</Button>
        </Flex>

    );
}

export function AdminPendingCreditsPanel(props) {
    const textColor = useColorModeValue("gray.700", "white");

    const [pendingCreditEvents, setPendingCreditEvents] = useState([]);
    const toast = useToast()
    const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;
    const {t, i18n} = useTranslation();

    const fetchPendingCreditEvents = async () => {
        try {
            let headers = authHeader();
            const response = await axios.get(API_URL + `/api/get_pending_credit_events`, {headers: headers});
            setPendingCreditEvents(response.data);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchPendingCreditEvents();
    }, []);  // Only run this effect on mount

    const getPendingCreditEventsTable = () => {
        return (<Box overflowX="auto" width="100%">
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th verticalAlign="middle">{t('timestamp')}</Th>
                            <Th verticalAlign="middle">{t('type')}</Th>
                            <Th verticalAlign="middle">{t('referer')}</Th>
                            <Th verticalAlign="middle">{t('referee')}</Th>
                            <Th verticalAlign="middle">actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {Object.entries(pendingCreditEvents).map(([key, value]) => (
                            <Tr key={key}>
                                <Td verticalAlign="middle">
                                    {(new Date(value.created_at * 1000)).toISOString()}
                                </Td>
                                <Td verticalAlign="middle">

                                    <Flex flexDirection="column">
                                        <Text>{t(value.type)}</Text>
                                        <Text>amount: {value.credits}</Text>
                                    </Flex>
                                </Td>
                                <Td verticalAlign="middle">
                                    <Flex flexDirection="column">
                                        <Text>id: {value.user_id}</Text>
                                        {value.user_email && <Text>{value.user_email}</Text>}
                                        {value.user_phone && <Text>{value.user_phone}</Text>}
                                        <Text>ip: {value.user_ip}</Text>
                                    </Flex>
                                </Td>
                                <Td verticalAlign="middle">
                                    <Flex flexDirection="column">
                                        <Text>id: {value.referee_id || value.gid_referee_id}</Text>
                                        {(value.referee_email || value.gid_referee_email) &&
                                            <Text>{value.referee_email || value.gid_referee_email}</Text>}
                                        {(value.referee_phone || value.gid_referee_phone) &&
                                            <Text>{value.referee_phone || value.gid_referee_phone}</Text>}
                                        <Text>ip: {value.referee_ip || value.gid_referee_ip}</Text>
                                    </Flex>

                                </Td>
                                <Td verticalAlign="middle">
                                    <Flex flexDirection="column">
                                        <Flex>
                                            <Button bg={'green.400'} color={'white'}
                                                    onClick={() => {
                                                        let headers = authHeader();
                                                        axios.post(API_URL + '/api/approve_pending_credits', {
                                                                user_id: value.user_id,
                                                                id: value.id
                                                            }, {headers: headers}
                                                        ).then(function (response) {
                                                            toast({
                                                                title: "Successfully approved pending credits",
                                                                status: "success",
                                                                duration: 2000,
                                                                isClosable: true,
                                                            });
                                                        }).catch(function (error) {
                                                            toast({
                                                                title: "Failed to approve pending credits",
                                                                status: "error",
                                                                duration: 2000,
                                                                isClosable: true,
                                                            });
                                                        });
                                                    }}>
                                                Approve</Button>
                                        </Flex>
                                        <Box m={"5px"}></Box>
                                        <DenialRow url={API_URL + '/api/deny_pending_credits'} userId={value.user_id}
                                                   pendingId={value.id}></DenialRow>
                                        <Box m={"5px"}></Box>
                                        <Flex>
                                            <Button bg={'red.400'} color={'white'}
                                                    onClick={() => {
                                                        let headers = authHeader();
                                                        axios.post(API_URL + '/api/disable_users', {
                                                                user_ids: [value.user_id]
                                                            }, {headers: headers}
                                                        ).then(function (response) {
                                                            toast({
                                                                title: "Successfully disabled both users",
                                                                status: "success",
                                                                duration: 2000,
                                                                isClosable: true,
                                                            });
                                                        }).catch(function (error) {
                                                            toast({
                                                                title: "Failed to disable both users",
                                                                status: "error",
                                                                duration: 2000,
                                                                isClosable: true,
                                                            });
                                                        });
                                                    }}>
                                                Disable referer</Button>
                                            <Box m={"5px"}></Box>
                                            <Button bg={'red.400'} color={'white'}
                                                    onClick={() => {
                                                        let headers = authHeader();
                                                        axios.post(API_URL + '/api/disable_users', {
                                                                user_ids: [value.referee_id || value.gid_referee_id]
                                                            }, {headers: headers}
                                                        ).then(function (response) {
                                                            toast({
                                                                title: "Successfully disabled both users",
                                                                status: "success",
                                                                duration: 2000,
                                                                isClosable: true,
                                                            });
                                                        }).catch(function (error) {
                                                            toast({
                                                                title: "Failed to disable both users",
                                                                status: "error",
                                                                duration: 2000,
                                                                isClosable: true,
                                                            });
                                                        });
                                                    }}>
                                                Disable referee</Button>
                                        </Flex>
                                    </Flex>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
        );
    }

    return (
        <Card
            overflowX={{sm: "scroll", xl: "hidden"}}
        >
            <CardHeader p="6px 0px 22px 0px">
                <Flex direction="column">
                    <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
                        Pending Credits
                    </Text>
                </Flex>
            </CardHeader>
            <CardBody>
                {getPendingCreditEventsTable()}
            </CardBody>
        </Card>
    );
}