import React, { useState, useEffect } from 'react';
import axios from 'axios';
import authHeader from "../services/auth-header";
import { Table, Thead, Tbody, Tr, Th, Td, Button, Fade, Center, HStack, Text, Select, Textarea } from "@chakra-ui/react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Box,
    VStack,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody
} from '@chakra-ui/react'
import FeedBackImage from './FeedBackImage';
import { ViewResultPanel } from './ViewResultPanel';

export function ViewFeedBackPanel() {
    const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;
    const [feedBacks, setFeedBacks] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isImageOpen, onOpen: onImageOpen, onClose: onImageClose } = useDisclosure();
    const { isOpen: isDetailOpen, onOpen: onDetailOpen, onClose: onDetailClose } = useDisclosure();
    const [imgName, setImgName] = useState(null);
    const [detail, setDetail] = useState(null);
    const [showTable, setShowTable] = useState(false);
    const [gid, setGid] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // You can adjust this number
    const [noteText, setNoteText] = useState('');
    const { isOpen: isNotesOpen, onOpen: onNotesOpen, onClose: onNotesClose } = useDisclosure();
    const [selectedFeedbackId, setSelectedFeedbackId] = useState(null);

    // Calculate pagination values
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentFeedbacks = feedBacks.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(feedBacks.length / itemsPerPage);

    const fetchFeedbacks = async () => {
        try {
            let headers = authHeader();
            const response = await axios.get(API_URL + `/api/get_feedbacks`, { headers: headers });
            const sortedFeedbacks = response.data.sort((a, b) => {
                const dateA = new Date(a.create_at);
                const dateB = new Date(b.create_at);
                return dateB - dateA;
            });
            setFeedBacks(sortedFeedbacks);
        } catch (err) {
            console.error(err);
        }
    }
    
    useEffect(() => {
        fetchFeedbacks();
    }, []);

    const imageModal = () => {
        return (<Modal isOpen={isImageOpen} onClose={onImageClose} size={'auto'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Image</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FeedBackImage imgName={imgName} />
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" onClick={onImageClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>)
    }
    const showFeedBacks = () => {
        return (
            <Box m={10}>
                <Table size={'md'} variant='striped' colorScheme='teal'>
                    <Thead>
                        <Tr>
                            <Th>User ID</Th>
                            <Th>User Email</Th>
                            <Th>Gpt response</Th>
                            <Th>Details</Th>
                            <Th>Rate</Th>
                            <Th>Photo</Th>
                            <Th>Contact Info</Th>
                            <Th>Status</Th>
                            <Th>Notes</Th>
                            <Th>Created At</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {currentFeedbacks.map(feedback => (
                            <Tr key={feedback.id}>
                                <Td>{feedback.user_id}</Td>
                                <Td>{feedback.email}</Td>
                                <Td>
                                    {feedback.gid ?
                                        <Button onClick={() => { setGid(feedback.gid); onOpen(); }}>View Gpt response</Button> : <>No details</>}
                                </Td>
                                <Td>
                                    {feedback.details['content'] ?
                                        <Button onClick={() => showDetail(feedback.details['content'])}>View Details</Button> : <>No details</>}
                                </Td>
                                <Td>{feedback.rate}</Td>
                                <Td>
                                    {feedback.photo_file_name ? 
                                        <Button onClick={() => { setImgName(feedback.photo_file_name); onImageOpen(); }}>View Photo</Button> :
                                        <>No photo</>}
                                </Td>
                                <Td>{feedback.phone_number || feedback.contact_info}</Td>
                                <Td>
                                    <Select
                                        value={feedback.details.status || 'Open'}
                                        onChange={(e) => updateFeedbackStatus(feedback.id, e.target.value)}
                                        bg={
                                            feedback.details.status === 'Closed' ? 'gray.300' :
                                            feedback.details.status === 'In-Progress' ? 'blue.300' :
                                            'green.300'
                                        }
                                    >
                                        <option value="Open">Open</option>
                                        <option value="In-Progress">In-Progress</option>
                                        <option value="Closed">Closed</option>
                                    </Select>
                                </Td>
                                <Td>
                                    <Button
                                        onClick={() => {
                                            setSelectedFeedbackId(feedback.id);
                                            onNotesOpen();
                                        }}
                                    >
                                        {feedback.details.notes?.length || 0} Notes
                                    </Button>
                                </Td>
                                <Td>{feedback.create_at}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                
                <Center mt={4}>
                    <HStack spacing={4}>
                        <Button 
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                            isDisabled={currentPage === 1}
                        >
                            Previous
                        </Button>
                        <Text>
                            Page {currentPage} of {totalPages}
                        </Text>
                        <Button 
                            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                            isDisabled={currentPage === totalPages}
                        >
                            Next
                        </Button>
                    </HStack>
                </Center>
            </Box>
        );
    }
    const detailModal = () => {
        return (<Modal isOpen={isDetailOpen} onClose={onDetailClose} size={'md'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Detail</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {detail}
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" onClick={onDetailClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>)
    }
    const showDetail = (content) => {
        setDetail(content);
        onDetailOpen();
    }

    const updateFeedbackStatus = async (feedbackId, newStatus) => {
        try {
            let headers = authHeader();
            // Find the current feedback to get its existing details
            const currentFeedback = feedBacks.find(f => f.id === feedbackId);
            const updatedDetails = {
                ...currentFeedback.details,  // Spread existing details
                status: newStatus  // Add/update status
            };
            
            console.log("update_feedback", {
                id: feedbackId,
                details: updatedDetails
            })

            await axios.put(API_URL + `/api/update_feedback`, {
                id: feedbackId,
                details: updatedDetails
            }, { headers: headers });
            fetchFeedbacks(); // Refresh the table
        } catch (err) {
            console.error(err);
        }
    };

    const addNote = async (feedbackId) => {
        try {
            let headers = authHeader();
            const currentFeedback = feedBacks.find(f => f.id === feedbackId);
            const currentUser = JSON.parse(localStorage.getItem("user"));
            console.log("currentUser", currentUser)
            const newNote = {
                text: noteText,
                timestamp: new Date().toISOString(),
                author: {
                    id: currentUser.user_id,
                    email: currentUser.email,
                    name: currentUser.user_name
                }
            };

            const updatedDetails = {
                ...currentFeedback.details,
                notes: currentFeedback.details.notes ? [...currentFeedback.details.notes, newNote] : [newNote]
            };

            await axios.put(API_URL + `/api/update_feedback`, {
                id: feedbackId,
                details: updatedDetails
            }, { headers: headers });
            
            setNoteText('');
            onNotesClose();
            fetchFeedbacks();
        } catch (err) {
            console.error(err);
        }
    };

    const notesModal = () => {
        return (
            <Modal isOpen={isNotesOpen} onClose={onNotesClose} size={'xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Notes</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4} align="stretch">
                            {selectedFeedbackId && feedBacks.find(f => f.id === selectedFeedbackId)?.details.notes?.map((note, index) => (
                                <Box key={index} p={3} borderWidth={1} borderRadius="md">
                                    <Text fontSize="sm" color="gray.500">
                                        {new Date(note.timestamp).toLocaleString()} - {note.author.name} ({note.author.id})
                                    </Text>
                                    <Text mt={1}>{note.text}</Text>
                                </Box>
                            ))}
                            <Textarea
                                value={noteText}
                                onChange={(e) => setNoteText(e.target.value)}
                                placeholder="Add a new note..."
                            />
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={() => addNote(selectedFeedbackId)}>
                            Add Note
                        </Button>
                        <Button onClick={onNotesClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );
    };

    console.log(imgName)

    return (
        <>
            <VStack spacing='20px'>
                <Button onClick={() => setShowTable(!showTable)}>Show Feedbacks</Button>
                <Box display={showTable ? "block" : "none"}>
                    {showFeedBacks()}
                </Box>
                <Drawer onClose={onClose} isOpen={isOpen} size="full" placement="bottom">
                    <DrawerOverlay />
                    <DrawerContent bgColor="gray.100">
                        <DrawerCloseButton m={'50px'} color="black" />
                        <DrawerHeader bg="blue.800">
                        </DrawerHeader>
                        <DrawerBody bg="white">
                            <ViewResultPanel gid={gid}></ViewResultPanel>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
                {imageModal()}
                {detailModal()}
                {notesModal()}
            </VStack>

        </>
    )
}