import React, {useEffect, useRef} from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import {Box, Button, Center, chakra, Link, Spinner, Text, VStack} from "@chakra-ui/react";
import {useSelector} from "react-redux";
import {selectMarkdownResponse, selectToeflData} from "../redux/toeflSlice";
import rehypeRaw from "rehype-raw";
import ReactAudioPlayer from "react-audio-player";
import {useGetExampleAudioQuery} from "../services/cmApi";
import ResponseFeedBack from "./ResponseFeedBack";
import {useTranslation} from 'react-i18next';
import {ShareResultButton} from "./ShareResultButton";
import ChakraMarkdownComponents from "./ChakraMarkdownComponents";
import EssayGeneration from "./essayGeneration";
import EssayScore from "./essayScore";
import html2pdf from 'html2pdf.js';
import logoPng from "../assets/logo_long2.png";
import {DownloadIcon} from "@chakra-ui/icons";
import printComponent from "./utils/printComponent";

export function ResponsePanel(props) {
    const {t, i18n} = useTranslation();
    const toeflData = useSelector(selectToeflData);
    const markdownResponse = useSelector(selectMarkdownResponse);
    const responseRef = useRef(null);

    const showMarkDown = toeflData.shouldShowMarkdown || toeflData.isStreaming;
    const isStreaming = toeflData.isStreaming;
    const getResponse = () => {
        if (markdownResponse.result) {
            return markdownResponse.result;
        } else {
            return "";
        }
    };

    let exampleAudio = useGetExampleAudioQuery(
        {gid: toeflData.gid}, {skip: !showMarkDown || isStreaming});

    const getAudioSrc = () => {
        if (exampleAudio.status === 'fulfilled' && exampleAudio.data.status === "SUCCESS") {
            if ('audio_url' in exampleAudio.data) {
                return exampleAudio.data['audio_url'];
            } else {
                return exampleAudio.data['audio'];
            }
        } else {
            return "";
        }
    };

    useEffect(() => {
        if (responseRef.current) {
            responseRef.current.scrollIntoView({behavior: "smooth", block: "end"});
        }
    }, [toeflData.isStreaming]);

    const query = new URLSearchParams(window.location.search);
    const structured = query.get("structured")
    const showExampleAudioPlayer = showMarkDown && !isStreaming && toeflData.section === "speaking" && exampleAudio.status === 'fulfilled';

    const TOEFLScoringRubricEn = "https://lingoleap.ai/read/how-is-the-toefl-score-calculated/"
    const TOEFLScoringRubricCn = "https://lingoleap.ai/zh/read/how-is-the-toefl-score-calculated-2/"

    let showRubric = false;
    let rubricLink = "";
    if (toeflData.taskName && toeflData.taskName.startsWith("task")) {
        showRubric = true;
        if (i18n.language == "cn") {
            rubricLink = TOEFLScoringRubricCn;
        } else {
            rubricLink = TOEFLScoringRubricEn;
        }
    }

    return <>
        <Box m={5} ref={responseRef} p={5} borderRadius={20}
             display={showMarkDown ? "block" : "none"}

        >   <Box m="20px" overflowX="auto" className="print-container">
                {toeflData.content.message1 && <EssayScore/>}
                {structured ? (
                    <EssayGeneration/>
                ) : (
                    <ReactMarkdown
                        children={getResponse()}
                        remarkPlugins={[gfm]}
                        rehypePlugins={[rehypeRaw]}
                        className="ll-markdown"
                        components={ChakraMarkdownComponents}
                    />
                )}
            </Box>
            <Center>
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='lg'
                    mt='20px'
                    display={isStreaming ? "block" : "none"}
                />
            </Center>
            <Box mt={4} display={(showRubric && !isStreaming) ? "flex" : "none"} justifyContent="space-between"
                 alignItems="center">
                <Link color="blue.500" fontWeight={"bold"} fontSize={"sm"} href={rubricLink} isExternal>
                    ⓘ {t("response_scoring_rubrics")}
                </Link>
                <Button
                    variant="ghost"
                    size="sm"
                    leftIcon={<DownloadIcon/>}
                    onClick={printComponent}
                >
                    {t("download_pdf")}
                </Button>
            </Box>

            <Center display={showExampleAudioPlayer ? "block" : "none"} className="pdf-exclude">
                <VStack>
                    <chakra.p ml={3} mt={3} fontWeight={'bold'}>
                        Example Audio
                    </chakra.p>
                    <ReactAudioPlayer
                        src={getAudioSrc()}
                        controls={true}
                    />
                </VStack>
            </Center>
            <Center mt="20px" className="pdf-exclude">
                <ShareResultButton visible={markdownResponse.result && !toeflData.isStreaming}></ShareResultButton>
            </Center>
            <Center>
                <Box mt={10} p={5} bg='gray.100' borderRadius={20} width={'full'}
                     display={!isStreaming ? "block" : "none"}>
                    <VStack>
                        <Text as={'h3'} fontSize={'md'}>
                            {t("your_santisfication_rate")}
                        </Text>

                        <ResponseFeedBack gid={toeflData.gid}/>
                    </VStack>
                </Box>
            </Center>
        </Box>
    </>;
}