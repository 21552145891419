import {
    Box,
    Button,
    Divider,
    Flex,
    HStack,
    Icon,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack
} from '@chakra-ui/react';
import React, {useEffect, useRef, useState} from 'react';
import {FaCheckCircle, FaMicrophone} from 'react-icons/fa';
import {useHistory} from "react-router-dom";
import {IoWarning} from "react-icons/io5";

function ToeflAdjustingTheMicrophoneRecord() {
    const [isRecording, setIsRecording] = useState(false);
    const [volume, setVolume] = useState(0);
    const [countdown, setCountdown] = useState(10); // 倒计时
    const mediaRecorderRef = useRef(null);
    const volumeValues = useRef([]); // 存储音量值
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [isFailureModalOpen, setIsFailureModalOpen] = useState(false);
    const [isDisplayContinue, setIsDisplayContinue] = useState(false);
    let history = useHistory();

    useEffect(() => {
        let animationFrameId;
        if (isRecording) {
            const timer = setInterval(() => {
                setCountdown((prev) => {
                    if (prev <= 1) {
                        clearInterval(timer);
                        setIsRecording(false);
                    }
                    return prev - 1;
                });
            }, 1000);
            const stream = mediaRecorderRef.current.stream;
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const analyser = audioContext.createAnalyser();
            analyser.fftSize = 2048;
            const bufferLength = analyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);
            const source = audioContext.createMediaStreamSource(stream);
            source.connect(analyser);
            const updateVolume = () => {
                analyser.getByteTimeDomainData(dataArray);
                let validValues = []
                for (let i = 0; i < bufferLength; i++) {
                    const volume_value = Math.abs(dataArray[i] - 128);
                    if (volume_value > 0) {
                        validValues.push(volume_value);
                    }
                }
                const sum = validValues.reduce((acc, value) => acc + value, 0);
                const average = (sum / validValues.length) * 2;
                const scaledVolume = Math.min(Math.floor((average / 128) * 18), 18); // 映射到 0-18
                setVolume(scaledVolume);
                if (scaledVolume > 0) {
                    volumeValues.current.push(scaledVolume); // 记录音量数据
                }
                console.log('isRecording:', isRecording)
                if (isRecording) {
                    animationFrameId = requestAnimationFrame(updateVolume);
                }
            };


            updateVolume();
            return () => {
                cancelAnimationFrame(animationFrameId);
                clearInterval(timer);
                stopRecording();
            };
        } else if (mediaRecorderRef.current) {

        }
    }, [isRecording]);

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({audio: true});
            mediaRecorderRef.current = new MediaRecorder(stream);
            setCountdown(10);
            setIsRecording(true);
            volumeValues.current = []; // 重置音量数组
        } catch (error) {
            console.error('Microphone access denied:', error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop()); // 停止音频流
        }
        console.log('validVolumes', volumeValues.current)
        const validVolumes = volumeValues.current.filter((v) => v > 0);
        const average = validVolumes.length
            ? (validVolumes.reduce((sum, v) => sum + v, 0) / validVolumes.length).toFixed(2)
            : 0;
        console.log('average', volumeValues.current)
        setVolume(average);
        if (average >= 2) {
            setIsSuccessModalOpen(true);
            console.log('Average volume is GOOD:', average);
        } else {
            setIsFailureModalOpen(true)
            console.log('Average volume is NOT GOOD:', average);
        }
    };

    const handleRecordClick = () => {
        if (!isRecording) {
            startRecording();
        }
    };

    const ShownIndicatorPart = ({value, color, range}) => (
        <Flex justify="space-between" mb={2}>
            {[...Array(range)].map((_, i) => (
                <Box
                    key={i}
                    w={4}
                    h={10}
                    bg={i < value ? color : 'white'}
                    mx={2}
                    borderRadius="2px"
                    border={'1px'}
                    borderColor={'gray.500'}
                />
            ))}
        </Flex>
    );

    const ShownIndicator = ({value, color}) => (
        <Flex>
            <Flex direction={'column'} alignItems="center" mt={4}>
                <ShownIndicatorPart value={value} color={color} range={2}></ShownIndicatorPart>
                <Text ml={-2} fontSize={'sm'}>Too Quiet</Text>
            </Flex>
            <Divider
                orientation="vertical"
                borderColor="gray.500"
                borderStyle="dashed"
                borderWidth={1}
                height="75px"

            />
            <Flex direction={'column'} alignItems="center" mt={4}>
                <ShownIndicatorPart value={value - 2} color={color} range={8}></ShownIndicatorPart>
                <Text fontSize={'sm'}>Good</Text>
            </Flex>
            <Divider
                orientation="vertical"
                borderColor="gray.500"
                borderStyle="dashed"
                borderWidth={1}
                height="75px"
            />
            <Flex direction={'column'} alignItems="center" mt={4}>
                <ShownIndicatorPart value={value - 10} color={color} range={5}></ShownIndicatorPart>
                <Text fontSize={'sm'}>Too Loud</Text>
            </Flex>
        </Flex>
    );


    const handleClickContinue = () => {
        const newUrl = location.pathname.replace('adjusting_the_microphone_record', 'directions')
        history.replace(newUrl);
    };


    const SuccessModal = ({isOpen, onClose}) => {
        return (
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent px={16} py={10}>
                    <ModalHeader>
                        <Flex direction={"row"} alignItems={'center'} px={3}>
                            <FaCheckCircle color="green" fontSize={30}/>
                            <Box width={3}></Box>
                            Success
                        </Flex>
                        <Divider borderWidth={1} borderColor={'gray.300'} mt={2}/>
                    </ModalHeader>
                    <ModalBody>
                        <Text px={3}>Your microphone volume has been successfully adjusted.</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Flex justifyContent="flex-start" w="full">
                            <Button colorScheme='teal' onClick={handleClickContinue} ml={3}>
                                Continue
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );
    };
    const FailureModal = ({isOpen, onClose}) => {
        return (
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent px={16} py={10}>
                    <ModalHeader>
                        <Flex direction={"row"} alignItems={'center'} px={3}>
                            <IoWarning color="red" fontSize={30}/>
                            <Box width={3}></Box>
                            Failure
                        </Flex>
                        <Divider borderWidth={1} borderColor={'gray.300'} mt={2}/>
                    </ModalHeader>
                    <ModalBody>
                        <Text px={3}>Your microphone volume has failed to be adjusted. Please try again.</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Flex justifyContent="flex-start" w="full">
                            <Button colorScheme='teal' onClick={onClose} ml={3}>
                                Return
                            </Button>
                            {isDisplayContinue && <Button colorScheme='teal' onClick={handleClickContinue} ml={3}>
                                Continue
                            </Button>}
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );
    }


    return (
        <Box maxWidth="860px" margin="auto" padding="4">
            <HStack spacing={8} align="center">
                <Button
                    variant="solid"
                    colorScheme="teal"
                    size="lg"
                    borderRadius="full"
                    boxShadow="md"
                    padding="6"
                    width="80"
                    height="40"
                    border="2px solid teal"
                    m={4}
                    onClick={handleRecordClick}
                    disabled={isRecording}
                >
                    <VStack spacing="1">
                        <Icon as={FaMicrophone} boxSize="10" mb={2}/>
                        <Text>{isRecording ? 'Recording...' : 'Record'}</Text>
                    </VStack>
                </Button>
                <VStack spacing={8} align="stretch">
                    <Text>Select the 'Record' button. A timer will count down until the system is ready to
                        record.</Text>
                    <Text>To check your microphone level, you will record the following paragraph using your normal tone
                        and volume.</Text>
                    <Text>There are several reasons why I would prefer to live in a large city. Some of the greatest
                        advantages would include the number of job opportunities and career options, public
                        transportation, greater diversity, and a wealth of entertainment. Also, large cities typically
                        have a great deal to offer in terms of history, art and culture.</Text>
                    <ShownIndicator value={volume} color={"teal.500"}></ShownIndicator>
                </VStack>
                <SuccessModal isOpen={isSuccessModalOpen} onClose={() => {
                    setIsSuccessModalOpen(false);
                }}/>
                <FailureModal isOpen={isFailureModalOpen} onClose={() => {
                    setIsFailureModalOpen(false);
                    handleRecordClick();
                    setIsDisplayContinue(true)
                }}/>
            </HStack>
        </Box>
    );
}

export default ToeflAdjustingTheMicrophoneRecord;
