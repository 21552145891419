import React, {useEffect, useState} from 'react';
import {Box, Tab, TabList, Tabs} from '@chakra-ui/react';
import {ViewResultPanel} from "../ViewResultPanel";
import {ToeflIbtContext} from "./ToeflIbtContext";
import ToeflWaitingPage from "./ToeflWaitingPage";

function ToeflWritingAnswer() {
    const {questionContent, answerContent} = React.useContext(ToeflIbtContext);
    const [tabIndex, setTabIndex] = useState(0);
    const [gid, setGid] = useState(null);
    const [isAnswered, setIsAnswered] = useState(false);


    useEffect(() => {
        const gid = answerContent?.['writing']?.[tabIndex + 1]?.['gid'];
        const answer = answerContent?.['writing']?.[tabIndex + 1]?.['answer'];
        const status = answerContent?.['writing']?.[tabIndex + 1]?.['status'];
        setIsAnswered(!!answer);
        if (gid && gid.startsWith('G_') && (status === "finished")) {
            setGid(gid);
        } else {
            setGid(null)
        }
    }, [answerContent, tabIndex]);

    return (
        <Box margin="auto" padding="1" bg={'gray.100'} height={"92vh"} width={'100%'}>
            <Tabs variant='enclosed' bg={'white'} borderRadius={'md'}>
                <TabList>
                    {questionContent?.['writing']?.tasks.map((task, index) => (
                        index <= 1 && (
                            <Tab key={index} onClick={() => {
                                setTabIndex(index)
                            }}>
                                Task {index + 1}
                            </Tab>
                        )
                    ))}
                </TabList>
            </Tabs>
            {(gid) ? <ViewResultPanel gid={gid}></ViewResultPanel>
                : <ToeflWaitingPage isAnswered={isAnswered}/>}
        </Box>
    );
}

export default ToeflWritingAnswer;
