import '../../../fonts.css';
import React, {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

import lingoLeapSVG from '../../../assets/login1.2_img/LingoLeap.svg'
import logoSVG from '../../../assets/login1.2_img/Logo.svg'
import avatarContainer from '../../../assets/login1.2_img/Avatas_Contianer.png'
import anderson from '../../../assets/login1.2_img/person_1.png'
import sarah from '../../../assets/login1.2_img/person_2.png'
import david from '../../../assets/login1.2_img/person_3.png'
import {PhoneRegistry} from "../PhoeRegistry/phoneRegistry";
import {EmailRegistry} from "../EmailRegistery/emailRegistry";
import {VerifyAccount} from "../VerifyAccount/verifyAccount";
import {ResetPassword} from "../ResetPassword/resetPassword";
import {SetNewPassword} from "../ResetPassword/setNewPassword";
import {TermOfUse} from "../TermOfUse/termOfUse";
import {Helmet} from 'react-helmet';
import {
    Avatar,
    Box,
    ChakraProvider,
    Divider,
    extendTheme,
    Flex,
    Grid,
    GridItem,
    Heading,
    HStack,
    Image,
    Link,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useMediaQuery,
    VStack,
} from '@chakra-ui/react'
import { handleRedirectParam } from './Redirect';

// Define the custom theme
const theme = extendTheme({
    fonts: {
        heading: 'THICCCBOI, sans-serif',
        body: 'THICCCBOI, sans-serif',
    },
});

export const Login_1_2 = () => {
    // Add this line near the top of the component
    const {t, i18n} = useTranslation();
    const location = useLocation();

    // State declarations
    const [tabActiveIndex, setTabActiveIndex] = useState(0);
    const [loginOrVerify, setLoginOrVerify] = useState("login");
    const [isLogin, setIsLogin] = useState(location.pathname === '/login');
    const [phone, setPhone] = useState('');
    const [invitationCode, setInvitationCode] = useState('');
    const [email, setEmail] = useState(''); // Add this line
    const [password, setPassword] = useState('');
    const [resetFrom, setResetFrom] = useState('phone');
    const [hoveringLoginHere, setHoveringLoginHere] = useState(false);
    const [hoveringCreateAccount, setHoveringCreateAccount] = useState(false);
    const [isResetPasswordFlow, setIsResetPasswordFlow] = useState(false);
    const [resetToken, setResetToken] = useState('');
    const [authToken, setAuthToken] = useState('');
    const [currentTestimonial, setCurrentTestimonial] = useState(0);

    const [isLargerThan1100] = useMediaQuery('(min-width: 1100px)')
    const [isLargerThan400] = useMediaQuery('(min-width: 400px)')

    const testimonials = [
        {name: t('testimonials.anderson.name'), image: anderson, text: t('testimonials.anderson.text')},
        {name: t('testimonials.sarah.name'), image: sarah, text: t('testimonials.sarah.text')},
        {name: t('testimonials.david.name'), image: david, text: t('testimonials.david.text')},
    ];

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const paramValue = query.get('r');
        
        // Handle invitation code
        if (paramValue) {
            setInvitationCode(paramValue);
        }

        // Handle redirect parameter
        handleRedirectParam(location);
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
        }, 6000);

        return () => clearInterval(timer);
    }, [testimonials.length]);

    useEffect(() => {
        setIsLogin(location.pathname === '/login');
    }, [location.pathname]);

    const handlePrev = () => {
        setCurrentTestimonial((prev) => (prev - 1 + testimonials.length) % testimonials.length);
    };

    const handleNext = () => {
        setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    };

    const toggleLoginRegister = () => {
        const newIsLogin = !isLogin;
        setIsLogin(newIsLogin);
        const newPath = newIsLogin ? '/login' : '/register';
        window.history.pushState(null, '', newPath);
    };

    // Callback function
    const registeryCallback = (login) => {
        if (login.type === "resetPass") {
            setLoginOrVerify("resetPass");
            setResetFrom(login.from);
            setIsResetPasswordFlow(true);
        } else if (login.type === 'login') {
            setLoginOrVerify('login');
            setIsResetPasswordFlow(false);
        } else if (login.type === "setNewPassword") {
            // Handle the transition to the SetNewPassword component
            setLoginOrVerify("setNewPassword");
            setResetToken(login.resetToken);
            setAuthToken(login.authToken);
        } else if (login.result === "ok") {
            setPassword(login.password);
            setEmail(login.email); // Update this line
            setPhone(login.phone);
            setInvitationCode(login.invitationCode);
            setLoginOrVerify("verify");
            setIsResetPasswordFlow(login.isResetPassword);
            if (login.isResetPassword) {
                setResetFrom(login.resetFrom);
            }
            console.log("login.result is ok", login.result)
        }
    };

    // Custom styles and SVG components
    const CheckmarkSVG = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                d="M7.15541 18.3385C6.82899 18.3401 6.51503 18.1868 6.28067 17.9114L0.37744 10.9677C0.259778 10.8284 0.165894 10.6624 0.101148 10.4792C0.0364024 10.296 0.00206288 10.0991 9.0224e-05 9.89977C-0.00389373 9.49724 0.124201 9.10928 0.356196 8.82123C0.588191 8.53318 0.905082 8.36864 1.23716 8.36381C1.56923 8.35898 1.88929 8.51426 2.12691 8.79547L7.1604 14.7137L17.8722 2.10322C18.1102 1.822 18.4305 1.66688 18.7628 1.672C19.0951 1.67711 19.4122 1.84203 19.6442 2.13048C19.8762 2.41894 20.0041 2.80729 19.9999 3.2101C19.9957 3.61292 19.8596 3.99721 19.6217 4.27843L8.03014 17.9114C7.79578 18.1868 7.48182 18.3401 7.15541 18.3385Z"
                fill="#545454"/>
        </svg>
    );

    const PrevButton = () => (
        <Box as="button" onClick={handlePrev} p={0} bg="transparent" border="none" cursor="pointer"
             _focus={{outline: 'none'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path
                    d="M6.04198 8.50544L11.6829 2.98571C12.0859 2.59077 12.1079 1.92765 11.7309 1.50443C11.3549 1.0812 10.7199 1.0592 10.3179 1.45414L4.61699 7.03359C4.219 7.42329 4 7.94498 4 8.50544C4 9.0659 4.219 9.5876 4.61599 9.9773L10.3169 15.5567C10.5099 15.7453 10.7549 15.8385 10.9999 15.8385C11.2679 15.8385 11.5339 15.7275 11.7299 15.5065C12.1069 15.0832 12.0849 14.4212 11.6819 14.0252L6.04198 8.50544Z"
                    fill="#1F2A37"/>
            </svg>
        </Box>
    );

    const NextButton = () => (
        <Box as="button" onClick={handleNext} p={0} bg="transparent" border="none" cursor="pointer"
             _focus={{outline: 'none'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path
                    d="M9.95802 8.50237L4.31709 14.0221C3.91409 14.417 3.89209 15.0802 4.26909 15.5034C4.64508 15.9266 5.28007 15.9486 5.68207 15.5537L11.383 9.97423C11.781 9.58453 12 9.06283 12 8.50237C12 7.94191 11.781 7.42021 11.384 7.03051L5.68307 1.45107C5.49007 1.2625 5.24507 1.16927 5.00008 1.16927C4.73208 1.16927 4.46608 1.28031 4.27009 1.50135C3.89309 1.92458 3.91509 2.58665 4.31809 2.98264L9.95802 8.50237Z"
                    fill="#1F2A37"/>
            </svg>
        </Box>
    );

    const PhoneIconSVG = () => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M17.5 1.5H7.5C6.395 1.5 5.5 2.395 5.5 3.5V21.5C5.5 22.605 6.395 23.5 7.5 23.5H17.5C18.605 23.5 19.5 22.605 19.5 21.5V3.5C19.5 2.395 18.605 1.5 17.5 1.5ZM16.833 18.5H8.167C7.798 18.5 7.5 18.202 7.5 17.833V5.167C7.5 4.798 7.798 4.5 8.167 4.5H16.834C17.202 4.5 17.5 4.798 17.5 5.167V17.834C17.5 18.202 17.202 18.5 16.833 18.5ZM10 20.5C9.72386 20.5 9.5 20.7239 9.5 21C9.5 21.2761 9.72386 21.5 10 21.5H15C15.2761 21.5 15.5 21.2761 15.5 21C15.5 20.7239 15.2761 20.5 15 20.5H10Z"
                  fill="currentColor"/>
        </svg>
    );

    const EmailIconSVG = () => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.125 5.25C4.88412 5.25 3.875 6.25912 3.875 7.5V16.5C3.875 17.7409 4.88412 18.75 6.125 18.75H18.875C20.1159 18.75 21.125 17.7409 21.125 16.5V7.5C21.125 6.25912 20.1159 5.25 18.875 5.25H6.125ZM6.6311 6.75H18.3696L13.7913 11.0991C13.0675 11.7872 11.9325 11.7872 11.2087 11.0991L6.6311 6.75ZM5.375 7.62671L10.1753 12.1868C10.827 12.8059 11.6638 13.1155 12.5 13.1155C13.3363 13.1155 14.1726 12.8055 14.8247 12.1868L19.625 7.62671V16.5C19.625 16.9136 19.2886 17.25 18.875 17.25H6.125C5.71137 17.25 5.375 16.9136 5.375 16.5V7.62671Z"
                fill="currentColor"/>
        </svg>
    );

    // Render functions
    const renderLeftPanel = () => (
        <GridItem colSpan={1} bg='white' px="80px" py="59px">
            <VStack align="flex-start" spacing={8} h="100%" justify="space-between">
                <Flex alignItems="center">
                    <Link href="https://lingoleap.ai" isExternal>
                        <Image src={logoSVG} h="32px"/>
                    </Link>
                    <Link href="https://lingoleap.ai" isExternal>
                        <Image src={lingoLeapSVG} h="24px" ml={2}/>
                    </Link>
                </Flex>

                <VStack align="flex-start" spacing={0} w="90%" flex="1" justify="center">
                    <VStack align="flex-start" spacing={6} w="100%">
                        <Heading as="h1" fontSize="3xl" fontWeight="bold" color="#232323">
                            {t('leftPanel.heading')}
                        </Heading>
                        {/*add subheading*/}
                        <Heading as="h2" fontSize="xl" color="#717171" fontWeight="medium">
                            {t('leftPanel.subheading')}
                        </Heading>

                        <Box position="relative" top="-8px">
                            <Image src={avatarContainer} w="150px" objectFit="cover"/>
                        </Box>

                        <VStack align="flex-start" spacing={4} w="100%" mt="-20px" pl={6}>
                            <HStack align="center" spacing={3}>
                                <Box minWidth="20px" display="flex" alignItems="center">
                                    <CheckmarkSVG/>
                                </Box>
                                <Text fontSize="lg" color="#545454" lineHeight="1.5" fontWeight="medium">
                                    {t('leftPanel.feature1')}
                                </Text>
                            </HStack>

                            <HStack align="center" spacing={3}>
                                <Box minWidth="20px" display="flex" alignItems="center">
                                    <CheckmarkSVG/>
                                </Box>
                                <Text fontSize="lg" color="#545454" lineHeight="1.5" fontWeight="medium">
                                    {t('leftPanel.feature2')}
                                </Text>
                            </HStack>

                            <HStack align="center" spacing={3}>
                                <Box minWidth="20px" display="flex" alignItems="center">
                                    <CheckmarkSVG/>
                                </Box>
                                <Text fontSize="lg" color="#545454" lineHeight="1.5" fontWeight="medium">
                                    {t('leftPanel.feature3')}
                                </Text>
                            </HStack>

                            <HStack align="center" spacing={3}>
                                <Box minWidth="20px" display="flex" alignItems="center">
                                    <CheckmarkSVG/>
                                </Box>
                                <Text fontSize="lg" color="#545454" lineHeight="1.5" fontWeight="medium">
                                    {t('leftPanel.feature4')}
                                </Text>
                            </HStack>
                        </VStack>
                    </VStack>

                    <VStack w="100%" mt="auto">
                        <Divider mt={10}/>
                        <HStack w="100%" justify="space-between" py={4}>
                            <PrevButton/>
                            <Text fontSize="md" color="#232323" textAlign="center" flex={1} px={8} fontWeight="medium">
                                "{testimonials[currentTestimonial].text}"
                            </Text>
                            <NextButton/>
                        </HStack>
                        <HStack>
                            <Avatar size="sm" name={testimonials[currentTestimonial].name}
                                    src={testimonials[currentTestimonial].image}/>
                            <Text color="#232323" fontSize="sm"
                                  fontWeight="medium">{testimonials[currentTestimonial].name}</Text>
                        </HStack>
                    </VStack>
                </VStack>
            </VStack>
        </GridItem>
    );

    const renderRightPanel = () => (
        <GridItem colSpan={1} bg='#FAF7F2' height="100vh" overflow="auto">
            <Flex direction="column" minHeight="100%">
                {!isLargerThan1100 && (
                    <Flex alignItems="center" p={4} bg="#FAF7F2">
                        <Link href="https://lingoleap.ai" isExternal>
                            <Image src={logoSVG} h="32px"/>
                        </Link>
                        <Link href="https://lingoleap.ai" isExternal>
                            <Image src={lingoLeapSVG} h="24px" ml={2}/>
                        </Link>
                    </Flex>
                )}
                <Flex flex="1" direction="column" justifyContent="center">
                    {loginOrVerify === "login" && renderLoginPanel()}
                    {loginOrVerify === "verify" && renderVerifyPanel()}
                    {loginOrVerify === "resetPass" && renderResetPasswordPanel()}
                    {loginOrVerify === "setNewPassword" && renderSetNewPasswordPanel()}
                </Flex>
            </Flex>
        </GridItem>
    );

    const renderLoginPanel = () => (
        <Flex
            direction="column"
            h={isLargerThan1100 ? "100%" : "calc(100% - 60px)"}
            p={isLargerThan1100 ? 10 : 4}
            justifyContent="center"
            alignItems="center"
        >
            <VStack spacing={isLargerThan1100 ? 4 : 2} width={isLargerThan1100 ? "80%" : "100%"}>
                <Box w="100%" shadow="md" borderWidth="1px" bg="white" borderRadius="8">
                    <VStack alignItems="stretch" spacing={6} p={isLargerThan1100 ? "7%" : "5%"}>
                        <Box>
                            <Heading as="h2" fontSize="24px" fontWeight="bold" color="#232323" mb={1}>
                                {!isLogin ? t('loginPanel.createYourAccount') : t('loginPanel.welcomeBack')}
                            </Heading>

                            <Text color="#717171" fontSize="16px" fontWeight="medium">
                                {!isLogin ? t('loginPanel.alreadyHaveAccount') : t('loginPanel.dontHaveAccount')}
                                <Link
                                    onClick={toggleLoginRegister}
                                    color="#6062FF"
                                    fontWeight="bold"
                                    _hover={{color: '#5145CD'}}
                                >
                                    {!isLogin ? t('loginPanel.loginHere') : t('loginPanel.createAccount')}
                                </Link>
                            </Text>
                        </Box>

                        <Tabs isFitted variant="unstyled" defaultIndex={tabActiveIndex}
                              onChange={(index) => setTabActiveIndex(index)}>
                            <TabList mb="1em">

                                <Tab
                                    borderTopLeftRadius='8px'
                                    borderBottomLeftRadius='8px'
                                    borderTopRightRadius='0'
                                    borderBottomRightRadius='0'
                                    _selected={{
                                        color: 'white',
                                        bg: 'black',
                                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
                                    }}
                                    _hover={tabActiveIndex !== 0 && {background: '#F7F7F7'}}
                                    _focus={{boxShadow: 'none'}}
                                    color="black"
                                    bg="white"
                                    boxShadow="0px 2px 4px rgba(0, 0, 0, 0.05)"
                                    transition="all 0.3s ease"
                                >
                                    <EmailIconSVG/>
                                    <Text ml={2} fontSize="14px" fontWeight="medium"
                                          color={tabActiveIndex === 0 ? 'white' : '#232323'}>
                                        {t('loginPanel.email')}
                                    </Text>
                                </Tab>
                                <Tab
                                    borderTopRightRadius='8px'
                                    borderBottomRightRadius='8px'
                                    borderTopLeftRadius='0'
                                    borderBottomLeftRadius='0'
                                    _selected={{
                                        color: 'white',
                                        bg: 'black',
                                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
                                    }}
                                    _hover={tabActiveIndex !== 1 && {background: '#F7F7F7'}}
                                    _focus={{boxShadow: 'none'}}
                                    color="black"
                                    bg="white"
                                    boxShadow="0px 2px 4px rgba(0, 0, 0, 0.05)"
                                    transition="all 0.3s ease"
                                >
                                    <PhoneIconSVG/>
                                    <Text ml={2} fontSize="14px" fontWeight="medium"
                                          color={tabActiveIndex === 1 ? 'white' : '#232323'}>
                                        {t('loginPanel.phoneNumber')}
                                    </Text>
                                </Tab>
                            </TabList>
                            <TabPanels>

                                <TabPanel px={0}>
                                    {<EmailRegistry props={{
                                        callback: registeryCallback,
                                        isLogin,
                                        invitationCode,
                                    }}/>}
                                </TabPanel>
                                <TabPanel px={0}>
                                    {<PhoneRegistry props={{
                                        callback: registeryCallback,
                                        isLogin,
                                        invitationCode,
                                    }}/>}
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </VStack>
                </Box>
                <Box mt={isLargerThan1100 ? 4 : 2} width={isLargerThan400 ? "" : 80}>
                    <TermOfUse/>
                </Box>
            </VStack>
        </Flex>
    );

    const renderVerifyPanel = () => (
        <VerifyAccount props={{
            phone,
            email,
            invitationCode,
            password,
            isResetPasswordFlow,
            resetFrom,
            callback: registeryCallback,
        }}/>
    );

    const renderResetPasswordPanel = () => (
        <ResetPassword props={{
            callback: registeryCallback,
            resetFrom,
        }}/>
    );

    const renderSetNewPasswordPanel = () => (
        <SetNewPassword
            resetToken={resetToken}
            authToken={authToken}
            email={email}
            phone={phone}
            onResetSuccess={() => setLoginOrVerify("login")}
            onBackToLogin={() => setLoginOrVerify("login")}
        />
    );

    return (
        <>
            <Helmet>
                <meta property="og:title" content="lingoleap.ai"/>
                <meta property="og:description" content={`${t('leftPanel.heading')} ${t('leftPanel.subheading')}`}/>
                <meta property="og:image"
                      content={i18n.language === "cn" ?
                          "https://lingoleapstorage1.blob.core.windows.net/publicdata/lingoleap_register_cn.jpeg" :
                          "https://lingoleapstorage1.blob.core.windows.net/publicdata/lingoleap_register_en.jpeg"}/>
                <meta property="og:url" content={`${window.location.href}`}/>
                <meta property="og:type" content="website"/>
                <meta itemprop="name" content="lingoleap.ai"/>
                <meta itemprop="description" content={`${t('leftPanel.heading')} ${t('leftPanel.subheading')}`}/>
                <meta itemprop="image" content={i18n.language === "cn" ?
                    "https://lingoleapstorage1.blob.core.windows.net/publicdata/lingoleap_register_cn.jpeg" :
                    "https://lingoleapstorage1.blob.core.windows.net/publicdata/lingoleap_register_en.jpeg"}/>
            </Helmet>
            <ChakraProvider theme={theme}>
                <Grid h='100vh' templateColumns={isLargerThan1100 ? 'repeat(2, 1fr)' : '1fr'} overflow="hidden">
                    {isLargerThan1100 && renderLeftPanel()}
                    {renderRightPanel()}
                </Grid>
            </ChakraProvider>
        </>
    );
}
