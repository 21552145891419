import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import AuthService from "./auth.service";


export const cmApi = createApi({
    reducerPath: 'cmApi',
    baseQuery: async (args, api, extraOptions) => {
        const baseQuery = fetchBaseQuery(
            {
                baseUrl: process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin,
                prepareHeaders: (headers) => {
                    headers.set('Access-Control-Allow-Origin', '*'); // Allow CORS by setting the Access-Control-Allow-Origin header
                    return headers;
                },
            })
        let result = await baseQuery(args, api, extraOptions)
        if (result.error && result.error.status === 401) {
            localStorage.removeItem("user");
            window.location.replace("/");
        }
        return result;
    },
    tagTypes: ['History', 'Preferences', 'Tags', 'Questions'],
    endpoints: (builder) => ({
        getTaskHistory: builder.query({
            query: ({}) => ({
                url: `/api/get_task_history`,
                headers: {
                    'authorization': AuthService.getCurrentUser()['token']
                }
            }),
            providesTags: ['History'],
        }),
        getTask: builder.query({
            query: ({gid}) => ({
                url: `/api/get_task?gid=${gid}`,
                headers: {
                    'authorization': AuthService.getCurrentUser()['token']
                }
            }),
        }),
        getExampleAudio: builder.query({
            query: ({gid}) => ({
                url: `/api/get_example_audio?gid=${gid}`,
                headers: {
                    'authorization': AuthService.getCurrentUser()['token']
                }
            }),
        }),
        getCreditHistory: builder.query({
            query: ({}) => ({
                url: `/api/credit_history`,
                headers: {
                    'authorization': AuthService.getCurrentUser()['token']
                }
            })
        }),
        getBalance: builder.query({
            query: ({}) => ({
                url: `/api/get_balance`,
                headers: {
                    'authorization': AuthService.getCurrentUser()['token']
                }
            }),
            providesTags: ['History'],
        }),
        getPurchaseInfo: builder.query({
            query: ({lang}) => ({
                url: `/api/get_purchase_info?lang=${lang}`,
                headers: {
                    'authorization': AuthService.getCurrentUser()['token']
                }
            }),
            providesTags: ['History'],
        }),
        getUserPreferences: builder.query({
            query: ({}) => ({
                url: `/api/get_user_preferences`,
                headers: {
                    'authorization': AuthService.getCurrentUser()['token']
                }
            }),
            providesTags: ['Preferences'],

        }),
        updateUserPreferences: builder.mutation({
            query: (payload) => ({
                url: `/api/update_user_preferences`,
                method: 'POST',
                body: payload,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'authorization': AuthService.getCurrentUser()['token']
                }
            }),
            invalidatesTags: ['Preferences'],
        }),
        generateAnswer: builder.mutation({
            query: (payload) => ({
                url: '/api/generate',
                method: 'POST',
                body: payload,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            refetchOnMountOrArgChange: 'always',
        }),
        login: builder.mutation({
            query: (payload) => ({
                url: '/api/login',
                method: 'POST',
                body: payload,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            refetchOnMountOrArgChange: 'always',
        }),
        register: builder.mutation({
            query: (payload) => ({
                url: '/api/register',
                method: 'POST',
                body: payload,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            refetchOnMountOrArgChange: 'always',
        }),
        adminTopUp: builder.mutation({
            query: (payload) => ({
                url: '/api/admin_top_up',
                method: 'POST',
                body: payload,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'authorization': AuthService.getCurrentUser()['token']
                },
            }),
            refetchOnMountOrArgChange: 'always',
        }),
        adminCreateReferral: builder.mutation({
            query: (payload) => ({
                url: '/api/create_referral',
                method: 'POST',
                body: payload,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'authorization': AuthService.getCurrentUser()['token']
                },
            }),
            refetchOnMountOrArgChange: 'always',
        }),
        getQuestionList: builder.query({
            query: ({
                        test,
                        section,
                        question_set,
                        tpo_numbers,
                        question_year,
                        gen_types,
                        completion_status,
                        tags,
                        is_full_set
                    }) => {
                let url = `/api/get_question_list?`
                if (test) {
                    url += `&test=${test}`;
                }
                if (section) {
                    url += `&section=${section}`;
                }
                if (question_set) {
                    url += `&question_set=${question_set}`;
                }
                if (tpo_numbers) {
                    url += `&tpo_numbers=${tpo_numbers}`;
                }
                if (question_year) {
                    url += `&question_year=${question_year}`;
                }
                if (gen_types) {
                    url += `&gen_types=${gen_types.join(',')}`;
                }
                if (completion_status) {
                    url += `&completion_status=${completion_status}`;
                }
                if (tags) {
                    url += "&tag=" + tags.join("&tag=")
                }
                if (is_full_set) {
                    url += `&is_full_set=${is_full_set}`
                }
                return {
                    url: url,
                    headers: {
                        'authorization': AuthService.getCurrentUser()['token']
                    }
                }

            }
        }),
        getQuestionByQid: builder.query({
            query: ({qid}) => ({
                url: `/api/get_question_by_qid?qid=${qid}`,
                headers: {
                    'authorization': AuthService.getCurrentUser()['token']
                }
            })
        }),
        getGrading: builder.query({
            query: ({gid}) => ({
                url: `/api/get_grading?gid=${gid}`,
                headers: {
                    'authorization': AuthService.getCurrentUser() ? AuthService.getCurrentUser()['token'] : ""
                }
            })
        }),
        submitFeedback: builder.mutation({
            query: (payload) => ({
                url: '/api/submit_feedback',
                method: 'POST',
                body: payload,
                headers: {
                    'authorization': AuthService.getCurrentUser()['token']
                },
            })
        }),
        updateSharingStatus: builder.mutation({
            query: (payload) => ({
                url: `/api/update_sharing_status`,
                method: 'POST',
                body: payload,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'authorization': AuthService.getCurrentUser()['token']
                }
            }),
            invalidatesTags: ['Preferences'],
        }),
        getExampleAnswer: builder.query({
            query: ({section, task, level}) => ({
                url: `/api/get_example_answer?section=${section}&task=${task}&level=${level}`,
                headers: {
                    'authorization': AuthService.getCurrentUser()['token']
                }
            })
        }),
        report: builder.mutation({
            query: (payload) => ({
                url: '/api/report',
                method: 'POST',
                body: payload,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'authorization': AuthService.getCurrentUser()['token']
                },
            })
        }),
        nonUserReport: builder.mutation({
            query: (payload) => ({
                url: '/api/report_non_user',
                method: 'POST',
                body: payload,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
        }),
        uploadPracticeBundle: builder.mutation({
            query: (payload) => ({
                url: '/api/admin_upload_practice_bundle',
                method: 'POST',
                body: payload,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'authorization': AuthService.getCurrentUser()['token']
                },
            }),
            invalidatesTags: ['QuestionBundles'],
        }),
        updateQuestionBundleStatus: builder.mutation({
            query: (payload) => ({
                url: '/api/admin_update_question_bundle_status',
                method: 'POST',
                body: payload,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'authorization': AuthService.getCurrentUser()['token']
                },
            }),
            invalidatesTags: ['QuestionBundles'],

        }),
        publishPracticeBundle: builder.mutation({
            query: (payload) => ({
                url: '/api/admin_publish_question_bundle',
                method: 'POST',
                body: payload,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'authorization': AuthService.getCurrentUser()['token']
                },
            }),
            invalidatesTags: ['QuestionBundles'],

        }),
        unpublishPracticeBundle: builder.mutation({
            query: (payload) => ({
                url: '/api/admin_unpublish_question_bundle',
                method: 'POST',
                body: payload,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'authorization': AuthService.getCurrentUser()['token']
                },
            }),
            invalidatesTags: ['QuestionBundles'],

        }),
        getPracticeBundles: builder.query({
            query: ({}) => ({
                url: `/api/admin_get_practice_bundles`,
                headers: {
                    'authorization': AuthService.getCurrentUser()['token']
                },
            }),
            providesTags: ['QuestionBundles'],
        }),
        getQuestionsByQuestionSet: builder.query({
            query: ({question_set, section_task}) => ({
                url: `/api/admin_get_questions_by_question_set?question_set=${question_set}&section_task=${section_task}`,
                headers: {
                    'authorization': AuthService.getCurrentUser()['token']
                },
            }),
        }),
        getGaolianPrice: builder.query({
            query: ({}) => ({
                url: `/api/get_gaolian_price`,
            }),
        }),
        getPsAidInfo: builder.query({
            query: ({}) => ({
                url: `/api/get_ps_aid_info`,
                headers: {
                    'authorization': AuthService.getCurrentUser()['token']
                },
            })
        }),
        savePractice: builder.mutation({
            query: (payload) => ({
                url: '/api/save_practice',
                method: 'POST',
                body: payload,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'authorization': AuthService.getCurrentUser()['token']
                },
            }),
            invalidatesTags: ['History'],
        }),
        uploadUserAudio: builder.mutation({
                query: (payload) => {
                    //const formData = new FormData();
                    //formData.append('file', new Blob([payload], { type: payload.type }), 'audiofile');
                    return {
                        url: '/api/upload_user_audio',
                        method: 'POST',
                        body: payload,
                        headers: {
                            //'Content-type': 'multipart/form-data',
                            'Content-type': 'application/json; charset=UTF-8',
                            'authorization': AuthService.getCurrentUser()['token']
                        }
                    }
                }
            }
        ),
        getQuestionSetNames: builder.query({
            query: ({}) => {
                return {
                    url: '/api/get_question_set_names',
                    headers: {
                        'authorization': AuthService.getCurrentUser()['token']
                    },
                }
            },
        },),
        adminGetQuestionList: builder.query({
            query: ({question_set, gen_type, completion_status}) => {
                let url = `/api/get_question_list?gen_types=${gen_type}&completion_status=${completion_status}&visibility=ACTIVE_PUBLISHED`
                if (question_set) {
                    url += `&question_set=${question_set}`;
                }
                return {
                    url: url,
                    headers: {
                        'authorization': AuthService.getCurrentUser()['token']
                    }
                }

            },
            providesTags: ['Questions']
        }),
        adminGetQuestionByQid: builder.query({
            query: ({qid}) => {
                let url = `/api/admin_get_question_by_qid?qid=${qid}`
                return {
                    url: url,
                    headers: {
                        'authorization': AuthService.getCurrentUser()['token']
                    }
                }

            },
            // providesTags: ['Questions']
        }),
        getTags: builder.query({
                query: ({}) => {
                    return {
                        url: '/api/get_tags',
                        headers: {
                            'authorization': AuthService.getCurrentUser()['token']
                        },
                    }
                },
                providesTags: ['Tags']
            },
        ),
        removeTag: builder.mutation({
            query: ({payload}) => {
                return {
                    url: `/api/admin_remove_tags`,
                    body: payload,
                    method: "POST",
                    headers: {
                        'authorization': AuthService.getCurrentUser()['token']
                    },
                }
            },
            invalidatesTags: ['Tags']
        },),
        addTag: builder.mutation({
            query: ({payload}) => {
                return {
                    url: `/api/admin_add_tags`,
                    body: payload,
                    method: "POST",
                    headers: {
                        'authorization': AuthService.getCurrentUser()['token']
                    },
                }
            },
            invalidatesTags: ['Tags']
        },),
        addTagToQuestion: builder.mutation({
            query: ({payload}) => {
                return {
                    url: `/api/admin_add_tag_to_question`,
                    body: payload,
                    method: "POST",
                    headers: {
                        'authorization': AuthService.getCurrentUser()['token']
                    },
                }
            },
            invalidatesTags: ['Questions']
        },),
        removeTagFromQuestion: builder.mutation({
            query: ({payload}) => {
                return {
                    url: `/api/admin_remove_tag_from_question`,
                    body: payload,
                    method: "POST",
                    headers: {
                        'authorization': AuthService.getCurrentUser()['token']
                    },
                }
            },
            invalidatesTags: ['Questions']
        },),
        addQuestion: builder.mutation({
            query: ({payload}) => {
                return {
                    url: `/api/admin_add_question`,
                    body: payload,
                    method: 'POST',
                    headers: {
                        'authorization': AuthService.getCurrentUser()['token']
                    },
                }
            },
            invalidatesTags: ['Questions']
        },),
        updateQuestion: builder.mutation({
            query: ({payload}) => {
                return {
                    url: `/api/admin_update_question`,
                    body: payload,
                    method: 'POST',
                    headers: {
                        'authorization': AuthService.getCurrentUser()['token']
                    },
                }
            },
            invalidatesTags: ['Questions']
        },),
        updateQuestionOrder: builder.mutation({
            query: ({payload}) => {
                return {
                    url: `/api/update_question_order`,
                    body: payload,
                    method: 'POST',
                    headers: {
                        'authorization': AuthService.getCurrentUser()['token']
                    },
                }
            },
            invalidatesTags: ['Questions']
        },),
        updateQuestionStatus: builder.mutation({
            query: ({payload}) => {
                return {
                    url: `/api/update_question_status`,
                    body: payload,
                    method: 'POST',
                    headers: {
                        'authorization': AuthService.getCurrentUser()['token']
                    },
                }
            },
            invalidatesTags: ['Questions']
        },),
        getPromotionCode: builder.query({
            query: ({promotion_type}) => {
                let url = `/api/get_promotion_code?promotion_type=${promotion_type}`
                return {
                    url: url,
                    headers: {
                        'authorization': AuthService.getCurrentUser()['token']
                    }
                }
            },
            // providesTags: ['Questions']
        }),
        
        // 兑换码相关API
        // 生成兑换码（管理员）
        generateRedemptionCodes: builder.mutation({
            query: (payload) => ({
                url: '/api/redemption_code/generate',
                method: 'POST',
                body: payload,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'authorization': AuthService.getCurrentUser()['token']
                },
            }),
        }),
        
        // 兑换兑换码
        redeemRedemptionCode: builder.mutation({
            query: (payload) => ({
                url: '/api/redemption_code/redeem',
                method: 'POST',
                body: payload,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'authorization': AuthService.getCurrentUser()['token']
                },
            }),
        }),
        
        // 查询兑换码（管理员）
        queryRedemptionCodes: builder.query({
            query: ({}) => {
                let url = `/api/redemption_code/query?`;
                return {
                    url: url,
                    headers: {
                        'authorization': AuthService.getCurrentUser()['token']
                    }
                };
            },
        }),
    })
});

export const {
    useGenerateAnswerMutation,
    useLoginMutation,
    useRegisterMutation,
    useGetTaskHistoryQuery,
    useGetTaskQuery,
    useGetExampleAudioQuery,
    useGetBalanceQuery,
    useGetPurchaseInfoQuery,
    useGetUserPreferencesQuery,
    useUpdateUserPreferencesMutation,
    useGetCreditHistoryQuery,
    useAdminTopUpMutation,
    useAdminCreateReferralMutation,
    useGetQuestionListQuery,
    useGetQuestionByQidQuery,
    useGetGradingQuery,
    useSubmitFeedbackMutation,
    useUpdateSharingStatusMutation,
    useGetExampleAnswerQuery,
    useReportMutation,
    useNonUserReportMutation,
    useUploadPracticeBundleMutation,
    useUpdateQuestionBundleStatusMutation,
    useGetPracticeBundlesQuery,
    useGetQuestionsByQuestionSetQuery,
    useGetGaolianPriceQuery,
    useGetPsAidInfoQuery,
    useSavePracticeMutation,
    useUploadUserAudioMutation,
    //
    useGetQuestionSetNamesQuery,
    useAdminGetQuestionListQuery,
    useGetTagsQuery,
    useRemoveTagMutation,
    useAddTagMutation,
    useAddTagToQuestionMutation,
    useRemoveTagFromQuestionMutation,
    useAddQuestionMutation,
    useUpdateQuestionMutation,
    useUpdateQuestionOrderMutation,
    useAdminGetQuestionByQidQuery,
    useUpdateQuestionStatusMutation,
    useGetPromotionCodeQuery,
    useGenerateRedemptionCodesMutation,
    useRedeemRedemptionCodeMutation,
    useQueryRedemptionCodesQuery
} = cmApi;
