import React from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import {Box, Center, chakra, Spinner, VStack, Text, Button, Link} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import {selectMarkdownResponse, selectIeltsData} from "../redux/ieltsSlice";
import rehypeRaw from "rehype-raw";
import ReactAudioPlayer from "react-audio-player";
import { useGetExampleAudioQuery } from "../services/cmApi";
import ResponseFeedBack from "./ResponseFeedBack";
import { useTranslation } from 'react-i18next';
import { ShareResultButton } from "./ShareResultButton";
import ChakraMarkdownComponents from "./ChakraMarkdownComponents";
import {IeltsSpeakingQuestionAnswerList} from "./IeltsSpeakingQuestionAnswerList";
import { DownloadIcon } from "@chakra-ui/icons";
import html2pdf from 'html2pdf.js';
import logoPng from "../assets/logo_long2.png";
import { useRef } from "react";


export default function IeltsResponsePanel(props) {
    const { t, i18n } = useTranslation();
    const ieltsData = useSelector(selectIeltsData);
    const markdownResponse = useSelector(selectMarkdownResponse);
    const responseRef = useRef(null);

    const showMarkDown = ieltsData.shouldShowMarkdown || ieltsData.isStreaming;
    const isStreaming = ieltsData.isStreaming;
    const getResponse = () => {
        if (markdownResponse.result) {
            return markdownResponse.result;
        } else {
            return "";
        }
    };

    let exampleAudio = useGetExampleAudioQuery(
        { gid: ieltsData.gid }, { skip: !showMarkDown || isStreaming });

    const getSpeakingQuestionAnswerList = () => {
        if (exampleAudio.status === 'fulfilled') {
            if ('speaking_question_answer_list' in exampleAudio.data) {
                return exampleAudio.data['speaking_question_answer_list'];
            } else {
                return [];
            }
        } else {
            return [];
        }
    };

    const showExampleAudioPlayer = showMarkDown && !isStreaming && !ieltsData.hasStreamingError && ieltsData.section === "speaking" && exampleAudio.status === 'fulfilled';
    const IELTSScoringRubricEn = "https://lingoleap.ai/read/how-is-the-ielts-score-calculated/"
    const IELTSScoringRubricCn = "https://lingoleap.ai/zh/read/how-is-the-ielts-score-calculated-2/"

    let showRubric = true;
    let rubricLink = "";

    if (i18n.language == "cn") {
        rubricLink = IELTSScoringRubricCn;
    } else {
        rubricLink = IELTSScoringRubricEn;
    }

    const handleDownloadPDF = () => {
        const content = responseRef.current.cloneNode(true);
        
        const buttonsToRemove = content.querySelectorAll('.pdf-exclude');
        buttonsToRemove.forEach(element => element.remove());
        
        const logoContainer = document.createElement('div');
        logoContainer.style.textAlign = 'center';
        logoContainer.style.marginBottom = '20px';
        const logo = document.createElement('img');
        logo.src = logoPng;
        logo.style.height = '50px';
        logoContainer.appendChild(logo);
        content.insertBefore(logoContainer, content.firstChild);

        // Create filename with date
        const today = new Date();
        const date = today.toISOString().split('T')[0]; // Format: YYYY-MM-DD
        const filename = `Lingoleap_${date}.pdf`;

        const opt = {
            margin: 1,
            filename: filename,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        
        html2pdf().set(opt).from(content).save();
    };

    return <>
        <Box ml={5} mr={5} mt={10} p={5} bg='gray.200' borderRadius={20}
            display={showMarkDown ? "block" : "none"}
            ref={responseRef}>
            <Center display={isStreaming ? "block" : "none"}>
                <Text fontWeight="bold">{t('streaming_reminder')}</Text>
            </Center>
            <ReactMarkdown
                children={getResponse()} remarkPlugins={[gfm]}
                rehypePlugins={[rehypeRaw]} className="ll-markdown"
                components={ChakraMarkdownComponents}
            />
            <Center>
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='lg'
                    mt='20px'
                    display={isStreaming ? "block" : "none"}
                />
            </Center>
            <Center display={showExampleAudioPlayer ? "block" : "none"}>
                <Text fontWeight="bold">{t('example_answers')}</Text>
                <IeltsSpeakingQuestionAnswerList data={getSpeakingQuestionAnswerList()}></IeltsSpeakingQuestionAnswerList>
            </Center>
            <Box mt={4} display={(showRubric && !isStreaming) ? "flex" : "none"} justifyContent="space-between" alignItems="center">
                <Link color="blue.500" fontWeight={"bold"} fontSize={"sm"} href={rubricLink} isExternal>
                    ⓘ {t("response_scoring_rubrics")}
                </Link>
                <Button
                    variant="ghost"
                    size="sm"
                    leftIcon={<DownloadIcon />}
                    onClick={handleDownloadPDF}
                    display={markdownResponse.result && !isStreaming ? "block" : "none"}
                >
                    {t("download_pdf")}
                </Button>
            </Box>

            <Center mt="10px" className="pdf-exclude">
                <ShareResultButton visible={ieltsData.shouldShowMarkdown && !ieltsData.isStreaming && !ieltsData.hasStreamingError} gid={ieltsData.gid}></ShareResultButton>
            </Center>
            <Center className="pdf-exclude">
                <Box mt={10} p={5} bg='gray.100' borderRadius={20} width={'full'} display={ieltsData.shouldShowMarkdown && !isStreaming ? "block" : "none"}>
                    <VStack>
                        <Text as={'h3'} fontSize={'md'}>
                            {t("your_santisfication_rate")}
                        </Text>
                        <ResponseFeedBack gid={ieltsData.gid}/>
                    </VStack>
                </Box>
            </Center>
        </Box>
    </>;
}