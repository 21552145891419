export const storeRedirectData = (redirectValue, expirationMinutes = 3) => {
    if (!redirectValue) return;

    // Create URL object to parse the redirect URL
    try {
        const url = new URL(redirectValue);
        const allowedDomains = ['localhost', 'lingoleap.ai', 'lingoleap.cn'];
        
        // Check if the hostname matches any allowed domain
        const isAllowedDomain = allowedDomains.some(domain => {
            if (domain === 'localhost') {
                return url.hostname === 'localhost';
            }
            return url.hostname.endsWith(domain);
        });

        if (isAllowedDomain) {
            const expirationTime = new Date().getTime() + (expirationMinutes * 60 * 1000);
            const redirectData = {
                value: redirectValue,
                expiresAt: expirationTime
            };
            localStorage.setItem('redirectData', JSON.stringify(redirectData));
        } else {
            console.warn(`Rejected redirect attempt to unauthorized domain: ${url.hostname}`);
        }
    } catch (error) {
        console.warn(`Invalid redirect URL: ${redirectValue}`, error);
    }
};

export const getRedirectData = () => {
    const redirectData = JSON.parse(localStorage.getItem('redirectData'));
    if (redirectData && new Date().getTime() < redirectData.expiresAt) {
        return redirectData.value;
    }
    localStorage.removeItem('redirectData');
    return null;
};

// Add this function to handle the URL parameters
export const handleRedirectParam = (location) => {
    const query = new URLSearchParams(location.search);
    const redirectValue = query.get('redirect');
    storeRedirectData(redirectValue);
};
