import React, {useEffect, useRef, useState} from "react";

import {Box, Text, VStack} from '@chakra-ui/react'
import {ToeflIbtContext} from "./ToeflIbtContext";
//"▶"

export const ToeflReadingQuestionRenderReadingText = (props) => {
    var {textArray, questionText, subIdx, ...other} = props;
    const {
        answerContent,
        setAnswerContent
    } = React.useContext(ToeflIbtContext);
    const itemRefs = useRef([])
    const [clickSquareIdx, setClickSquareIdx] = useState(-1)
    const alphabet = ['A', 'B', 'C', 'D', 'E', 'F']
    let squareCount = -1

    useEffect(() => {
        const answer = answerContent?.reading?.[subIdx]
        const squareIdx = answer ? alphabet.indexOf(answer) : -1
        setClickSquareIdx(squareIdx)
    }, []);


    const scrollToItem = (index) => {
        if (itemRefs.current[index]) {
            itemRefs.current[index].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            })
        }
    }

    const handleOptionChange = (value) => {
        setAnswerContent(prevAnswerContent => ({
            ...prevAnswerContent,
            reading: {
                ...prevAnswerContent.reading,
                [subIdx]: value
            }
        }));
    };


    const handleSquare = (text) => {
        const textSplit = text.split('【▇】')
        return (
            <Text>
                {textSplit.map((item, idx) => {
                    if (idx === 0) {
                        return <span key={idx}>{item}</span>
                    }
                    let squareIdx = squareCount += 1
                    return <Box key={`k-${idx}`} display="inline">
                        {(clickSquareIdx !== squareIdx) ? (
                            <Box key={idx} display="inline" as="span"
                                 onClick={(e) => {
                                     // console.log("current click idx: ", clickSquareIdx);
                                     if (clickSquareIdx === -1 || clickSquareIdx !== squareIdx) {
                                         // console.log("set click idx to : ", squareIdx);
                                         setClickSquareIdx(squareIdx);
                                         const letter = alphabet[squareIdx];
                                         handleOptionChange(letter)
                                     } else {
                                         setClickSquareIdx(-1);
                                         handleOptionChange('')
                                     }
                                 }}
                            >
                                <span
                                    style={{
                                        display: "inline-block",
                                        width: "20px",
                                        height: "20px",
                                        backgroundColor: "#066A6E",
                                        margin: "-8px 1px 0",
                                        verticalAlign: "middle",
                                        borderRadius: "2px"
                                    }}>
                                </span>
                            </Box>
                        ) : (
                            <Box
                                key={idx}
                                as="span"
                                display="inline"
                                backgroundColor="teal"
                                color={"white"}
                                p="4px"
                                ml={2}
                                mr={2}
                                borderRadius="4px"
                                as="span"
                                // fontWeight={"bold"}
                            >
                                {questionText[1]}
                            </Box>)}

                        <span key={`text-${squareIdx}`}>{item}</span>
                    </Box>
                })}
            </Text>
        )
    }


    return (
        <VStack
            border="2px"
            borderColor="gray.200"
            borderRadius="16"
            height="100%"
            width="100%"
            spacing={0}
        >
            <Box
                h="5%"
                w="100%"
                p={4}
                mb={2}
            >
                <Text
                    fontSize={"15.68px"}
                    fontWeight="700"
                    fontFamily={"Open Sans"}
                    color="#3d3d3d"
                    textAlign="center"
                >
                    {textArray && textArray[0]}
                </Text>
            </Box>
            <Box
                overflowY="auto"
                width="100%"
                height='94%'
            >
                <VStack spacing={4} align="stretch" p={3}>
                    {textArray && textArray.map((text, id) => {
                        if (id === 0) {
                            return
                        }
                        if (text[0] === "▶") {
                            setTimeout(() => {
                                scrollToItem(id)
                            }, 200)
                            return (
                                <Box
                                    key={id}
                                    ref={el => itemRefs.current[id] = el}
                                    position="relative"
                                    paddingLeft="20px"
                                    _before={{
                                        content: '""',
                                        position: "absolute",
                                        left: 0,
                                        top: 0,
                                        bottom: 0,
                                        width: "3px",
                                        backgroundColor: "green",
                                    }}
                                >
                                    {(text.includes('【▇】') ? (
                                            handleSquare(text.substring(1))
                                        ) : (
                                            // <div dangerouslySetInnerHTML={{__html: text.substring(1)}}/>
                                            <div dangerouslySetInnerHTML={{
                                                __html: text.substring(1).replace(
                                                    /<span style="color:#00BFFF;">(.*?)<\/span>/g,
                                                    '<span style="background-color: teal; color: ' +
                                                    'white;padding: 4px;border-radius: 4px;' +
                                                    'margin-left: 2px; margin-right: 2px;">$1</span>')
                                            }}
                                            />
                                        )
                                    )}
                                </Box>
                            )
                        } else {
                            return (
                                <Box key={id} position="relative" paddingLeft="20px">
                                    <div dangerouslySetInnerHTML={{__html: text}}/>
                                </Box>
                            )
                        }
                    })}
                </VStack>
            </Box>
        </VStack>

    )
}