import React, {useEffect, useState} from 'react';
// import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Divider,
    Flex,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Spinner,
    Tab,
    TabList,
    Tabs,
    Text,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import {FaAngleLeft, FaAngleRight, FaEye, FaEyeSlash, FaVolumeHigh} from "react-icons/fa6";
import {useHistory} from "react-router-dom";
import {getRouteInfo, PracticeTypeEnum, ToeflIbtContext} from "./ToeflIbtContext";
import FinishWarning from "./ToeflIbtFinishWarning"
import axios from 'axios';
import {useTranslation} from "react-i18next";
import mixpanel from 'mixpanel-browser';
import authHeader from "../../services/auth-header";
import {isEqual} from "./ToeflScoreReport";
import {useSavePracticeMutation} from "../../services/cmApi";
import {VscFeedback} from "react-icons/vsc";
import GeneralFeedbackModal from "../modals/GeneralFeedbackModal";
import {IoWarning} from "react-icons/io5";

const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;
const MAX_RETRIES = 3; // 最大重试次数
const RETRY_DELAY = 1000; // 重试间隔时间（毫秒）

function ToeflIbtMenu({isCountdownPaused, setIsCountdownPaused}) {
    const history = useHistory();
    const {
        questionContent,
        setQuestionContent,
        volume,
        setVolume,
        answerContent,
        setAnswerContent,
        setMockId,
        mockId,
        mockJson,
        setMockJson,
        mediaRecorder
    } = React.useContext(ToeflIbtContext);
    // qid为all时则是模考，练习时则为单个part
    const {
        parentPath,
        questionSet,
        tpoNum,
        section,
        practiceType,
        qid,
        childPath,
        otherParams,
        mid
    } = getRouteInfo(location.pathname);
    const [partIdx, subIdx] = parseParams();
    const displayReadingTabs = checkDisplayReadingTabs(partIdx, subIdx);
    const totalNum = questionContent?.[section]?.total_question_num || 0;
    const isShowCountDownDisplay = showCountDownDisplay();
    const [countdown, setCountdown] = useState(-1); // 初始化倒计时为60秒
    const [showCountdown, setShowCountdown] = useState(true); // 控制倒计时显示的状态
    const [isOpen, setIsOpen] = useState(false); // 添加状态管理
    const toast = useToast();
    const {t, i18n} = useTranslation();
    const [savePracticeRequest, savePracticeError] = useSavePracticeMutation();
    const [lastMid, setLastMid] = useState(null); // 存储上一次未完成的mockId
    const [isLastOpen, setIsLastOpen] = useState(false); // 控制上一次未完成的模考的显示状态
    const {isOpen: isOpenFeedback, onOpen: onOpenFeedback, onClose: onCloseFeedback} = useDisclosure()
    const {isOpen: isOpenIncorrect, onOpen: onOpenIncorrect, onClose: onCloseIncorrect} = useDisclosure()
    const [totalCountdownTime, setTotalCountdownTime] = useState(0)
    const [readingUrlStack, setReadingUrlStack] = useState([
        practiceType === PracticeTypeEnum.FULL_MOCK_TEST ? `/toefl/full_mock_test` : `/toefl/${section}`]);
    const [answerContentByMid, setAnswerContentByMid] = useState({});
    const [uploadWritingAnswer, setUploadWritingAnswer] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenChrome, setIsOpenChrome] = useState(false);

    useEffect(() => {
        // 题目初始化 示例‘/toefl_ibt/tpo_1/all_listening/all’ 全科模考时获取所有题
        const sections = practiceType === PracticeTypeEnum.FULL_MOCK_TEST ?
            ['reading', 'listening', 'speaking', 'writing'] : [section]
        sections.map(sectionStr => {
            if (!questionContent || !Object.keys(questionContent).includes(sectionStr)) {
                fetchQuestionContent(sectionStr)
            }
        })
    }, [parentPath]);

    useEffect(() => {
        // 页面判断
        if (!questionContent) {
            return;
        } else if (practiceType === PracticeTypeEnum.FULL_MOCK_TEST && Object.keys(questionContent).length !== 4) {
            return;
        } else if (practiceType !== PracticeTypeEnum.FULL_MOCK_TEST && Object.keys(questionContent).length !== 1) {
            return;
        } else if (childPath === 'headware_check' && practiceType === PracticeTypeEnum.FULL_MOCK_TEST) {
            // 检测浏览器类型
            const isEdge = /Edg/i.test(navigator.userAgent);
            const isChrome = /Chrome/i.test(navigator.userAgent) && !isEdge;
            console.log('isChrome', isChrome);
            if (!isChrome) {
                setIsOpenChrome(true);
            }
        }
    }, [childPath, partIdx, questionContent]);


    useEffect(() => {
        // 模考id初始化
        if (childPath === 'headware_check') {
            return;
        } else if (!mockId && mid) { // 加载答案
            getAnswerByMid(mid)
        } else if (!questionContent) {
            return;
        } else if (practiceType === PracticeTypeEnum.FULL_MOCK_TEST && Object.keys(questionContent).length !== 4) {
            return;
        } else if (practiceType !== PracticeTypeEnum.FULL_MOCK_TEST && Object.keys(questionContent).length !== 1) {
            return;
        } else if (!mockId && !lastMid) {
            checkLastUnfinishedMockId() // 刷新页面时判断是否存在未完成的mockId
        }
    }, [questionContent, mockId, lastMid, childPath]);


    useEffect(() => {
        // 答案解析
        if (Object.keys(answerContentByMid).length === 0) {
            return;
        } else if (!questionContent) {
            return;
        } else if (practiceType === PracticeTypeEnum.FULL_MOCK_TEST && Object.keys(questionContent).length !== 4) {
            return;
        } else if (practiceType !== PracticeTypeEnum.FULL_MOCK_TEST && Object.keys(questionContent).length !== 1) {
            return;
        } else {
            const answerData = {};
            let lastSection = 'reading';
            let lastPartIdx = 1;
            ['reading', 'listening', 'speaking', 'writing'].map(sectionStr => {
                questionContent?.[sectionStr]?.tasks?.map((task, index) => {
                    if (!Object.keys(answerData).includes(sectionStr)) {
                        answerData[sectionStr] = {}
                    }
                    const task_answer = answerContentByMid?.[task.qid]
                    if (task_answer) {
                        lastSection = sectionStr
                        lastPartIdx = index + 1
                    }
                    if (['reading', 'listening'].includes(sectionStr)) {
                        task.questions.map((subQuestion, idx) => {
                            answerData[sectionStr][subQuestion.sub_question_idx] = task_answer?.[idx + 1] || '';
                        })
                    } else {
                        answerData[sectionStr][index + 1] = answerContentByMid[task.qid] || {}
                        if (mid) { // 分享或答案页刷新
                            const score = answerData[sectionStr][index + 1]?.score;
                            const gid = answerData[sectionStr][index + 1]?.gid;
                            if (score) {
                                answerData[sectionStr][index + 1]["status"] = 'finished'; // 已批改完成
                            } else if (gid) {
                                answerData[sectionStr][index + 1]["status"] = 'uploaded'; // 已有用户答案
                            }
                        }
                    }
                })
            })
            setAnswerContent(answerData)
            // console.log('answerData', answerData)
            // 跳转到上次的地址
            if (!mid) { // 非分享数据展示
                let lastUrl
                if (lastSection === 'reading') {
                    lastUrl = `${parentPath.replace(section, lastSection)}/text/${lastPartIdx}`
                } else if (lastSection === 'listening') {
                    lastUrl = `${parentPath.replace(section, lastSection)}/audio/${lastPartIdx}`
                } else if (lastSection === 'speaking') {
                    lastUrl = `${parentPath.replace(section, lastSection)}/directions/${lastPartIdx}`
                } else if (lastSection === 'writing') {
                    lastUrl = `${parentPath.replace(section, lastSection)}/directions/${lastPartIdx}`
                }
                history.replace(lastUrl);
            }
        }
    }, [questionContent, answerContentByMid]);

    useEffect(() => {
        if (childPath === 'headware_check') {
            return;
        } else if (!questionContent) {
            return;
        } else if (practiceType === PracticeTypeEnum.FULL_MOCK_TEST && Object.keys(questionContent).length !== 4) {
            return;
        } else if (practiceType !== PracticeTypeEnum.FULL_MOCK_TEST && Object.keys(questionContent).length !== 1) {
            return;
        } else {
            // 总倒计时更新
            const minute = 60;
            let totalTimeV = 36 * minute;
            if (section === "reading") {
                totalTimeV = qid === "all" ? 36 * minute : 18 * minute
            } else if (section === "listening") {
                totalTimeV = qid === "all" ? 18 * minute : 3.5 * minute
            } else if (section === "writing") {
                const taskObj = questionContent?.[section]?.tasks[partIdx - 1];
                if (taskObj?.gen_type === 'writing_task1') {
                    if (childPath === 'text') {
                        totalTimeV = 3 * minute
                    } else if (childPath === 'question') {
                        totalTimeV = 20 * minute  // writing_task1
                    }
                } else if (childPath === 'question') {
                    totalTimeV = 10 * minute  // writing_task2
                }
            }
            setTotalCountdownTime(totalTimeV)
            // 页面初始化时判断是否是否需要初始化
            if (['speaking', 'writing'].includes(section)  // 口语、写作
                && childPath === "directions"  // directions 页面
                && partIdx > 0 // 任务参数存在
            ) {
                const task = questionContent?.[section]?.tasks?.[qid === 'all' ? partIdx - 1 : 0] // 判断是否为单题
                if (task) {
                    saveUserAnswer(section, {[task.qid]: {}}); //结果初始化
                }
            }
        }
    }, [questionContent, section, childPath, partIdx]);

    useEffect(() => {
        // 倒计时暂停状态更新
        let isPaused
        if (childPath === 'headware_check') {
            return;
        } else if (section === "reading") {
            isPaused = (childPath === 'directions') // 阅读仅在directions暂停
        } else if (section === "listening") {
            isPaused = true // 听力每部分都有音频
        } else if (section === "speaking") {
            isPaused = true // 口语用页面内的倒计时
        } else {
            isPaused = true
        }
        setIsCountdownPaused(isPaused)
        if (childPath === 'directions' && partIdx === 0) {
            mixpanel.track('Mock Test', {
                practiceType: practiceType,
                section: section,
                status: "Start"
            });
        }
    }, [section, childPath, partIdx, subIdx]);

    useEffect(() => {
        // 添加阅读回退地址缓存
        if (section === 'reading') {
            pushReadingUrl(location.pathname);
        }
    }, [location.pathname]);

    useEffect(() => {
        //  阅读、听力在答题后进行答案保存
        return () => {
            if (childPath === "question") {// question 页面
                if (['reading', 'listening'].includes(section)) {// 阅读、听力、写作是做题更新用户答案
                    saveAnswer(section) // 结果保存
                }
            }
        }
    }, [answerContent.reading, answerContent.listening, section, childPath, partIdx, subIdx]);

    useEffect(() => {
        //口语在答案更新时进行保存
        const gidV = answerContent.speaking?.[partIdx]?.['gid'];
        const answer = answerContent.speaking?.[partIdx]?.answer
        const status = answerContent.speaking?.[partIdx]?.status
        if (answer && !gidV) { // 未保存过答案才保存
            setIsLoading(true);
            onAudioSave(answer);
        } else if (gidV && status === 'uploaded') { // 已保存过答案
            setIsLoading(false);
            const nextUrl = getNextUrl();
            // console.log("nextUrl", nextUrl)
            if (nextUrl) {
                history.replace(nextUrl);
            }
        }
    }, [answerContent.speaking, partIdx]);

    useEffect(() => {
        //口语在答案更新时进行保存
        if (uploadWritingAnswer) {
            const gidV = answerContent.writing?.[partIdx]?.['gid'];
            const answer = answerContent.writing?.[partIdx]?.answer
            const status = answerContent.writing?.[partIdx]?.status
            if (answer && !gidV) { // 未保存过答案才保存
                setIsLoading(true);
                onAnswerSave(answer);

            } else if (answer && gidV && status === 'uploaded') { // 已保存过答案
                setIsLoading(false);
                setUploadWritingAnswer(false)
                const nextUrl = getNextUrl(true);
                // console.log("nextUrl", nextUrl)
                if (nextUrl) {
                    history.replace(nextUrl);
                }
            }
        }
    }, [answerContent.writing, partIdx, uploadWritingAnswer]);


    useEffect(() => {
        // 倒计时刷新
        if (totalCountdownTime > 0) {
            // console.log('useEffect:', section, totalCountdownTime)
            setCountdown(totalCountdownTime)
        }
    }, [section, totalCountdownTime]);

    useEffect(() => {
        // 计时器
        let timer;
        if (!isCountdownPaused && countdown >= 0 && !isLastOpen) {
            timer = setInterval(() => {
                setCountdown(prev => {
                    let nextUrl = null
                    if (prev > 0) {
                        return prev - 1;
                    }
                    // 倒计时结束自动跳转下一部分，需要区分听力、阅读；写作
                    if (section === 'reading') {
                        if (practiceType === 'full_mock_test') {
                            const newParentPath = parentPath.replace(section, "listening");
                            nextUrl = `${newParentPath}/directions`;
                        } else if (practiceType === 'mock_test') {
                            nextUrl = `${parentPath}/answer`
                        } else {
                            return 0
                        }
                    } else if (section === 'listening') {
                        if (practiceType === 'full_mock_test') {
                            const newParentPath = parentPath.replace(section, "speaking");
                            nextUrl = `${newParentPath}/directions`;
                        } else if (practiceType === 'mock_test') {
                            nextUrl = `${parentPath}/answer`
                        } else {
                            return 0
                        }
                    } else if (section === 'writing') {
                        if (practiceType !== 'practice' && ['text', 'question'].includes(childPath)) {
                            nextUrl = getNextUrl();
                        } else {
                            return 0
                        }
                    } else {
                        return 0
                    }
                    if (nextUrl) {
                        if (mockId && !nextUrl.includes('?mid=') && nextUrl.includes('toefl_ibt')) {
                            nextUrl += `?mid=${mockId}`;
                        }
                        history.replace(nextUrl);
                    }
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [isCountdownPaused, isLastOpen]);


    function parseParams() {
        if (!otherParams || otherParams.length === 0) {
            return [0, 0];
        } else if (otherParams.length === 1) {
            return [Number(otherParams[0]), 0];
        } else if (otherParams.length === 2) {
            return [Number(otherParams[0]), Number(otherParams[1])];
        } else {
            return [0, 0];
        }
    }

    const getAnswerByMid = (midV) => {
        setMockId(midV);
        axios.get(`${API_URL}/api/query_mock_test_answer_by_mid?mid=${midV}`, {headers: authHeader()}).then(
            response => {
                if (response.status === 200) {
                    setMockJson(response.data.mock_json);
                    setAnswerContentByMid(response.data.answer_content)
                    console.log(`${midV}: Get Answer successfully`);
                }
            }).catch(error => {
            console.log(`${midV}: Error get answer`, error);
            toast({
                title: "Failed to get answer",
                description: error.message,
                status: "error",
                duration: 2000,
                isClosable: true,
            });
        });
    }

    const checkLastUnfinishedMockId = () => {
        try {
            let headers = authHeader();
            axios.post(API_URL + '/api/get_last_unfinished_mid', { // 仅支持全科模考未完成查询
                test: 'toefl',
                section: practiceType === PracticeTypeEnum.FULL_MOCK_TEST ? 'reading' : section,
                question_set: questionSet,
                question_id: tpoNum,
                practice_type: practiceType
            }, {headers: headers}).then(response => {
                if (response.status === 200) {
                    if (response.data.mid) {
                        setLastMid(response.data.mid)
                        setIsLastOpen(true);
                    } else {
                        updateMockId() //创建新的mockId
                    }
                } else {
                    updateMockId() //创建新的mockId
                }
            }).catch(error => {
                console.log(`Unfinished mock test found`, error);
                toast({
                    title: "Unfinished mock test found",
                    description: error.message,
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });
            });
        } catch (error) {
            console.error("Unfinished mock test found:", error);
            toast({
                title: "Unfinished mock test found",
                description: error.message,
                status: "error",
                duration: 2000,
                isClosable: true,
            });
        }
    }

    function updateMockId() {
        if (!mockId) {
            // 生成一个唯一的mid 或获取一个未完成的mid todo
            const mid = generateUniqueMockId()
            setMockId(mid)
        }
    }

    function generateUniqueMockId(prefix = 'M_', length = 10) {
        try {
            const uuid = self.crypto.randomUUID(); // 使用浏览器内置的 UUID 生成方法
            // 截取 UUID 的一部分作为 ID 的一部分
            const uniquePart = uuid.replace(/-/g, '').substring(0, length);
            console.debug(`Generated Unique Part: ${uniquePart}`);
            console.debug(`Generated Mid: ${prefix + uniquePart}`);
            return prefix + uniquePart;
        } catch (error) {
            console.error("Error generating unique ID:", error);
            throw new Error("Failed to generate a valid unique ID");
        }
    }


    const pushReadingUrl = () => {
        if (section === 'reading' && location.pathname !== readingUrlStack[readingUrlStack.length - 1]) {
            setReadingUrlStack(prevStack => [...prevStack, location.pathname]);
            // console.log('readingUrlStack', readingUrlStack)
        }
    };

    const popReadingUrl = () => {
        // console.log('readingUrlStack', readingUrlStack)
        if (readingUrlStack.length >= 2) {
            const newStack = [...readingUrlStack];
            newStack.pop();
            let lastUrl = newStack[newStack.length - 1]
            // console.log('lastUrl', lastUrl)
            setReadingUrlStack(newStack);
            if (mockId && !lastUrl.includes('?mid=') && lastUrl.includes('toefl_ibt')) {
                lastUrl += `?mid=${mockId}`;
            }
            return lastUrl;
        }
        return null;
    };

    function showCountDownDisplay() {
        if (section === "speaking") {
            return false
        } else if (["headware_check", "adjusting_the_volume", "adjusting_the_microphone",
            "adjusting_the_microphone_record", "directions", "answer"].includes(childPath)) {
            return false
        } else if (section === "writing" && ["audio"].includes(childPath)) {
            return false
        }
        return true
    }

    const onClose = () => setIsOpen(false); // 关闭
    const onContinue = () => {
        const nextUrl = getNextUrl();
        if (nextUrl) {
            history.replace(nextUrl);
        }
        // history.replace(`${parentPath}/answer?mid=${mockId}`);
        onClose();
    }; // 继续

    const onLastClose = () => {
        setIsLastOpen(false)
        updateMockId()
        setAnswerContent({})
        setMockJson({})
    };

    const onRetake = () => {
        setIsLastOpen(false)
        updateMockId()
        setAnswerContent({})
        setMockJson({})
        const newUrl = practiceType === PracticeTypeEnum.FULL_MOCK_TEST ?
            parentPath.replace(`${section}`, 'reading') : parentPath
        history.replace(newUrl)
    }

    const onContinueLastMock = () => {
        setIsLastOpen(false)
        getAnswerByMid(lastMid)
    }

    function checkGroupType(questionObj) {
        if (questionObj.sub_question_answer.length === 1) {
            return 'Radio';
        } else if (
            questionObj.sub_question_texts &&
            questionObj.sub_question_texts.some(text => (/drag your/i.test(text) || /drag it/i.test(text)))
        ) {
            return 'Drag';
        } else if (
            questionObj.sub_question_texts &&
            questionObj.sub_question_texts.some(text => text.startsWith('Phrases:'))
        ) {
            return 'Table';
        } else {
            return 'Checkbox';
        }
    }

    function isIncorrectCheck() {
        if (['speaking', 'writing'].includes(section) || childPath !== "question") {
            return false
        }
        const taskObj = questionContent?.[section]?.tasks[partIdx - 1];
        if (taskObj && taskObj?.questions) {
            for (const questionObj of taskObj?.questions) {
                if (questionObj.sub_question_idx.toString() === String(subIdx)) {
                    const group_type = checkGroupType(questionObj)
                    if (group_type !== 'Checkbox') {
                        return false
                    }
                    return questionObj.sub_question_answer.length !== answerContent?.[section]?.[String(subIdx)]?.length;
                }
            }
        }
    }


    function getNextUrlForReading() {
        if (childPath === "directions") {
            return `${parentPath}/text/1`;
        } else if (childPath === "text") {
            const nextSubIdx = questionContent?.[section]?.tasks[partIdx - 1].questions[0].sub_question_idx;
            return `${parentPath}/question/${partIdx}/${nextSubIdx}`;
        } else if (childPath === "question") {
            const nextPartIdx = partIdx + 1;
            const nextSubIdx = subIdx + 1;
            const questions = questionContent?.[section]?.tasks[partIdx - 1].questions;
            let curPartEndIdx = questions[questions.length - 1].sub_question_idx;
            if (nextSubIdx > totalNum) { // 单科最后一题
                if (practiceType === PracticeTypeEnum.FULL_MOCK_TEST) { // 全科模考跳到下一个section
                    const newParentPath = parentPath.replace(section, "listening");
                    return `${newParentPath}/directions`;
                } else if (practiceType === PracticeTypeEnum.MOCK_TEST && countdown > 0 && !isOpen) { // 模考倒计时未结束
                    setIsOpen(true)
                } else {
                    return `${parentPath}/answer`
                }
            } else if (Number(subIdx) === Number(curPartEndIdx)) { // 单任务最后一题
                return `${parentPath}/text/${nextPartIdx}`;
            } else {
                return `${parentPath}/question/${partIdx}/${nextSubIdx}`;
            }
        } else if (childPath === "answer") {
            return `/toefl/reading`;
        }
    }

    function getNextUrlForListening() {
        if (childPath === "directions") {
            return `${parentPath}/audio/1`;
        } else if (childPath === "audio") {
            const questions = questionContent?.[section]?.tasks[partIdx - 1].questions;
            const nextSubIdx = questions?.[0].sub_question_idx;
            return `${parentPath}/question/${partIdx}/${nextSubIdx}`;
        } else if (childPath === "re_play") {
            return location.pathname.replace("re_play", "question");
        } else if (childPath === "question") {
            const questions = questionContent?.[section]?.tasks[partIdx - 1].questions;
            const nextPartIdx = partIdx + 1;
            const nextSubIdx = subIdx + 1;
            let curPartEndIdx = questions[questions.length - 1].sub_question_idx;
            if (nextSubIdx > totalNum) { // 单科最后一题
                if (practiceType === PracticeTypeEnum.FULL_MOCK_TEST) { // 全科模考跳到下一个section
                    const newParentPath = parentPath.replace(section, "speaking");
                    return `${newParentPath}/directions`;
                } else if (practiceType === PracticeTypeEnum.MOCK_TEST && countdown > 0 && !isOpen) { // 模考倒计时未结束
                    setIsOpen(true)
                } else {
                    return `${parentPath}/answer`
                }
            } else if (Number(subIdx) === Number(curPartEndIdx)) { // 单任务最后一题
                return `${parentPath}/audio/${nextPartIdx}`;
            } else if (isRePlay(partIdx, nextSubIdx)) { // 重读题目
                return `${parentPath}/re_play/${partIdx}/${nextSubIdx}`;
            } else {
                return `${parentPath}/question/${partIdx}/${nextSubIdx}`;
            }
        } else if (childPath === "answer") {
            return `/toefl/listening`;
        }
    }

    function getNextUrlForSpeaking() {
        if (childPath === "directions") {
            if (!otherParams || otherParams?.length === 0) {
                if (qid === 'all') {
                    return `${parentPath}/directions/1`;
                }
                const question = questionContent?.[section]?.tasks[0];
                if (question.gen_type === 'speaking_task2') {
                    return `${parentPath}/directions/2`;  // 单任务
                } else if (question.gen_type === 'speaking_task3') {
                    return `${parentPath}/directions/3`;
                } else if (question.gen_type === 'speaking_task4') {
                    return `${parentPath}/directions/4`;
                } else {
                    return `${parentPath}/directions/1`;
                }
            }
            if (partIdx === 1) {
                return `${parentPath}/question/${partIdx}`;
            } else if (partIdx === 2 || partIdx === 3) {
                if (qid !== 'all') {
                    return `${parentPath}/text/1`;
                } else {
                    return `${parentPath}/text/${partIdx}`;
                }
            } else if (partIdx === 4) {
                if (qid !== 'all') {
                    return `${parentPath}/audio/1`;
                } else {
                    return `${parentPath}/audio/${partIdx}`;
                }
            }
        } else if (childPath === "text") {
            return `${parentPath}/audio/${partIdx}`;
        } else if (childPath === "audio") {
            return `${parentPath}/question/${partIdx}`
        } else if (childPath === "question") {
            // 正在录音则停止录音，
            // console.log('mediaRecorder', mediaRecorder)
            const answer = answerContent?.[section]?.[partIdx]?.answer;
            if (mediaRecorder) {
                mediaRecorder.stop();
                return null  //停止录音后会b保存自动跳转
            } else if (!mediaRecorder && !answer) { // 无录音则进行数据库更新状态值
                const question = questionContent?.[section]?.tasks[partIdx - 1];
                saveUserAnswer(section, {[question.qid]: {"user_op": "skip"}}); //结果初始化
            }
            // 没有录音则判断当前用户是否已作答且上传答案
            const gidV = answerContent?.[section]?.[partIdx]?.gid;
            // console.log('answer', answer)
            // console.log('gidV', gidV)
            if (answer && !gidV) {
                return null;
                // 弹出答案上传中的提示框  todo
            }
            //获取下一页url
            if (qid !== 'all') {
                return `${parentPath}/answer`
            } else if ([1, 2, 3].includes(partIdx)) {
                return `${parentPath}/directions/${partIdx + 1}`;
            } else if (partIdx === 4) {
                if (practiceType === PracticeTypeEnum.FULL_MOCK_TEST) {
                    const newParentPath = parentPath.replace(section, "writing");
                    return `${newParentPath}/directions`;
                } else {
                    return `${parentPath}/answer`
                }
            }
        } else if (childPath === "answer") {
            return `/toefl/speaking`;
        }
    }

    function getNextUrlForWriting(isForce) {
        if (childPath === "directions") {
            if (!otherParams || otherParams?.length === 0) {
                const question = questionContent?.[section]?.tasks[0];
                if (qid !== 'all' && question.gen_type === 'writing_task3') {
                    return `${parentPath}/directions/2`;  // 单任务
                } else {
                    return `${parentPath}/directions/1`;
                }
            }
            if (partIdx === 1) {
                return `${parentPath}/text/${partIdx}`;
            } else if (partIdx === 2) {
                if (qid !== 'all') { // 单任务
                    return `${parentPath}/question/1`;
                } else {
                    return `${parentPath}/question/${partIdx}`;
                }
            }
        } else if (childPath === "text") {
            return `${parentPath}/audio/${partIdx}`;
        } else if (childPath === "audio") {
            return `${parentPath}/question/${partIdx}`
        } else if (childPath === "question") {
            if (!isForce) {
                // 判断时间是否还有剩余，有剩余则进行弹窗提示
                if (partIdx === 2 && [PracticeTypeEnum.FULL_MOCK_TEST, PracticeTypeEnum.MOCK_TEST].includes(practiceType)
                    && countdown > 0 && !isOpen) {
                    setIsOpen(true)
                    return null;
                }
                //判断当前用户是否已作答且上传答案
                const answer = answerContent?.[section]?.[partIdx]?.answer;
                const gidV = answerContent?.[section]?.[partIdx]?.gid;
                // console.log('gidV', gidV)
                // console.log('answer', answer)
                if (answer && !gidV) {
                    setUploadWritingAnswer(true)
                    return null;
                } else if (!answer) { // 无录音则进行数据库更新状态值
                    const question = questionContent?.[section]?.tasks[partIdx - 1];
                    saveUserAnswer(section, {[question.qid]: {"user_op": "skip"}}); //结果初始化
                }
            }
            //获取下一页url
            if (qid !== 'all') {
                return `${parentPath}/answer`
            } else if (partIdx === 1) {
                return `${parentPath}/directions/2`;
            } else {
                return `${parentPath}/answer`
            }
        } else if (childPath === "answer") {
            if (practiceType === PracticeTypeEnum.FULL_MOCK_TEST) {
                return `/toefl/full_mock_test`;
            } else {
                return `/toefl/writing`;
            }
        }
    }

    function getNextUrl(isForce = false) {
        // adjusting
        if (childPath === "headware_check") {
            return `${parentPath}/adjusting_the_volume`
        } else if (childPath === "adjusting_the_volume") {
            if (practiceType === PracticeTypeEnum.FULL_MOCK_TEST || section === "speaking") {
                // General practice exam or individual oral practice exam
                return `${parentPath}/adjusting_the_microphone`
            } else {
                return `${parentPath}/directions?mid=${mockId}`
            }
        } else if (childPath === "adjusting_the_microphone") {
            return `${parentPath}/adjusting_the_microphone_record`
        } else if (childPath === "adjusting_the_microphone_record") {
            return `${parentPath}/directions?mid=${mockId}` //調試
        }
        // other
        let nextUrl;
        switch (section) {
            case "listening":
                nextUrl = getNextUrlForListening();
                break
            case "reading":
                nextUrl = getNextUrlForReading();
                break
            case "writing":
                nextUrl = getNextUrlForWriting(isForce);
                break
            case "speaking":
                nextUrl = getNextUrlForSpeaking();
                break
        }
        if (!nextUrl) {
            return null;
        }
        if (nextUrl.includes('answer')) {
            mixpanel.track('Mock Test', {
                practiceType: practiceType,
                section: 'All',
                status: "Submit"
            });
        }
        if (mockId && !nextUrl.includes('?mid=') && nextUrl.includes('toefl_ibt')) {
            nextUrl += `?mid=${mockId}`;
        }
        return nextUrl;
    }

    function saveAnswer(sectionStr) {
        let answer_json, mock_json;
        const task = questionContent?.[sectionStr]?.tasks?.[partIdx - 1]
        if (!task) {
            return;
        }
        if (['reading', 'listening'].includes(sectionStr)) {
            const result = handleAnswerContent(sectionStr, task);
            answer_json = result.answerContentV;
            mock_json = result.mockJson;
            saveUserAnswer(sectionStr, answer_json, mock_json);
        }
    }

    const saveUserAnswer = async (sectionStr, answer_json, mock_json = null) => {
        try {
            let headers = authHeader();
            await axios.post(API_URL + '/api/save_answer', {
                answer_json: answer_json,
                practice_type: practiceType,
                question_id: tpoNum,
                question_set: questionSet,
                section: sectionStr,
                test: 'toefl',
                mid: mockId,
                mock_json: mock_json || {}
            }, {headers: headers}).then(response => {
                if (response.status === 201) {
                    console.log(`${tpoNum}-${sectionStr}: Answer saved successfully`);
                    return true
                }
            }).catch(error => {
                console.log(`${tpoNum}-${sectionStr}: Error saving answer`, error);
                toast({
                    title: "Failed to save answer",
                    description: error.message,
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });
            });
        } catch (error) {
            console.error("Error saving answer:", error);
            toast({
                title: "Failed to save answer",
                description: error.message,
                status: "error",
                duration: 2000,
                isClosable: true,
            });
        }
        return false
    };

    function onAudioSave(data) {
        const gidV = answerContent?.[section]?.[partIdx]?.['gid'];
        const questionObj = questionContent?.[section]?.tasks[partIdx - 1];
        if (gidV) { // 存在gidV表明音频已保存
            return;
        }
        // 后端保存音频
        let formData = {
            'gen_type': questionObj.gen_type,
            'tpo_number': questionObj.tpo_number,
            'gid': gidV,
            'qid': questionObj.qid,
            'topic': questionObj.question,
            'audio': data,
            'mock_id': mockId
        };
        savePracticeWithRetry(formData, questionObj);
    };

    function onAnswerSave(data) {
        const gidV = answerContent?.[section]?.[partIdx]?.['gid'];
        const questionObj = questionContent?.[section]?.tasks[partIdx - 1];
        if (gidV) { // 存在gidV表明音频已保存
            return;
        }
        // 后端保存音频
        let formData = {
            'gen_type': questionObj.gen_type,
            'tpo_number': questionObj.tpo_number,
            'gid': gidV,
            'qid': questionObj.qid,
            'topic': questionObj.question,
            'answer': data,
            'mock_id': mockId
        };
        savePracticeWithRetry(formData, questionObj);
    };

    const savePracticeWithRetry = async (formData, questionObj, retries = MAX_RETRIES) => {
        try {
            const response = await savePracticeRequest(formData);
            // console.log("save practice response: ", response)
            if (response.data.status === 'success') {
                const gidV = response.data.data.gid;
                setAnswerContent(prevAnswerContent => ({
                    ...prevAnswerContent,
                    [questionObj.section]: {
                        ...prevAnswerContent[questionObj.section],
                        [partIdx]: {
                            ...(prevAnswerContent[questionObj.section]?.[partIdx] || {}),
                            gid: gidV,
                            status: 'uploaded'
                        }
                    }
                }));
                // console.log("save practice gid: ", gidV);
            } else {
                throw new Error('Save practice failed with status: ' + response.data.status);
            }
        } catch (error) {
            if (retries > 0) {
                console.warn(`save practice error, retrying... (${MAX_RETRIES - retries + 1}/${MAX_RETRIES})`, error);
                await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
                return savePracticeWithRetry(formData, questionObj, retries - 1);
            } else {
                console.error("Failed to save practice after retries:", error);
                throw error; // 或者根据需要处理错误
            }
        }
    };

    function handleAnswerContent(sectionStr, task) {
        const answerContentV = {}
        const mockJson = {}
        const taskAnswerContent = {}
        const taskMockJson = {}
        task?.questions.map((question, index) => {
            const subQuestionIdx = question?.sub_question_idx
            taskAnswerContent[index + 1] = answerContent?.[sectionStr]?.[subQuestionIdx] || '';
            if (!Object.keys(taskMockJson).includes(question.category)) {
                taskMockJson[question.category] = {
                    "Total": 0,
                    'Correct': 0,
                    'Incorrect': 0,
                    'Unattempted': 0
                }
            }
            taskMockJson[question.category]["Total"] += 1
            if (taskAnswerContent[index + 1] === '') {
                taskMockJson[question.category]["Unattempted"] += 1
            } else {
                if (isEqual(taskAnswerContent[index + 1], question.sub_question_answer)) {
                    taskMockJson[question.category]["Correct"] += 1
                } else {
                    taskMockJson[question.category]["Incorrect"] += 1
                }
            }
        })
        answerContentV[task.qid] = taskAnswerContent
        mockJson[task.qid] = taskMockJson
        setMockJson(prevSetMockJson => ({
            ...prevSetMockJson,
            [sectionStr]: {
                ...(prevSetMockJson[sectionStr] || {}),
                [task.qid]: mockJson[task.qid]
            }
        }));
        return {answerContentV, mockJson}
    }


    function isRePlay(partIdx, nextSubIdx) {
        const taskObj = questionContent?.[section]?.tasks[partIdx - 1];
        if (taskObj && taskObj?.questions) {
            for (const questionObj of taskObj?.questions) {
                if (questionObj.sub_question_idx.toString() === nextSubIdx.toString()) {
                    if (questionObj.sub_question_audio && questionObj.sub_question_audio.replay_url) {
                        return true;
                    }
                }
            }
        }
        return false
    }

    function checkDisplayReadingTabs(partIdx, SubIdx) {
        const taskObj = questionContent?.[section]?.tasks[partIdx - 1];
        if (taskObj && taskObj?.questions) {
            for (const questionObj of taskObj?.questions) {
                if (questionObj.sub_question_idx.toString() === SubIdx?.toString()) {
                    return (questionObj.sub_question_texts &&
                        questionObj.sub_question_texts.includes("●")
                    )
                }
            }
        }
        return false
    }


    const formatTime = (seconds) => {
        seconds = seconds < 0 ? 0 : seconds
        const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
        const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
        const secs = String(seconds % 60).padStart(2, '0');
        return `${hours}:${minutes}:${secs}`;
    };


    const fetchQuestionContent = async (sectionStr) => {
        try {
            let param;
            if (qid === "all") {
                param = {
                    test: "toefl",
                    section: sectionStr,
                    questionSet: questionSet,
                    tpoNumber: tpoNum
                };
            } else {
                param = {
                    qid: qid,
                    test: "toefl",
                    section: sectionStr,
                    questionSet: questionSet,
                    tpoNumber: tpoNum
                };
            }
            axios.post(API_URL + '/api/query_mock_test_question_bank', param)
                .then(response => {
                    if (response.status === 200) {
                        const data = response.data.data;
                        data.tasks = data.tasks.map((task, index) => {
                            return {
                                ...task,
                                listening_texts: typeof task.listening_texts === 'string' ? task.listening_texts.split('\n') : [],
                                reading_texts: typeof task.reading_texts === 'string' ? task.reading_texts.split('\n') : []
                            };
                        });
                        setQuestionContent(prevQuestionContent => {
                            return {
                                ...prevQuestionContent,
                                [sectionStr]: data
                            };
                        });
                    }
                }).catch(error => {
                console.error('There was an error!', error);
            });
        } catch (error) {
            console.error('Error occurred while obtaining the content of the problem:', error);
        }
    };

    // const handleClick = () => {
    //     if (practiceType === PracticeTypeEnum.FULL_MOCK_TEST) {
    //         history.replace(`/toefl/${PracticeTypeEnum.FULL_MOCK_TEST}`)
    //     } else {
    //         history.replace(`/toefl/${section}`)
    //     }
    // };

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const LastUnfinishedWarning = ({isOpen, onClose, onRetake, onContinueLastMock}) => {
        return (
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>
                        {t('tips')}
                        <Divider borderWidth={1} borderColor={'gray.300'} mt={2}/>
                    </ModalHeader>
                    <ModalBody>
                        <Text>{t('dropped out midway during the last practice session')}</Text>
                        <Text>{t('please confirm the operation')}</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="teal" onClick={onRetake}>{t('retake')}</Button>
                        <Button colorScheme="teal" onClick={onContinueLastMock} ml={3}>{t('continue')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );
    };

    const IncorrectWarning = ({isOpen, onClose}) => {
        return (
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader px={10} py={6}>
                        <Flex direction={"row"} alignItems={'center'} px={3}>
                            <IoWarning color="red" fontSize={30}/>
                            <Box width={3}></Box>
                            Incorrect Number of Choices
                        </Flex>
                        <Divider borderWidth={1} borderColor={'gray.300'} mt={2}/>
                    </ModalHeader>
                    <ModalBody>
                        <Text px={10}>You must select the EXACT number ofchoices before you can leave thisquestion.
                            Refer to the
                            directions.</Text>
                    </ModalBody>
                    <ModalFooter style={{display: 'flex', justifyContent: 'flex-start'}}>
                        <Button ml={10} mt={4} colorScheme="teal" onClick={onClose}>Return to Question</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );
    };

    function isDisplayBackButton() {
        // 各個section之間不可回退
        if (childPath === 'directions' && partIdx === 0) {
            return false
        } else if (childPath === 'answer') { // 答案页不可回退
            return false
        } else if (section === 'reading') {
            return !(childPath === 'text' && partIdx === 1);
        } else if (['listening', 'speaking', 'writing'].includes(section)) { // 口语、写作每一页都不可回退
            return false
        } else { // 其它场景可回退
            return true
        }
    }

    const ChromeWarning = ({isOpen, onClose}) => {
        const onDownload = () => {
            // 区分中英文跳转下载区
            if (i18n.language === 'cn') {
                window.open('https://www.google.cn/chrome/')
            } else {
                window.open('https://www.google.com/chrome/')
            }
        }

        // 获取当前浏览器名称
        const isEdge = /Edg/i.test(navigator.userAgent);
        const browserName = isEdge ? 'Edge' : navigator.userAgent.match(/Chrome|Firefox|Safari|Opera|Edge/i)?.[0];
        const bodyText = t('For the best testing experience, we recommend using the Chrome browser').replace('{browserName}', browserName)
        return (
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>
                        {t('Recommendations for the Best Mock Test Experience')}
                        <Divider borderWidth={1} borderColor={'gray.300'} mt={2}/>
                    </ModalHeader>
                    <ModalBody>
                        <Text>{bodyText}</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="teal" onClick={onDownload} ml={3}>{t('Download Chrome')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );

    };

    const LoadingOverlay = ({isLoading}) => {
        if (!isLoading) return null;

        return (
            <Flex
                position="fixed"
                top={0}
                left={0}
                right={0}
                bottom={0}
                bg="rgba(0, 0, 0, 0.5)"
                justifyContent="center"
                alignItems="center"
                zIndex={9999}
            >
                <Spinner size="xl" color="white"/>
            </Flex>
        );
    };

    return (
        <Flex direction={"column"}
              display={(childPath === "answer" && practiceType === PracticeTypeEnum.FULL_MOCK_TEST) ? "none" : "flex"}>
            <Flex as="nav" align="center" justify="space-between" wrap="wrap" bg="teal.700" color="white">
                <Flex align="center" ml={5} mr={5}>
                    {/*<Text fontSize="4xl" fontWeight="bold">*/}
                    {/*    lingoleap*/}
                    {/*</Text>*/}
                    {/*<div onClick={handleClick}>*/}
                    <div>
                        <Text fontSize="4xl" fontWeight="bold">
                            lingoleap
                        </Text>
                    </div>
                </Flex>

                <Flex>
                    <Button colorScheme="teal"
                            variant="outline"
                            mr={3}
                            color="white"
                            borderColor="white"
                            _hover={{borderColor: "white"}}
                            rightIcon={<VscFeedback fontSize={20}/>}
                            onClick={onOpenFeedback}>
                        {t('feedback_button_text')}
                    </Button>
                    <Popover>
                        <PopoverTrigger>
                            <Button
                                colorScheme="teal"
                                variant="outline"
                                mr={3}
                                color="white"
                                borderColor="white"
                                _hover={{borderColor: "white"}}
                                rightIcon={<FaVolumeHigh fontSize={20}/>}
                            >
                                Volume
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent bg="white" borderColor="gray.200">
                            <PopoverBody>
                                <Box p={1}>
                                    <Flex align="flex-end" justify="space-between" mb={2}>
                                        {[...Array(15)].map((_, i) => (
                                            <Box
                                                key={i}
                                                w={2}
                                                h={`${(i + 8) * 3}px`}
                                                bg={i < volume * 15 ? "teal.500" : "gray.300"}
                                                mr={1}
                                                borderRadius="6px"
                                            />
                                        ))}
                                    </Flex>
                                    <Slider
                                        aria-label="volume-slider"
                                        value={volume * 100}
                                        min={0}
                                        max={100}
                                        onChange={(val) => {
                                            setVolume(val / 100);
                                        }}
                                    >
                                        <SliderTrack bg="gray.300">
                                            <SliderFilledTrack bg="teal.500"/>
                                        </SliderTrack>
                                        <SliderThumb boxSize={4} bg="teal.500"/>
                                    </Slider>
                                </Box>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                    <Button colorScheme="teal"
                            variant="outline"
                            mr={3}
                            color="white"
                            borderColor="white"
                            _hover={{borderColor: "white"}}
                            leftIcon={<FaAngleLeft fontSize={20}/>}
                            display={isDisplayBackButton() ? "block" : "none"}
                            onClick={() => {
                                const lastUrl = popReadingUrl();
                                if (lastUrl === undefined) return;
                                history.replace(lastUrl);
                            }}>
                        Back
                    </Button>
                    <Button
                        bg="white"
                        variant="outline"
                        mr={3}
                        color="teal"
                        rightIcon={<FaAngleRight fontSize={20}/>}
                        onClick={() => {
                            // 判断是否符合答题要求，答题错误则弹出弹窗，且返回当前url
                            if (isIncorrectCheck()) {
                                onOpenIncorrect();
                                setIsCountdownPaused(true);
                            } else {
                                const nextUrl = getNextUrl();
                                if (nextUrl) {
                                    history.replace(nextUrl);
                                }
                            }
                        }}
                    >
                        {(["headware_check", "adjusting_the_volume", "adjusting_the_microphone",
                            "adjusting_the_microphone_record", "directions"].includes(childPath)) ? "Continue" : "Next"}
                    </Button>
                </Flex>
            </Flex>
            {(childPath !== "answer") && (
                <Flex direction={"row"} justify="space-between" bg="gray.100">
                    <Flex direction={"row"} align="center" fontSize={"xl"}>
                        <Text p={2} ml={8}> {capitalizeFirstLetter(section)} </Text>
                        {(childPath === 'directions' && partIdx !== 0) || (
                            ["question", "text", "audio"].includes(childPath) && subIdx === 0
                        ) && (
                            <Flex direction={"row"}>
                                <Text p={2}> | </Text>
                                <Text p={2}> Passage {partIdx} of {questionContent?.[section]?.tasks.length} </Text>
                            </Flex>
                        )}
                        {(["question", "text", "re_play"].includes(childPath) && subIdx !== 0) && (
                            <Flex direction={"row"}>
                                <Text p={2}> | </Text>
                                <Text p={2}> Question {subIdx} of {totalNum} </Text>
                            </Flex>
                        )}

                    </Flex>
                    {displayReadingTabs && (
                        <Tabs>
                            <TabList>
                                <Tab onClick={() => {
                                    const url = location.pathname.replace("text", "question");
                                    history.replace(url);
                                }}>View Question</Tab>
                                <Tab onClick={() => {
                                    const url = location.pathname.replace("question", "text");
                                    history.replace(url);
                                }}>View Passage</Tab>
                            </TabList>
                        </Tabs>
                    )}
                    <Flex direction={"row"} align="center" fontSize={"xl"}>
                        {isShowCountDownDisplay && (
                            <Flex align="center" ml={8}>
                                {showCountdown && <Text fontSize="md">{formatTime(countdown)}</Text>}
                                <Button onClick={() => setShowCountdown(prev => !prev)} ml={2}
                                        color="teal" leftIcon={showCountdown ? <FaEyeSlash/> : <FaEye/>}
                                        as={Link} variant="Link"
                                        css={{
                                            color: 'teal',
                                            ':hover': {
                                                color: 'teal', // 保持悬停时的颜色不变
                                                backgroundColor: 'transparent', // 保持背景透明
                                                textDecoration: 'none' // 移除下划线
                                            }
                                        }}
                                >
                                    {showCountdown ? "Hide Time" : "Show Time"}
                                </Button>
                            </Flex>
                        )}
                    </Flex>
                </Flex>
            )}
            <FinishWarning isOpen={isOpen} onClose={onClose} onReturn={onClose} onContinue={onContinue}
                           section={section}></FinishWarning>
            <IncorrectWarning isOpen={isOpenIncorrect} onClose={() => {
                onCloseIncorrect();
                setIsCountdownPaused(false);
            }}></IncorrectWarning>
            <LastUnfinishedWarning isOpen={isLastOpen} onClose={onLastClose} onRetake={onRetake}
                                   onContinueLastMock={onContinueLastMock}></LastUnfinishedWarning>
            <GeneralFeedbackModal
                isOpen={isOpenFeedback} onOpen={onOpenFeedback} onClose={onCloseFeedback}
            ></GeneralFeedbackModal>
            <LoadingOverlay isLoading={isLoading}/>
            <ChromeWarning isOpen={isOpenChrome} onClose={() => {
                setIsOpenChrome(false)
            }}></ChromeWarning>
        </Flex>
    );
}

export default ToeflIbtMenu;