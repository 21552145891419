import React, {useEffect, useState} from "react";
import {
    Badge,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Select,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import * as XLSX from 'xlsx';
import CardHeader from "./Card/CardHeader";
import CardBody from "./Card/CardBody";
import Card from "./Card/Card";
import {useTranslation} from "react-i18next";
import {useGenerateRedemptionCodesMutation, useQueryRedemptionCodesQuery} from "../services/cmApi";

export function AdminRedeemCodePanel() {
    const textColor = useColorModeValue("gray.700", "white");
    const {t} = useTranslation();
    const toast = useToast();
    const {isOpen, onOpen, onClose} = useDisclosure();

    // 状态管理
    const [codeType, setCodeType] = useState(100); // 100-口语，200-写作，900-模考
    const [codeCount, setCodeCount] = useState(1);
    const [expirationDays, setExpirationDays] = useState(365); // 默认1年有效期
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50); // 默认每页显示50条
    const [statusFilter, setStatusFilter] = useState("");
    const [codeTypeFilter, setCodeTypeFilter] = useState("");
    const [batchIdFilter, setBatchIdFilter] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [sortBy, setSortBy] = useState("created_at");
    const [sortOrder, setSortOrder] = useState("desc");

    // 本地过滤和排序的数据
    const [filteredCodes, setFilteredCodes] = useState([]);
    const [allCodes, setAllCodes] = useState([]);
    const [totalPages, setTotalPages] = useState(1);

    // 使用RTK Query获取兑换码列表 - 不再使用后端分页，获取所有数据
    const {data: codesData, isLoading: isLoadingCodes, refetch} = useQueryRedemptionCodesQuery({
        page_size: 1000, // 获取大量数据，前端处理分页
        sort_by: "created_at",
        sort_order: "desc"
    });

    // 使用RTK Query生成兑换码
    const [generateCodes, {isLoading: isGenerating}] = useGenerateRedemptionCodesMutation();

    // 生成兑换码
    const handleGenerateCodes = async () => {
        try {
            const response = await generateCodes({
                code_type: codeType,
                count: codeCount,
                expiration_days: expirationDays
            }).unwrap();

            onClose();

            // 刷新兑换码列表
            refetch();

            toast({
                title: "兑换码生成成功",
                description: response.message || `成功生成${codeCount}个兑换码`,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "兑换码生成失败",
                description: error.data?.message || "生成兑换码时发生错误",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    // 获取积分类型显示名称
    const getCreditTypeName = (type) => {
        switch (parseInt(type)) {
            case 100:
                return '口语';
            case 200:
                return '写作';
            case 900:
                return '模考';
            default:
                return type;
        }
    };

    // 获取兑换码状态
    const getCodeStatus = (status) => {
        switch (status) {
            case 'ACTIVE':
                return <Badge colorScheme="green">可用</Badge>;
            case 'USED':
                return <Badge colorScheme="red">已使用</Badge>;
            case 'EXPIRED':
                return <Badge colorScheme="gray">已过期</Badge>;
            default:
                return <Badge>{status}</Badge>;
        }
    };

    // 处理分页变化
    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    // 处理状态筛选变化
    const handleStatusFilterChange = (e) => {
        setStatusFilter(e.target.value);
        setPage(1); // 重置到第一页
    };

    // 处理积分类型筛选变化
    const handleCodeTypeFilterChange = (e) => {
        setCodeTypeFilter(e.target.value);
        setPage(1); // 重置到第一页
    };

    // 处理日期范围筛选变化
    const handleDateRangeChange = (type, value) => {
        if (type === 'start') setStartDate(value);
        else setEndDate(value);
        setPage(1); // 日期筛选时重置分页
    };

    // 处理排序变化
    const handleSortChange = (field) => {
        if (sortBy === field) {
            // 如果已经按此字段排序，则切换排序方向
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // 否则，按新字段排序，默认降序
            setSortBy(field);
            setSortOrder('desc');
        }
        setPage(1); // 重置到第一页
    };
    
    // 导出Excel功能
    const handleExportToExcel = () => {
        try {
            // 准备导出数据
            const exportData = allCodes
                .filter(code => {
                    // 应用当前筛选条件
                    let match = true;
                    
                    if (searchTerm) {
                        const term = searchTerm.toLowerCase();
                        match = match && (
                            code.code.toLowerCase().includes(term) || 
                            code.batch_id.toLowerCase().includes(term) || 
                            (code.redeemer_id && code.redeemer_id.toString().toLowerCase().includes(term))
                        );
                    }
                    
                    if (statusFilter) {
                        match = match && code.status === statusFilter;
                    }
                    
                    if (codeTypeFilter) {
                        match = match && code.code_type === codeTypeFilter;
                    }
                    
                    if (batchIdFilter) {
                        match = match && code.batch_id === batchIdFilter;
                    }
                    
                    if (startDate) {
                        const startTimestamp = new Date(startDate).getTime() / 1000;
                        match = match && code.created_at >= startTimestamp;
                    }
                    
                    if (endDate) {
                        const endDateTime = new Date(endDate);
                        endDateTime.setHours(23, 59, 59, 999);
                        const endTimestamp = endDateTime.getTime() / 1000;
                        match = match && code.created_at <= endTimestamp;
                    }
                    
                    return match;
                })
                .map(code => ({
                    '兑换码': code.code,
                    '积分类型': getCreditTypeName(code.code_type),
                    '积分数量': code.credit_amount,
                    '创建日期': new Date(code.created_at * 1000).toLocaleDateString(),
                    '过期日期': new Date(code.expire_at * 1000).toLocaleDateString(),
                    '状态': code.status,
                    '使用者ID': code.user_id || '-',
                    '使用日期': code.used_at ? new Date(code.used_at * 1000).toLocaleDateString() : '-',
                    '批次ID': code.batch_id
                }));

            // 创建工作簿和工作表
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(exportData);
            
            // 添加工作表到工作簿
            XLSX.utils.book_append_sheet(wb, ws, '兑换码列表');
            
            // 生成文件名
            const fileName = `兑换码列表_${new Date().toISOString().split('T')[0]}.xlsx`;
            
            // 导出Excel文件
            XLSX.writeFile(wb, fileName);
            
            toast({
                title: "导出成功",
                description: `成功导出${exportData.length}条兑换码数据`,
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error('导出Excel失败:', error);
            toast({
                title: "导出失败",
                description: "导出Excel文件时发生错误",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    // 将API返回的数据保存到本地状态
    useEffect(() => {
        if (codesData?.data?.codes?.length > 0) {
            setAllCodes(codesData.data.codes);
            
            // 设置起止日期为数据的创建时间范围
            const codes = [...codesData.data.codes]; // 创建数组副本
            const sortedCodes = codes.sort((a, b) => a.created_at - b.created_at);
            const earliestDate = new Date(sortedCodes[0].created_at * 1000).toISOString();
            const latestDate = new Date(sortedCodes[sortedCodes.length - 1].created_at * 1000).toISOString();
            setStartDate(earliestDate.split('T')[0]);
            setEndDate(latestDate.split('T')[0]);
        }
    }, [codesData]);
    
    // 根据筛选条件过滤数据
    useEffect(() => {
        if (allCodes.length === 0) return;
        
        let filtered = [...allCodes];
        
        // 搜索条件过滤
        if (searchTerm) {
            const term = searchTerm.toLowerCase();
            filtered = filtered.filter(code => 
                code.code.toLowerCase().includes(term) || 
                code.batch_id.toLowerCase().includes(term) || 
                (code.redeemer_id && code.redeemer_id.toString().toLowerCase().includes(term))
            );
        }
        
        // 状态过滤
        if (statusFilter) {
            filtered = filtered.filter(code => code.status === statusFilter);
        }
        
        // 积分类型过滤
        if (codeTypeFilter) {
            filtered = filtered.filter(code => code.code_type === codeTypeFilter);
        }
        
        // 批次ID过滤
        if (batchIdFilter) {
            filtered = filtered.filter(code => code.batch_id === batchIdFilter);
        }
        
        // 日期范围过滤
        if (startDate) {
            const startTimestamp = new Date(startDate).getTime() / 1000;
            filtered = filtered.filter(code => code.created_at >= startTimestamp);
        }
        
        if (endDate) {
            // 将结束日期设置为当天的23:59:59
            const endDateTime = new Date(endDate);
            endDateTime.setHours(23, 59, 59, 999);
            const endTimestamp = endDateTime.getTime() / 1000;
            filtered = filtered.filter(code => code.created_at <= endTimestamp);
        }
        
        // 排序
        filtered.sort((a, b) => {
            const aValue = a[sortBy];
            const bValue = b[sortBy];
            
            if (sortOrder === 'asc') {
                return aValue > bValue ? 1 : -1;
            } else {
                return aValue < bValue ? 1 : -1;
            }
        });
        
        // 计算总页数
        const total = Math.ceil(filtered.length / pageSize);
        setTotalPages(total > 0 ? total : 1);
        
        // 分页
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        setFilteredCodes(filtered.slice(startIndex, endIndex));
    }, [allCodes, searchTerm, statusFilter, codeTypeFilter, batchIdFilter, startDate, endDate, sortBy, sortOrder, page, pageSize]);

    return (
        <Card overflowX={{sm: "scroll", xl: "hidden"}}>
            <CardHeader p="6px 0px 22px 0px">
                <Flex justifyContent="space-between" alignItems="center">
                    <Text fontSize="lg" color={textColor} fontWeight="bold">
                        兑换码管理
                    </Text>
                    <Flex gap={2}>
                        <Button colorScheme="blue" onClick={handleExportToExcel}>
                            导出Excel
                        </Button>
                        <Button colorScheme="teal" onClick={onOpen}>
                            生成兑换码
                        </Button>
                    </Flex>
                </Flex>
            </CardHeader>
            <CardBody>
                <Flex mb={4} flexDirection="column" gap={3}>
                    <Flex justifyContent="space-between" alignItems="center" gap={3}>
                        <InputGroup maxWidth="300px">
                            <Input
                                placeholder="搜索兑换码/批次ID/使用者"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <InputRightElement width="4.5rem">
                                <Button h="1.75rem" size="sm" onClick={() => setSearchTerm("")}>
                                    清除
                                </Button>
                            </InputRightElement>
                        </InputGroup>

                        <Select
                            placeholder="全部状态"
                            value={statusFilter}
                            onChange={handleStatusFilterChange}
                            maxWidth="200px"
                        >
                            <option value="ACTIVE">可用</option>
                            <option value="USED">已使用</option>
                            <option value="EXPIRED">已过期</option>
                        </Select>

                        <Select
                            placeholder="全部积分类型"
                            value={codeTypeFilter}
                            onChange={handleCodeTypeFilterChange}
                            maxWidth="200px"
                        >
                            <option value="100">口语积分</option>
                            <option value="200">写作积分</option>
                            <option value="900">模考积分</option>
                        </Select>

                        <Flex>
                            <Input
                                placeholder="开始日期"
                                type="date"
                                value={startDate}
                                onChange={(e) => handleDateRangeChange('start', e.target.value)}
                                mr={2}
                                maxWidth="150px"
                            />
                            <Input
                                placeholder="结束日期"
                                type="date"
                                value={endDate}
                                onChange={(e) => handleDateRangeChange('end', e.target.value)}
                                maxWidth="150px"
                            />
                        </Flex>
                    </Flex>
                </Flex>

                {isLoadingCodes ? (
                    <Text>加载中...</Text>
                ) : (
                    <>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>兑换码</Th>
                                    <Th>积分类型</Th>
                                    <Th>积分数量</Th>
                                    <Th onClick={() => handleSortChange('created_at')} cursor="pointer">
                                        创建日期 {sortBy === 'created_at' && (sortOrder === 'asc' ? '↑' : '↓')}
                                    </Th>
                                    <Th onClick={() => handleSortChange('expire_at')} cursor="pointer">
                                        过期日期 {sortBy === 'expire_at' && (sortOrder === 'asc' ? '↑' : '↓')}
                                    </Th>
                                    <Th onClick={() => handleSortChange('status')} cursor="pointer">
                                        状态 {sortBy === 'status' && (sortOrder === 'asc' ? '↑' : '↓')}
                                    </Th>
                                    <Th>使用者</Th>
                                    <Th>使用日期</Th>
                                    <Th>批次ID</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {filteredCodes.map((code) => (
                                    <Tr key={code.id}>
                                        <Td>{code.code}</Td>
                                        <Td>{getCreditTypeName(code.code_type)}</Td>
                                        <Td>{code.credit_amount}</Td>
                                        <Td>{new Date(code.created_at * 1000).toLocaleDateString()}</Td>
                                        <Td>{new Date(code.expire_at * 1000).toLocaleDateString()}</Td>
                                        <Td>{getCodeStatus(code.status)}</Td>
                                        <Td>{code.user_id || '-'}</Td>
                                        <Td>{code.used_at ? new Date(code.used_at * 1000).toLocaleDateString() : '-'}</Td>
                                        <Td>{code.batch_id}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>

                        {/* 分页控制 */}
                        <Flex justifyContent="space-between" mt={4}>
                            <Text>总计: {codesData?.data?.codes?.length  || 0} 条记录</Text>
                            <Flex>
                                <Button
                                    isDisabled={page <= 1}
                                    onClick={() => handlePageChange(page - 1)}
                                    mr={2}
                                >
                                    上一页
                                </Button>
                                <Text alignSelf="center" mx={2}>
                                    第 {page} / {codesData?.data?.pagination?.total_pages || 1} 页
                                </Text>
                                <Button
                                    isDisabled={page >= (codesData?.data?.pagination?.total_pages || 1)}
                                    onClick={() => handlePageChange(page + 1)}
                                    ml={2}
                                >
                                    下一页
                                </Button>
                            </Flex>
                        </Flex>
                    </>
                )}
            </CardBody>

            {/* 生成兑换码弹窗 */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>生成兑换码</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Stack spacing={4}>
                            <FormControl>
                                <FormLabel>积分类型</FormLabel>
                                <Select value={codeType} onChange={(e) => setCodeType(parseInt(e.target.value))}>
                                    <option value="100">口语积分</option>
                                    <option value="200">写作积分</option>
                                    <option value="900">模考积分</option>
                                </Select>
                            </FormControl>

                            <FormControl>
                                <FormLabel>生成数量</FormLabel>
                                <NumberInput min={1} max={500} value={codeCount}
                                             onChange={(valueString) => setCodeCount(parseInt(valueString))}>
                                    <NumberInputField/>
                                    <NumberInputStepper>
                                        <NumberIncrementStepper/>
                                        <NumberDecrementStepper/>
                                    </NumberInputStepper>
                                </NumberInput>
                            </FormControl>

                            <FormControl>
                                <FormLabel>有效天数</FormLabel>
                                <NumberInput min={1} max={3650} value={expirationDays}
                                             onChange={(valueString) => setExpirationDays(parseInt(valueString))}>
                                    <NumberInputField/>
                                    <NumberInputStepper>
                                        <NumberIncrementStepper/>
                                        <NumberDecrementStepper/>
                                    </NumberInputStepper>
                                </NumberInput>
                            </FormControl>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleGenerateCodes} isLoading={isGenerating}>
                            生成
                        </Button>
                        <Button variant="ghost" onClick={onClose}>取消</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Card>
    );
}