import React, {useEffect, useState,Fragment} from "react";
import {useParams} from 'react-router-dom';

import {Box, HStack, VStack} from '@chakra-ui/react'
// import questionList from './questionList.json'
import {ToeflIbtContext} from "./ToeflIbtContext";

function ToeflWritingText({setIsCountdownPaused}) {
    const {partIdx} = useParams();
    const {
        questionContent,
    } = React.useContext(ToeflIbtContext)
    const [readingTexts, setReadingTexts] = useState([]);

    useEffect(() => {
        const texts = questionContent?.['writing']?.tasks[partIdx - 1].reading_texts;
        let flattenedTexts = []
        if (typeof texts === 'string') {
            flattenedTexts = texts.split('\n')
        } else if (Array.isArray(texts)) {
            flattenedTexts = texts?.flatMap(text => text.split('\n'));
        }
        setReadingTexts(flattenedTexts)
        setIsCountdownPaused(false);
    }, [questionContent, partIdx]);

    const splitText = (text) => {
        // 先拆分句子
        const sentences = text.split(/[.!?]/);
        // 使用正则表达式匹配以两个大写单词开头的句子，并返回标题和内容
        const match = sentences[0].match(/^([A-Z][a-z ]*) ([A-Z].*)/);
        if (match) {
            const title = match[1].trim();
            const titleWordsCnt = title.split(' ').length;
            if (titleWordsCnt <= 5) {
                const content = text.slice(title.length).trim();
                return {title: `<strong>${title}</strong>`, content: content};
            }
        }
        return {title: '', content: text};
    };

    return (
        <Box h={'80%'} bg={'#F9F9F9'} maxWidth="80%" margin="auto">
            <VStack alignItems={'left'}>
                <HStack p={4} h={'80%'} w={'100%'} alignItems="flex-start">
                    <Box w={'50%'} h={'90vh'}>
                        <VStack
                            border="2px"
                            borderColor="gray.200"
                            borderRadius="16"
                            height="100%"
                            width="100%"
                            spacing={0}
                        >
                            <Box
                                overflowY="auto"
                                width="100%"
                                height='94%'
                            >
                                <VStack spacing={4} align="stretch" p={3}>
                                    {readingTexts && readingTexts.map((text, id) => {
                                        const textList = splitText(text);
                                        return (
                                            <Fragment key={id}>
                                                {textList.title && (
                                                    <div key={`${id}-title`}
                                                         dangerouslySetInnerHTML={{__html: textList.title}}/>
                                                )}
                                                <div key={`${id}-content`}
                                                     dangerouslySetInnerHTML={{__html: textList.content}}/>
                                            </Fragment>
                                        );
                                    })}
                                </VStack>
                            </Box>
                        </VStack>
                    </Box>
                </HStack>
            </VStack>
        </Box>
    );
}

export default ToeflWritingText;