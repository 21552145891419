import {Box, Divider, Heading, Icon, Text, VStack} from '@chakra-ui/react';
import React, {useEffect, useRef} from "react";
import {FaVolumeUp} from 'react-icons/fa';
import {ToeflIbtContext} from "./ToeflIbtContext";
import {useHistory} from "react-router-dom";

function ToeflAdjustingTheVolume() {
    const directionsAudio = "https://lingoleapstorage1.blob.core.windows.net/sm-audio/adjusting_the_volume.mp3";
    const audioRef = useRef(null); // 创建 ref
    const {volume} = React.useContext(ToeflIbtContext);
    const history = useHistory();
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume; // 设置音量
        }
    }, [volume]);

    return (
        <Box maxWidth="860px" margin="auto" padding="4">
            <VStack spacing={8} align="stretch">
                <audio
                    src={directionsAudio}
                    autoPlay={true} // 自动播放音频
                    ref={audioRef} // 使用 ref 来控制音频
                    style={{display: 'none'}} // 隐藏音频控件
                />
                <Heading as="h1" size="lg" textAlign="left" style={{fontWeight: 'normal'}}>
                    Adjusting the Volume
                </Heading>
                <Divider borderWidth={1}/>
                <Text>
                    To adjust the volume, select the Volume icon at the top of the screen. The volume control will
                    appear. Move the volume indicator to the left or the right to change the volume.
                </Text>
                <Text>
                    To close the volume control, select the Volume icon again.
                </Text>
                <Text>
                    You will be able to change the volume during the test if you need to.
                </Text>
                <Text>
                    <Icon as={FaVolumeUp} color={"teal.500"}
                          fontSize={60}
                          style={{verticalAlign: '-2px'}}
                          mr={4}
                    />
                    You now have the option to adjust the volume.
                </Text>
            </VStack>
        </Box>
    );
}

export default ToeflAdjustingTheVolume;