import React, {useEffect, useState} from "react";
import {useParams} from 'react-router-dom';

import {Box, HStack, VStack} from '@chakra-ui/react'

import {ToeflReadingQuestionRenderReadingText} from "./ToeflReadingQuestionRenderReadingText";
import {ToeflReadingQuestionAnswerSetting} from "./ToeflReadingQuestionAnswerSetting";
import {ToeflReadingQuestionDragAndDrop} from "./ToeflReadingQuestionDragAndDrop";
import {ToeflIbtContext} from "./ToeflIbtContext";

function ToeflReadingQuestion() {
    const {partIdx, subIdx} = useParams();
    const {
        questionContent,
    } = React.useContext(ToeflIbtContext)
    const [question, setQuestion] = useState(null);

    useEffect(() => {
        setQuestion({})
        getQuestionInfo();
    }, [questionContent, partIdx, subIdx]);

    function getQuestionInfo() {
        const taskObj = questionContent?.['reading']?.tasks[partIdx - 1];
        if (taskObj && taskObj?.questions) {
            for (const questionObj of taskObj?.questions) {
                if (questionObj.sub_question_idx.toString() === subIdx) {
                    // console.log("question info: ", questionObj)
                    setQuestion(questionObj);
                    return;
                }
            }
        }
    }

    return (
        <VStack w={'100%'}>
            <Box h={'80%'} bg={'#F9F9F9'} w={'100%'}>
                <VStack alignItems={'left'}>
                    {
                        // (!question?.sub_question_texts || !question?.sub_question_texts[0].includes("Directions"))
                        (!question?.sub_question_texts || !question?.sub_question_texts.includes("●"))?
                        <HStack p={4} h={'100%'} w={'100%'} alignItems="flex-start">
                            <Box w={'50%'} h={'90vh'}>
                                <ToeflReadingQuestionRenderReadingText
                                    textArray={question?.reading_texts}
                                    questionText={question?.sub_question_texts}
                                    subIdx={subIdx}
                                />
                            </Box>
                            <Box w={'50%'} h={'90vh'}>
                                <ToeflReadingQuestionAnswerSetting
                                    subQuestionOptions={question?.sub_question_options}
                                    answerExplain={question?.sub_question_answer}
                                    questionText={question?.sub_question_texts}
                                    readTexts={question?.reading_texts}
                                    subIdx={subIdx}
                                />
                            </Box>
                        </HStack> :
                        <Box w={'100%'} h={'100%'}>
                            <ToeflReadingQuestionDragAndDrop
                                questionText={question?.sub_question_texts}
                                subQuestionOptions={question?.sub_question_options}
                                subIdx={subIdx}
                            />
                        </Box>
                    }
                </VStack>
            </Box>
        </VStack>
    )
}

export default ToeflReadingQuestion;