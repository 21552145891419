import React, {useState, useEffect} from "react";
import {
    Badge,
    Box,
    Button,
    Center,
    Flex,
    Icon,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Tooltip,
    useDisclosure,
    useBreakpointValue
} from "@chakra-ui/react";
import {useGetBalanceQuery, useGetPurchaseInfoQuery, useGetUserPreferencesQuery} from "../services/cmApi";
import {useTranslation} from "react-i18next";
import AuthService from "../services/auth.service";
import {PersonIcon} from "./Icons/Icons";
import getTimeDescription from "./utils/getTimeDescription";
import {useGetPromotionCodeQuery} from "services/cmApi";
import {useHistory} from 'react-router-dom';
import {RedeemCodeModal} from "./modals/RedeemCodeModal";

export function AccountPanel(props) {
    let balance = useGetBalanceQuery({});
    const {t, i18n} = useTranslation();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {isOpen: isOpenDel, onOpen: onOpenDel, onClose: onCloseDel} = useDisclosure();
    const [isRedeemModalOpen, setIsRedeemModalOpen] = useState(false);

    const {data: preferences, isLoading, isError, isSuccess} = useGetUserPreferencesQuery({});
    let purchase_info = useGetPurchaseInfoQuery({lang: i18n.language}, {skip: !isSuccess})
    const [promotionType, setPromotionType] = useState("CampusCertify")
    let promotion_info = useGetPromotionCodeQuery({promotion_type: promotionType});
    const isMobile = useBreakpointValue({base: true, md: false});
    let currentUser = AuthService.getCurrentUser();
    const [campusCertifyStatus, setCampusCertifyStatus] = useState(currentUser.campus_certify_status);
    let history = useHistory();
    const [denialReason, setDenialReason] = useState('');
    const query = new URLSearchParams(window.location.search);
    const mode = query.get('mo');

    const getBalance = () => {
        if (balance.status !== 'fulfilled') {
            return "n/a";
        } else {
            return balance.data.balance;
        }
    };

    const getPendingCredits = () => {
        if (balance.status !== 'fulfilled') {
            return "n/a";
        } else {
            let pending_credits = 0;
            for (let i = 0; i < balance.data.pending_info.length; i++) {
                pending_credits += balance.data.pending_info[i].credits;
            }
            return pending_credits;
        }
    };


    const getExpirationInfo = () => {
        if (purchase_info.status !== 'fulfilled') {
            return null;
        }

        if (purchase_info.data.expiration_info.length == 0) {
            return null
        }
        return purchase_info.data.expiration_info;
    }

    const getExpirationReminderText = () => {
        const expiration_info = getExpirationInfo();
        if (!expiration_info) {
            return "";
        }
        return expiration_info[0].credits + t('will_expire_in') + getTimeDescription(t, expiration_info[0].expiration) + t('expire_ending');
    }

    const getExpirationReminderRows = () => {
        const expiration_info = getExpirationInfo();
        if (!expiration_info) {
            return "";
        }
        let result = [];
        for (let i = 0; i < expiration_info.length; i++) {
            result.push(expiration_info[i].credits + t('will_expire_in') + getTimeDescription(t, expiration_info[i].expiration) + t('expire_ending'));

        }
        return result;
    }

    const renderCampusCertificationStatus = () => {
        if (campusCertifyStatus === "CERTIFIED") {
            return (
                <Flex flexDirection={"column"}>
                    <Text>已认证：{currentUser.campus_name}</Text>
                    {/*{(promotion_info.status === 'fulfilled' && promotion_info.data.promotion_code) ?*/}
                    {/*    <Flex>*/}
                    {/*        <Text>优惠码：</Text>*/}
                    {/*        <Text color='green' fontWeight="bold">*/}
                    {/*            {promotion_info.data.promotion_code}</Text>*/}
                    {/*        <Link color={"red.400"} style={{textDecoration: "underline"}}*/}
                    {/*              href={"https://lingoleap.ai/zh/read/%e6%8a%98%e6%89%a3%e7%a0%81%e4%bd%bf%e7%94%a8%e8%af%b4%e6%98%8e/"}*/}
                    {/*              ml="3"*/}
                    {/*              target="_blank"*/}
                    {/*        >优惠码使用说明</Link>*/}
                    {/*    </Flex> :*/}
                    {/*    null}*/}
                </Flex>)
        }else{
            return (<Flex display="none"></Flex>)
        }

        // else if (campusCertifyStatus === "WAITING") {
        //     return (
        //         <Flex flexDirection="column">
        //             <Flex>
        //                 <Text>未认证：</Text>
        //                 <Text color="yellow.400" fontWeight='bold'>人工审核中</Text>
        //             </Flex>
        //             <Flex>
        //                 <Center>
        //                     <Button bg={'yellow.400'} color={'white'} height={'24px'}
        //                             onClick={() => {
        //                                 AuthService.getMyInfo().then((data) => {
        //                                         setCampusCertifyStatus(data.campus_certify_status)
        //                                     },
        //                                 )
        //                             }}>刷新</Button>
        //                     <Badge justifyContent="center">(解锁8.5折学生价、免费积分)</Badge>
        //                 </Center>
        //             </Flex>
        //         </Flex>
        //     )
        // } else if (campusCertifyStatus === "DISABLED") {
        //     return (
        //         <Flex flexDirection="column">
        //             <Flex>
        //                 <Text>未认证：</Text>
        //                 <Text color="red.400" fontWeight='bold'>认证失败</Text>
        //             </Flex>
        //             <Flex display={denialReason === '' ? 'none' : 'flex'}>
        //                 <Text>失败原因：</Text>
        //                 <Text color="red.400" fontWeight='bold'>{denialReason}</Text>
        //             </Flex>
        //             <Flex>
        //                 <Button bg={'green.400'} color={'white'} height={'24px'}
        //                         onClick={() => {
        //                             history.push("/campus_certification_guidance")
        //                         }}>重新认证</Button>
        //                 <Badge justifyContent="center">(解锁8.5折学生价、免费积分)</Badge>
        //             </Flex>
        //         </Flex>
        //     )
        // } else {
        //     return (
        //         <Flex flexDirection="column">
        //             <Flex>
        //                 <Text>未认证：</Text>
        //                 <Button bg={'green.400'} color={'white'} height={'24px'} ml={'10px'}
        //                         onClick={() => {
        //                             history.push("/campus_certification_guidance")
        //                         }}>完成学校认证</Button>
        //             </Flex>
        //             <Center>
        //                 <Badge justifyContent="center">(解锁8.5折学生价、免费积分)</Badge>
        //             </Center>
        //         </Flex>
        //     )
        // }
    };


    useEffect(() => {
        AuthService.getMyInfo().then((data) => {
                currentUser = data
                setCampusCertifyStatus(data.campus_certify_status)
                setDenialReason(data.certify_denial_reason)
            }
        )
    }, []);


    useEffect(() => {
        if (campusCertifyStatus === "CERTIFIED") {
            promotion_info.refetch();
        }
    }, [campusCertifyStatus]);


    return (
        <Box width="100%" p="20px">
            <Flex flexDirection="row">
                <Flex flexDirection="column">
                    <Icon h="120px"
                          w="120px"
                          color="white"
                          bgColor="blue.500"
                          me="12px"
                          borderRadius="xl"
                    >
                        <PersonIcon color='inherit'/>
                    </Icon>
                </Flex>
                <Box p="10px"></Box>
                <Flex flexDirection="column">
                    <Flex><Text>{t('account')}: </Text> <Text ml="3">{currentUser.user_name}</Text></Flex>
                    <Flex flexDirection={isMobile ? "column" : "row"}>
                        <Text>{t('credit')}: {getBalance()}</Text>
                        <Center>
                            {/*<Text m="0">{t('total_credits') + ": " + getBalance()}</Text>*/}
                            <Badge
                                variant='outline' colorScheme='blue' ml="10px"
                                _hover={{
                                    color: "blue.600",
                                    cursor: "pointer"
                                }}
                                onClick={onOpen}
                                display={getExpirationReminderText() ? "block" : "none"}
                            >
                                {getExpirationReminderText()}
                            </Badge>
                            <Button
                                size="sm"
                                colorScheme="teal"
                                ml="10px"
                                onClick={() => setIsRedeemModalOpen(true)}
                            >
                                兑换码
                            </Button>
                            <Modal isOpen={isOpen} onClose={onClose}>
                                <ModalOverlay/>
                                <ModalContent>
                                    <ModalHeader>{t('sub_exp_detail')}</ModalHeader>
                                    <ModalCloseButton/>
                                    <ModalBody>
                                        {
                                            (getExpirationReminderRows() || []).map(row =>
                                                <Flex><Text>{row}</Text></Flex>)
                                        }
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                                            {t("close")}
                                        </Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
                            <Modal isOpen={isOpenDel} onClose={onCloseDel}>
                                <ModalOverlay/>
                                <ModalContent>
                                    <ModalHeader>{"Delete Account"}</ModalHeader>
                                    <ModalCloseButton/>
                                    <ModalBody>
                                        <Text>Are you sure you want to delete your account?</Text>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button colorScheme='blue' mr={3} onClick={onCloseDel}>
                                            {t("Yes")}
                                        </Button>
                                        <Button colorScheme='blue' mr={3} onClick={onCloseDel}>
                                            {t("close")}
                                        </Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
                        </Center>
                    </Flex>
                    <Flex display={getPendingCredits() ? "block" : "none"}>
                        <Tooltip label={t('pending_credits_explanation')} aria-label='A tooltip'>
                            {t('pending_credits') + ": " + getPendingCredits()}
                        </Tooltip>
                    </Flex>
                    {i18n.language == "cn" ? renderCampusCertificationStatus() : <Flex display="none"></Flex>}
                    <Flex>
                    { mode == 'a' ?
                        <Text
                                color={'blue.300'}
                                variant='outline' colorScheme='blue' ml="10px"
                                _hover={{
                                    color: "blue.600",
                                    cursor: "pointer"
                                }}
                                onClick={onOpenDel}
                        >Delete Account</Text>
                        : null }
                    </Flex>
                </Flex>
            </Flex>
            
            {/* 兑换码弹窗 */}
            <RedeemCodeModal 
                isOpen={isRedeemModalOpen} 
                onClose={() => setIsRedeemModalOpen(false)}
                onSuccess={(result) => {
                    // 兑换成功后刷新余额
                    balance.refetch();
                    // 刷新页面
                    window.location.reload();
                }}
            />
        </Box>);

}